import {
  Dialog,
  DialogBody,
  DialogHeader,
  FlatButton,
  Spinner,
  Status,
} from "@faktoring/ui";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { classnames } from "@faktoring/util";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { localizedTime } from "../../pages/users/users";
import { useAllBuyersQuery } from "../../state/buyers/buyer-api.service";
import { Invoice } from "../../state/requests/invoice.class";
import { selectDialogState } from "../../state/requests/request.slice";
import {
  useAllRequestsQuery,
  useDownloadDocumentationMutation,
  useDownloadFileMutation,
  useUpdateRequestStatusMutation,
} from "../../state/requests/requests-api.service";
import { Request } from "../../state/requests/requests.class";
import {
  selectRequestState,
  selectRequestsPageOptions,
} from "../../state/requests/requests.slice";
import {
  openTotalRequestPreview,
  selectTotalReqDialogState,
  useTotalRequestPreviewState,
} from "../../state/requests/total-request.slice";
import {
  InvoicePreview,
  formatNumber,
} from "../invoice-preview/invoice-preview";
import { InvoicesList } from "../invoices-list/invoices-list";
import { MessagePreview } from "../message-preview/message-preview";
import { NewContract } from "../new-contract/new-contract";
import { RequestMessageDialog } from "../request-message/request-message";
import { TotalRequestPreview } from "../total-request-preview/total-request-preview";
import styles from "./admin-request-preview.module.scss";
import { useFindUserBuyerContractsMutation } from "../../state/contracts/contracts-api.service";
import { ContractType } from "../../state/contracts/contracts.class";

interface IRequestPreviewProps {
  open: boolean;
  onClose: () => void;
  onStatusChange: (value: boolean) => void;
}

interface IFile {
  fileReference: string;
  name: string;
  type: string;
}

export const AdminRequestPreview: FC<IRequestPreviewProps> = ({
  open,
  onClose,
  onStatusChange,
}) => {
  const dispatch = useDispatch();
  const { requestsData: data } = useSelector(selectRequestState);
  const { pageSize, page } = useSelector(selectRequestsPageOptions);
  const {
    requestFilteredStatus,
    requestsSearchedValue,
    requestsSortDirection: sortDirection,
    requestsSortField: sortField,
  } = useSelector(selectDialogState);
  const { refetch: refetchData, isFetching } = useAllRequestsQuery({
    page,
    pageSize,
    status: requestFilteredStatus,
    searchedValue: requestsSearchedValue,
    sortField,
    sortDirection,
  });
  const { refetch: refetchBuyers } = useAllBuyersQuery(
    {
      page: 0,
      pageSize: 1000,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [updateRequest] = useUpdateRequestStatusMutation();
  const [findUserBuyerContracts] = useFindUserBuyerContractsMutation();
  const [downloadFile] = useDownloadFileMutation();
  const [downloadDocumentation] = useDownloadDocumentationMutation();
  const { requestPreviewId } = useSelector(selectDialogState);
  const { isChangedRequestData } = useSelector(selectTotalReqDialogState);
  const { t } = useTranslation();

  const [isOpenInvoicePreview, setisOpenInvoicePreview] = useState(false);
  const [isOpenMessagePreview, setisOpenMessagePreview] = useState(false);
  const [isOpenSendToChangeDialog, setisOpenSendToChangeDialog] =
    useState(false);
  const [isOpenNewContractDialog, setIsOpenNewContractDialog] =
    useState<boolean>(false);
  const [selectedIndex, setselectedIndex] = useState(0);
  const [isAddedNewContract, setIsAddedNewContract] = useState<boolean>(false);
  const [longTermContract, setLongTermContract] = useState<any>(null);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  const request = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  );

  useEffect(() => {
    !request?.contractId &&
      request?.userId &&
      findUserBuyerContracts({
        userId: request?.userId,
        buyerId: request?.buyerId,
      }).then((contracts: any) => {
        setLongTermContract(
          contracts.data.filter(
            (contract: any) => contract.contractType === ContractType.LongTerm
          )[0]
        );
      });

    setIsErrorMessageShown(false);
    refetchData();
  }, [
    isChangedRequestData,
    isAddedNewContract,
    request?.userId,
    request?.buyerId,
  ]);

  const notRejectedInvoices = request?.invoices?.filter(
    (invoice: Invoice) => invoice.status !== RequestStatus.RejectedAdmin
  );

  const downloadRequestDocumentation = (request: Request) => {
    downloadDocumentation({
      requestId: request.id,
      name: `dokumentacija_zahtev_${request.contractId}.zip`,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className={styles["admin-request-preview"]}
      >
        <DialogHeader>
          {t("dialog.requests.requestDetails")}: {request?.id}
        </DialogHeader>

        <DialogBody>
          <div>
            {t("table.requests.user")}: {request?.clientCompanyName} -{" "}
            {t("table.requests.buyer")}: {request?.buyerCompanyName}
          </div>
          <div>
            {request?.contractId ? (
              <div className={styles["admin-request-preview__new-contract"]}>
                <p>
                  {t("dialog.contracts.contractNumber")}:&nbsp;
                  {request.contractNumber
                    ? request.contractNumber
                    : t("dialog.contracts.contractDoesnHaveNumber")}
                </p>
                {request?.status !== RequestStatus.ApprovedUser && (
                  <FlatButton
                    onClick={() => downloadRequestDocumentation(request)}
                  >
                    {t("dialog.requests.cta.createDocumentation")}
                  </FlatButton>
                )}
              </div>
            ) : longTermContract?.contractType === ContractType.LongTerm ? (
              <div className={styles["admin-request-preview__new-contract"]}>
                <p>
                  {t("dialog.contracts.contractNumber")}:&nbsp;
                  {longTermContract?.contractNumber}
                </p>
              </div>
            ) : (
              <>
                <div className={styles["admin-request-preview__new-contract"]}>
                  <p>{t("dialog.contracts.thereIsnNoActiveContract")}</p>
                  {(request?.status === RequestStatus.ApprovedAdmin ||
                    request?.status === RequestStatus.ApprovedUser ||
                    request?.status === RequestStatus.Realized) && (
                    <FlatButton
                      onClick={() => {
                        setIsOpenNewContractDialog(true);
                      }}
                    >
                      {t("dialog.contracts.makeNewContract")}
                    </FlatButton>
                  )}
                </div>
                {isErrorMessageShown && (
                  <p className={styles["admin-request-preview__error-message"]}>
                    {t("dialog.contracts.noContractErrorMessage")}
                  </p>
                )}
              </>
            )}
          </div>

          <InvoicesList
            actions={false}
            invoices={request?.invoices}
            setIsOpenNewInvoice={(value) => setisOpenInvoicePreview(value)}
            setSelectedIndex={setselectedIndex}
            className={styles["admin-request-preview__invoice_list"]}
          />
          <div className={styles["admin-request-preview__additional-files"]}>
            <div>
              <span
                className={
                  styles["admin-request-preview__additional-files-name"]
                }
              >
                {t("dialog.requests.additionalFiles")}:
              </span>
              {request?.files.map((file: IFile, index: number) => (
                <button
                  key={index}
                  onClick={() =>
                    downloadFile({
                      fileReference: file.fileReference,
                      name: file.name,
                      type: file.type,
                    })
                  }
                  className={styles["admin-request-preview__file"]}
                >
                  {file.name}
                </button>
              ))}
            </div>
            {request?.adminComment && (
              <button
                className={styles["admin-request-preview__message"]}
                onClick={() => setisOpenMessagePreview(true)}
              >
                {t("dialog.requests.message")}
              </button>
            )}
          </div>
          <div className={styles["admin-request-preview__additional_info"]}>
            {request?.status && (
              <p
                className={
                  styles["admin-request-preview__additional_info-payment-data"]
                }
              >
                {t("table.requests.reqStatus.name")}:
                <Status title={request?.status} />
              </p>
            )}
            <p
              className={
                styles["admin-request-preview__additional_info-payment-data"]
              }
            >
              {t("dialog.requests.total")}:&nbsp;
              {request?.paymentData.amount &&
                formatNumber(request?.paymentData.amount)}
            </p>
            <p
              className={
                styles["admin-request-preview__additional_info-payment-data"]
              }
            >
              {t("dialog.requests.paymentDate")}:&nbsp;
              {request?.maturityDate
                ? localizedTime(request?.maturityDate)
                : ""}
            </p>
          </div>
          <FlatButton
            className={classnames(
              styles["admin-request-preview__flatbutton-wide"],
              styles["admin-request-preview__flatbutton-total"]
            )}
            color="blue"
            onClick={() => dispatch(openTotalRequestPreview(null))}
          >
            {t("dialog.requests.totalCalculation")}
          </FlatButton>
          <div className={styles["admin-request-preview__buttons-wrapper"]}>
            {request?.status === RequestStatus.Created && (
              <FlatButton
                className={styles["admin-request-preview__flatbutton-small"]}
                disabled={isFetching}
                onClick={() => setisOpenSendToChangeDialog(true)}
              >
                {t("dialog.requests.cta.sendForChange")}
              </FlatButton>
            )}
            {request?.status !== RequestStatus.RejectedAdmin &&
              request?.status !== RequestStatus.Charged && (
                <FlatButton
                  className={classnames({
                    [styles["admin-request-preview__flatbutton-small"]]:
                      request?.status === RequestStatus.Created,
                    [styles["admin-request-preview__flatbutton-wide"]]:
                      request?.status !== RequestStatus.Created,
                  })}
                  color="red"
                  disabled={isFetching}
                  onClick={async () => {
                    await updateRequest({
                      requestId: Number(requestPreviewId),
                      requestStatus: RequestStatus.RejectedAdmin,
                    });
                    onStatusChange(true);
                    onClose();

                    setTimeout(() => {
                      onStatusChange(false);
                    }, 0);
                  }}
                >
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    t("dialog.requests.cta.rejectRequest")
                  )}
                </FlatButton>
              )}

            {request?.status === RequestStatus.ApprovedAdmin && (
              <FlatButton
                className={styles["admin-request-preview__flatbutton-wide"]}
                color="blue"
                disabled={isFetching}
                onClick={async () => {
                  await updateRequest({
                    requestId: Number(requestPreviewId),
                    requestStatus: RequestStatus.Realized,
                  });
                  onStatusChange(true);
                  onClose();

                  setTimeout(() => {
                    onStatusChange(false);
                  }, 0);
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.realizeRequest")
                )}
              </FlatButton>
            )}
            {request?.status === RequestStatus.Realized && (
              <FlatButton
                className={styles["admin-request-preview__flatbutton-charged"]}
                disabled={isFetching}
                color="green"
                onClick={async () => {
                  await updateRequest({
                    requestId: Number(requestPreviewId),
                    requestStatus: RequestStatus.Charged,
                  });
                  onStatusChange(true);
                  onClose();

                  setTimeout(() => {
                    onStatusChange(false);
                  }, 0);
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.requestIsCharged")
                )}
              </FlatButton>
            )}
            {request?.status === RequestStatus.ApprovedUser && (
              <FlatButton
                className={
                  styles["admin-request-preview__flatbutton-documentation"]
                }
                color="blue"
                disabled={isFetching}
                onClick={async () => {
                  if (request.contractId) {
                    await downloadRequestDocumentation(request);
                    await updateRequest({
                      requestId: Number(requestPreviewId),
                      requestStatus: RequestStatus.Realized,
                    });
                    onStatusChange(true);
                    refetchBuyers();
                    onClose();

                    setTimeout(() => {
                      onStatusChange(false);
                    }, 0);
                  } else {
                    setIsErrorMessageShown(true);
                  }
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.createDocumentation")
                )}
              </FlatButton>
            )}
            {request?.status === RequestStatus.Created && (
              <FlatButton
                className={styles["admin-request-preview__flatbutton-small"]}
                color="blue"
                disabled={isFetching}
                onClick={async () => {
                  const requestObject: {
                    requestId: number;
                    requestStatus: RequestStatus;
                    invoices:
                      | {
                          invoiceId: number;
                          invoiceStatus: RequestStatus;
                        }[]
                      | undefined;
                    contractId?: number;
                  } = {
                    requestId: Number(requestPreviewId),
                    requestStatus: RequestStatus.ApprovedAdmin,
                    invoices: notRejectedInvoices?.map((invoice: Invoice) => ({
                      invoiceId: invoice.id,
                      invoiceStatus: RequestStatus.ApprovedAdmin,
                    })),
                  };
                  if (longTermContract?.id !== undefined) {
                    requestObject.contractId = longTermContract.id;
                  }
                  await updateRequest(requestObject);
                  onStatusChange(true);
                  onClose();

                  setTimeout(() => {
                    onStatusChange(false);
                  }, 0);
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.approveRequest")
                )}
              </FlatButton>
            )}
          </div>
        </DialogBody>
      </Dialog>

      <TotalRequestPreview
        {...useTotalRequestPreviewState()}
        data={request as Request}
      />

      <InvoicePreview
        invoiceIndex={selectedIndex}
        open={isOpenInvoicePreview}
        onClose={() => setisOpenInvoicePreview(false)}
      />

      <RequestMessageDialog
        open={isOpenSendToChangeDialog}
        onClose={() => setisOpenSendToChangeDialog(false)}
      />

      <MessagePreview
        message={request?.adminComment || ""}
        open={isOpenMessagePreview}
        onClose={() => setisOpenMessagePreview(false)}
        idRequest={request?.id || 0}
      />

      <NewContract
        open={isOpenNewContractDialog}
        onClose={() => setIsOpenNewContractDialog(false)}
        setIsAddedNewContract={(value: boolean) => setIsAddedNewContract(value)}
        requestData={request}
      />
    </>
  );
};
