import api from "../api";
import { Contract } from "./contracts.class";

interface IAllContractsPayload {
  page: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
  contractType: string;
  status: string;
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    allContracts: build.query({
      query: ({
        page,
        pageSize,
        sortField,
        sortDirection,
        contractType,
        status,
      }: IAllContractsPayload) =>
        `contracts/all?pageSize=${pageSize}&pageNumber=${page}&sortField=${
          sortField || ""
        }&sortDirection=${sortDirection || ""}&contractType=${
          contractType || ""
        }&status=${status || ""}`,
    }),
    newContract: build.mutation({
      query: (payload: Contract) => ({
        url: "contracts/add",
        method: "POST",
        body: payload,
      }),
    }),
    updateContract: build.mutation({
      query: (payload: Contract) => ({
        url: "contracts/update",
        method: "PUT",
        body: payload,
      }),
    }),
    generatePromissoryNotes: build.mutation({
      query: ({contractId, name})  => {
        return {
          url: `contracts/documentation/generate/pn/${contractId}`,
          method: "GET",
          responseHandler: async (response) => {
            const url = window.URL.createObjectURL(await response.blob());

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
          },
          cache: "no-cache",
        };
      },
    }),
    generateContract: build.mutation({
      query: ({contractId, name})  => {
        return {
          url: `contracts/documentation/generate/${contractId}`,
          method: "GET",
          responseHandler: async (response) => {
            const url = window.URL.createObjectURL(await response.blob());

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
          },
          cache: "no-cache",
        };
      },
    }),
    findUserBuyerContracts: build.mutation({
      query: ({userId, buyerId}) => ({
        url: `contracts?u=${userId}&b=${buyerId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAllContractsQuery,
  useNewContractMutation,
  useUpdateContractMutation,
  useGeneratePromissoryNotesMutation,
  useGenerateContractMutation,
  useFindUserBuyerContractsMutation
} = extendedApi;
