import { FC } from "react";
import { Outlet } from "react-router-dom";
import { EditBuyer } from "../../components/new-buyer/new-buyer";
import { Layout } from "../../components/layout/layout";
import { NewRequest } from "../../components/new-request/new-request";
import { useEditBuyerDialogState } from "../../state/buyers/buyers.slice";
import { useRequestsDialogState } from "../../state/requests/requests.slice";

export const Admin: FC<{}> = () => {
  return (
    <Layout>
      <Outlet />
    
      {/* Dialogs */}
      <NewRequest {...useRequestsDialogState()} />
      <EditBuyer {...useEditBuyerDialogState()} />
    </Layout>
  );
};
