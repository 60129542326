import { configureStore } from "@reduxjs/toolkit";
import usersSlice from "./user/users.slice";
import apiReducer from "./api";
import { authorizationSlice } from "./auth/auth.service";
import authReducer from "./auth/auth.slice";
import buyersReducer from "./buyers/buyers.slice";
import requestSlice from "./requests/request.slice";
import requestsReducer from "./requests/requests.slice";
import totalRequestSlice from "./requests/total-request.slice";
import contractsSlice from "./contracts/contracts.slice";
import paymentsSlice from "./payments/payments.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    authorization: authorizationSlice.reducer,
    api: apiReducer.reducer,
    buyers: buyersReducer.reducer,
    requests: requestsReducer.reducer,
    request: requestSlice.reducer,
    users: usersSlice.reducer,
    totalRequest: totalRequestSlice.reducer,
    contracts: contractsSlice.reducer,
    payments: paymentsSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiReducer.middleware,
      authorizationSlice.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
