import { classnames } from "@faktoring/util";
import { FC, forwardRef, HTMLProps, ReactNode } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-picker.module.scss";

export interface IDatePickProps {
  className?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  icon?: ReactNode;
  value: Date | null;
  onChange: (value: Date | null) => void;
  startDate?: Date;
  endDate?: Date;
}

export const DatePick: FC<IDatePickProps> = ({
  className,
  label,
  placeholder,
  disabled,
  icon,
  value,
  onChange,
  startDate,
  endDate,
  ...props
}) => {
  const classNames = classnames(styles["date-picker"], className);

  return (
    <div className={classNames}>
      {label && <span className={styles["date-picker__label"]}>{label}</span>}
      <DatePicker
        {...props}
        selected={value}
        onChange={(date) => {
          if (date) {
            const newDate = date
            newDate.setHours(12)
            onChange(newDate)
          }
        }}
        minDate={startDate}
        maxDate={endDate}
        popperPlacement="bottom"
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder || ''}
        disabled={disabled}
        customInput={<CustomInputField icon={icon} />}
      >
        {placeholder && <div className={styles["date-picker__title"]}>{placeholder}</div>}
      </DatePicker>
    </div>
  );
};

interface ICustomInputFiledProps extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode;
}

const CustomInputField = forwardRef<any, ICustomInputFiledProps>(
  (props, ref) => {
    const { value, onClick, onChange, onBlur, placeholder, icon, disabled } = props;

    return (
      <div className={styles["date-picker__button"]} onClick={onClick} ref={ref}>
        <input
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          ref={ref}
          disabled={disabled}
        />
        <div className={styles["date-picker__button-icon"]}> {icon} </div>
      </div>
    );
  }
);
