import { classnames } from "@faktoring/util";
import { FC, ReactNode } from "react";
import styles from "./side-nav-button.module.scss";

export interface ISideNavButton {
  className?: string;
  isOpenSideBar: boolean;
  icon: ReactNode;
  title?: string;
  counter?: number | null;
  onClick?: () => void;
  tooltip?: string | undefined;
  disabled?: boolean;
  active?: boolean;
}

export const SideNavButton: FC<ISideNavButton> = ({
  icon,
  title,
  onClick,
  counter,
  className,
  isOpenSideBar,
  tooltip,
  disabled,
  active,
}) => {
  const classes = classnames(className, styles["side-nav-button"], {
    [styles["side-nav-button--open"]]: isOpenSideBar === true,
  });

  return (
    <button disabled={disabled} className={classes} onClick={onClick}>
      <div
        className={classnames(styles["side-nav-button__icon"], {
          [styles["side-nav-button__icon-active"]]: active ? active : false,
        })}
      >
        {icon}
      </div>
      {tooltip && (
        <span className={styles["side-nav-button__tooltip"]}> {tooltip} </span>
      )}
      <div className={styles["side-nav-button__title"]}>
        {title}
        {counter && (
          <span className={styles["side-nav-button__counter"]}>{counter}</span>
        )}
      </div>
    </button>
  );
};
