import { Step, Stepper } from "@faktoring/ui";
import { FC } from "react";
import styles from "./stepper-header.module.scss";

interface INewRequestProps {
  steps: number;
  activeStep: number;
  title: string;
}

export const NewRequestHeader: FC<INewRequestProps> = ({
  steps,
  activeStep,
  title,
}) => {
  return (
    <div className={styles["new-request__header"]}>
      <Stepper activeStep={activeStep}>
        {[...Array(steps)].map((index: number) => <Step key={index + 1}/>)}
      </Stepper>
      <h3>{`${activeStep}. ${title}`}</h3>
    </div>
  );
};
