import {
  Dialog,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
  Spinner,
} from "@faktoring/ui";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { localizedCurrency, localizedTime } from "../../pages/users/users";
import { Roles } from "../../state/auth/auth-params";
import { selectDialogState } from "../../state/requests/request.slice";
import {
  useAllRequestsQuery,
  useDownloadFileMutation,
  useGetPaymentPerInvoiceMutation,
  useRequestsQuery,
  useUpdateInvoiceStatusMutation,
} from "../../state/requests/requests-api.service";
import { Request } from "../../state/requests/requests.class";
import {
  selectRequestState,
  selectRequestsPageOptions,
} from "../../state/requests/requests.slice";
import { useUserQuery } from "../../state/user/users-api.service";
import i18next from "i18next";
import styles from "./invoice-preview.module.scss";
import { InvoicePaymentDialog } from "../invoice-payment-dialog/invoice-payment-dialog";
import { InvoicePaymentsPreview } from "../invoice-payments-preview/invoice-payments-preview";

interface IInvoicePreviewProps {
  invoiceIndex: number;
  open: boolean;
  onClose: () => void;
}

interface IFile {
  fileReference: string;
  name: string;
  type: string;
}

const fetchRequests = {
  [Roles.Admin]: useAllRequestsQuery,
  [Roles.Client]: useRequestsQuery,
};

export const formatNumber = (number: string | number) => {
  return Number(number).toLocaleString(`${i18next.t("date")}`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const InvoicePreview: FC<IInvoicePreviewProps> = ({
  invoiceIndex,
  open,
  onClose,
}) => {
  const { pageSize, page } = useSelector(selectRequestsPageOptions);
  const { data: userInfo } = useUserQuery(null);
  const {
    requestFilteredStatus,
    requestsSearchedValue,
    requestsSortField: sortField,
    requestsSortDirection: sortDirection,
  } = useSelector(selectDialogState);
  const { requestsData: data } = useSelector(selectRequestState);
  const { refetch: refetchData, isFetching } = fetchRequests[
    userInfo.role as Roles
  ]({
    page,
    pageSize,
    status: requestFilteredStatus,
    searchedValue: requestsSearchedValue,
    sortDirection,
    sortField,
  });
  const [updateInvoice] = useUpdateInvoiceStatusMutation();
  const [downloadFile] = useDownloadFileMutation();
  const [getPaymentsPerInvoice] = useGetPaymentPerInvoiceMutation();
  const { requestPreviewId } = useSelector(selectDialogState);
  const [openInvoicePaymentDialog, setOpenInvoicePaymentDialog] =
    useState<boolean>(false);
  const [
    openInvoicePaymentsPreviewDialog,
    setOpenInvoicePaymentsPreviewDialog,
  ] = useState<boolean>(false);
  const [invoicePayments, setInvoicePayments] = useState<any>([]);
  const [totalInvoicePayments, setTotalInvoicePayments] = useState<number>(0);
  const { t } = useTranslation();

  const invoicePaymentData = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  )?.invoices[invoiceIndex]?.paymentData;

  const invoiceData = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  )?.invoices[invoiceIndex];

  const request = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  );

  useEffect(() => {
    invoiceData &&
      getPaymentsPerInvoice(invoiceData.id).then((payments: any) =>
        setInvoicePayments(payments.data)
      );
  }, [invoiceData]);

  useEffect(() => {
    const totalAmount = invoicePayments?.reduce(
      (total: number, payment: any) => total + payment.amount,
      0
    );

    setTotalInvoicePayments(totalAmount);
  }, [invoicePayments]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogHeader>
          {t("dialog.requests.invoiceData")}: {invoiceData?.invoiceNumber}
        </DialogHeader>

        <DialogBody className={styles["invoice-preview"]}>
          {request?.status === RequestStatus.Realized && (
            <FlatButton
              onClick={() => setOpenInvoicePaymentsPreviewDialog(true)}
            >
              {t("dialog.requests.allPayments")}
            </FlatButton>
          )}

          <div className={styles["invoice-preview__dates"]}>
            <p>
              {t("dialog.requests.issueDate")}:&nbsp;
              {localizedTime(invoiceData?.issueDate || "0")}
            </p>
            <p>
              {t("dialog.requests.paymentDate")}:&nbsp;
              {localizedTime(invoiceData?.paymentDate || "0")}
            </p>
          </div>
          {userInfo.role === Roles.Admin && (
            <div className={styles["invoice-preview__calculation"]}>
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.amount")}`}
                value={formatNumber(invoicePaymentData?.amount || 0)}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.retainedAdvancePercent")}`}
                value={invoicePaymentData?.retainedAdvancePercent + "%"}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.retainedAdvanceAmount")}`}
                value={formatNumber(
                  invoicePaymentData?.retainedAdvanceAmount || 0
                )}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.amountAfterAdvance")}`}
                value={formatNumber(
                  invoicePaymentData?.amountAfterAdvance || 0
                )}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.dailyInterestRatePercent")}`}
                value={`${invoicePaymentData?.dailyInterestRatePercent}%`}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.monthlyInterestRatePercent")}`}
                value={`${invoicePaymentData?.monthlyInterestRatePercent}%`}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.totalDays")}`}
                value={invoicePaymentData?.totalDays || ""}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.interestRateTotal")}`}
                value={formatNumber(invoicePaymentData?.interestRateTotal || 0)}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.appFeePercent")}`}
                value={invoicePaymentData?.appFeePercent + "%"}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.appFeeTotal")}`}
                value={formatNumber(invoicePaymentData?.appFeeTotal || 0)}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.totalAppExpense")}`}
                value={formatNumber(invoicePaymentData?.totalAppExpense || 0)}
                disabled={true}
              />
              <InputField
                className={styles["invoice-preview__input-field"]}
                label={`${t("dialog.requests.finalPaymentAmount")}`}
                value={formatNumber(
                  invoicePaymentData?.finalPaymentAmount || 0
                )}
                disabled={true}
              />
            </div>
          )}

          {userInfo.role === Roles.Client && (
            <p>
              {`${t("dialog.requests.amount")}`}:&nbsp;
              {formatNumber(invoicePaymentData?.amount || 0)}
            </p>
          )}

          <div className={styles["invoice-preview__file-list"]}>
            <span
              className={styles["client-request-preview__additional-files"]}
            >
              {t("dialog.requests.additionalFiles")}:&nbsp;
            </span>
            {invoiceData?.files.map((file: IFile, index: number) => (
              <button
                key={index}
                onClick={() =>
                  downloadFile({
                    fileReference: file.fileReference,
                    name: file.name,
                    type: file.type,
                  })
                }
                className={styles["invoice-preview__file"]}
              >
                {file.name}
              </button>
            ))}
          </div>

          {(request?.status === RequestStatus.Realized ||
            request?.status === RequestStatus.Charged) && (
              <div>
                {t("dialog.requests.totalPaymentsPerInvoice")}:&nbsp;
                {invoicePayments ? localizedCurrency(totalInvoicePayments) : 0}
              </div>
            )}

          {request?.status === RequestStatus.Created &&
            userInfo.role === Roles.Admin &&
            (invoiceData?.status === RequestStatus.RejectedAdmin ? (
              <FlatButton
                className={styles["invoice-preview__flatbutton__wide"]}
                color="blue"
                onClick={async () => {
                  await updateInvoice({
                    requestId: Number(requestPreviewId),
                    invoiceId: invoiceData.id,
                    invoiceStatus: RequestStatus.ApprovedAdmin,
                  });
                  await refetchData();
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.acceptInvoice")
                )}
              </FlatButton>
            ) : (
              <FlatButton
                className={styles["invoice-preview__flatbutton__wide"]}
                color="red"
                onClick={async () => {
                  await updateInvoice({
                    requestId: Number(requestPreviewId),
                    invoiceId: invoiceData ? invoiceData.id : 0,
                    invoiceStatus: RequestStatus.RejectedAdmin,
                  });
                  await refetchData();
                }}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.rejectInvoice")
                )}
              </FlatButton>
            ))}
          {invoiceData &&
            request?.status === RequestStatus.Realized &&
            invoiceData?.status !== RequestStatus.Charged &&
            userInfo.role === Roles.Admin && (
              <FlatButton
                className={styles["invoice-preview__flatbutton__wide"]}
                color="green"
                onClick={() => setOpenInvoicePaymentDialog(true)}
              >
                {isFetching ? (
                  <Spinner />
                ) : (
                  t("dialog.requests.cta.addInvoicePayment")
                )}
              </FlatButton>
            )}
        </DialogBody>
      </Dialog>

      <InvoicePaymentDialog
        invoiceIndex={invoiceIndex}
        open={openInvoicePaymentDialog}
        onClose={() => setOpenInvoicePaymentDialog(false)}
      />

      <InvoicePaymentsPreview
        paymentsData={invoicePayments}
        open={openInvoicePaymentsPreviewDialog}
        onClose={() => setOpenInvoicePaymentsPreviewDialog(false)}
      />
    </>
  );
};
