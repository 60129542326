import { DialogBody, SelectField } from "@faktoring/ui";
import { FC, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useFindAllBuyersUsersMutation } from "../../state/buyers/buyer-api.service";
import { Buyer } from "../../state/buyers/buyer.class";
import { ContractType } from "../../state/contracts/contracts.class";
import { Request } from "../../state/requests/requests.class";
import { User } from "../../state/user/user.class";
import { validateFieldRequired } from "../new-request/step-utils";
import styles from "./new-contract.module.scss";

interface IStepOneProps {
  form: any;
  allUsers: { content: User[] };
  requestData: Request;
}

export const StepOneUserBuyer: FC<IStepOneProps> = ({
  form,
  allUsers,
  requestData,
}) => {
  const [selectedUser, setSelectedUser] = useState<number>(
    form?.getState()?.values?.user?.id || ""
  );
  const [allBuyers, setAllBuyers] = useState<Buyer[] | []>([]);
  const [allBuyersUsers] = useFindAllBuyersUsersMutation();
  const { t } = useTranslation();

  const usersOptions =
    allUsers &&
    allUsers.content
      .filter((user: User) => user.companyName && user.id)
      .map((user: User) => {
        return {
          value: user.id,
          label: user.companyName,
        };
      });

  const getAllBuyersUsers = async (userId: number) => {
    userId &&
      (await allBuyersUsers(userId).then((res: any) => setAllBuyers(res.data)));
  };

  useEffect(() => {
    selectedUser && getAllBuyersUsers(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    form && setSelectedUser(form?.getState().values?.user?.id);
  }, [form?.getState().values?.user?.id]);

  return (
    <DialogBody className={styles["new-contract__step-one"]}>
      <div className={styles["new-contract__fields"]}>
        <Field
          name="user.id"
          initialValue={
            requestData
              ? usersOptions?.find(
                  (user: any) => user.label === requestData.clientCompanyName
                )?.value
              : selectedUser
          }
          type="select"
          validate={validateFieldRequired}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <SelectField
                  label={t("chart.client") || ""}
                  placeholder={t("dialog.contracts.chooseClient")}
                  options={usersOptions || [{ value: "", label: "" }]}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.id"
          type="select"
          initialValue={requestData && requestData.buyerId}
          validate={validateFieldRequired}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <SelectField
                  label={t("chart.buyer") || ""}
                  placeholder={t("dialog.contracts.chooseBuyer")}
                  options={allBuyers.map((buyer: Buyer) => {
                    return {
                      value: buyer.id,
                      label: buyer.companyName,
                    };
                  })}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="contractType"
          type="select"
          validate={validateFieldRequired}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <SelectField
                  label={t("dialog.contracts.contractType") || ""}
                  placeholder={t("dialog.contracts.chooseContractType")}
                  options={[
                    {
                      value: ContractType.OneTime,
                      label: t("dialog.contracts.contractOneTimeType"),
                    },
                    {
                      value: ContractType.LongTerm,
                      label: t("dialog.contracts.contractWithLimitType"),
                    },
                  ]}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </DialogBody>
  );
};
