import {
  DatePick,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
  SelectField,
} from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  useGeneratePromissoryNotesMutation,
  useUpdateContractMutation,
  useGenerateContractMutation
} from "../../state/contracts/contracts-api.service";
import {
  Contract,
  ContractStatus,
  ContractType,
} from "../../state/contracts/contracts.class";
import { validateFieldRequired } from "../new-request/step-utils";
import styles from "./contract-details.module.scss";

interface IContractDetailsProps {
  open: boolean;
  onClose: () => void;
  setIsContractChanged: (value: boolean) => void;
  data: Contract | undefined;
}

export const ContractDetails: FC<IContractDetailsProps> = ({
  open,
  onClose,
  setIsContractChanged,
  data,
}) => {
  const [updateContract] = useUpdateContractMutation();
  const [generatePromissoryNotes] = useGeneratePromissoryNotesMutation();
  const [generateContract] = useGenerateContractMutation();
  const usersData = data?.user;
  const buyersData = data?.buyer;
  const { t } = useTranslation();

  const startDateInitialValue =
    data && data.startDate && !isNaN(Date.parse(data.startDate as any))
      ? new Date(data.startDate)
      : null;

  const endDateInitialValue =
    data && data.endDate && !isNaN(Date.parse(data.endDate as any))
      ? new Date(data.endDate)
      : null;

  const labelMapping = {
    [ContractStatus.InPreparation]: t("dialog.contracts.status.inPreparation"),
    [ContractStatus.Expired]: t("dialog.contracts.status.expired"),
    [ContractStatus.Valid]: t("dialog.contracts.status.valid"),
  };

  const labelContractType = {
    [ContractType.OneTime]: t("dialog.contracts.contractOneTimeType"),
    [ContractType.LongTerm]: t("dialog.contracts.contractWithLimitType")
  }

  const onSubmit = async (contractsData: Contract) => {
    await updateContract(contractsData);

    setIsContractChanged(true);

    setTimeout(() => {
      setIsContractChanged(false);
    }, 0);
  };
  
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data ? data : new Contract()}
      render={({ form, pristine }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };
        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["contract-details"]}
          >
            <DialogHeader>{t("dialog.contracts.contractDetails")} {data?.contractType && ("- " + labelContractType[data.contractType])}</DialogHeader>

            <DialogBody className={styles["contract-details__body"]}>
              <div className={styles["contract-details__fields"]}>
                <Field
                  name="contractNumber"
                  type="text"
                  validate={validateFieldRequired}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          className={styles["contract-details__field"]}
                          label={`${t("dialog.contracts.contractNumber")}*` || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="startDate"
                  initialValue={startDateInitialValue}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <DatePick
                          icon={<IconMemo name="Datum-dospeca" />}
                          label={t("dialog.contracts.dateCreated") || ""}
                          {...input}
                          value={
                            !isNaN(Date.parse(input.value as any))
                              ? new Date(input.value as Date)
                              : null
                          }
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="endDate"
                  initialValue={endDateInitialValue}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <DatePick
                          icon={<IconMemo name="Datum-dospeca" />}
                          label={t("dialog.contracts.endDate") || ""}
                          {...input}
                          value={
                            !isNaN(Date.parse(input.value as any))
                              ? new Date(input.value as Date)
                              : null
                          }
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="status"
                  type="text"
                  initialValue={ContractStatus.InPreparation}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <SelectField
                          label={t("dialog.contracts.status.name") || ""}
                          placeholder={t("dialog.contracts.status.name") || ""}
                          options={Object.keys(ContractStatus).map((key) => ({
                            value:
                              ContractStatus[
                                key as keyof typeof ContractStatus
                              ],
                            label:
                              labelMapping[
                                ContractStatus[
                                  key as keyof typeof ContractStatus
                                ]
                              ],
                          }))}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="contractLimit"
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["new-contract__field"]}>
                        <InputField
                          type="number"
                          step="any"
                          className={styles["new-contract__field"]}
                          label={`${t("dialog.users.limit")}`}
                          {...input}
                        />
                        <div
                          className={styles["new-contract__field--validation"]}
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="contractLimitText"
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["new-contract__field"]}>
                        <InputField
                          type="text"
                          className={styles["new-contract__field"]}
                          label={`${t("dialog.contracts.limitWritten")}`}
                          {...input}
                        />
                        <div
                          className={styles["new-contract__field--validation"]}
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="retainedAdvancePercent"
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={`${t(
                            "dialog.users.retainedAdvancePercent"
                          )} (%)`}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="appFeePercent"
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={`${t("dialog.requests.appFeePercent")} (%)`}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="monthlyInterestRate"
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={`${t(
                            "dialog.requests.monthlyInterestRatePercent"
                          )} (%)`}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="promissoryNotes"
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={`${t("table.users.promissoryNoteCount")}`}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>

              <h4>{t("dialog.contracts.usersData")}</h4>
              <div className={styles["contract-details__fields"]}>
                <Field
                  name="user.id"
                  type="text"
                  initialValue={usersData && usersData.id}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__hidden-field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.companyName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />
                <Field
                  name="user.companyName"
                  type="text"
                  initialValue={usersData && usersData.companyName}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.companyName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.zipCode"
                  initialValue={usersData && usersData.zipCode}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.zipCode") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.city"
                  initialValue={usersData && usersData.city}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.city") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.street"
                  initialValue={usersData && usersData.street}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.street") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.doorNumber"
                  initialValue={usersData && usersData.doorNumber}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.doorNumber") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.phoneNumber"
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.phone") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.companyRegistrationNumber"
                  initialValue={
                    usersData && usersData.companyRegistrationNumber
                  }
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.buyers.registrationNumber") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.companyPib"
                  type="number"
                  initialValue={usersData && usersData.companyPib}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("table.buyers.pib") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.accountNumber"
                  initialValue={usersData && usersData.accountNumber}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.buyers.bankAccount") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.representativeFirstName"
                  initialValue={usersData && usersData.representativeFirstName}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.contracts.representativeName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.representativeLastName"
                  initialValue={usersData && usersData.representativeLastName}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={
                            t("dialog.contracts.representativeLastname") || ""
                          }
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.representativeJmbg"
                  initialValue={usersData && usersData.representativeJmbg}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.contracts.representativeJMBG") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="user.representativeType"
                  initialValue={usersData && usersData.representativeType}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={
                            t("dialog.contracts.representativeFunction") || ""
                          }
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>

              <h4>{t("dialog.contracts.buyersData")}</h4>
              <div
                className={classnames(
                  styles["contract-details__fields"],
                  styles["contract-details__last-field"]
                )}
              >
                <Field
                  name="buyer.id"
                  type="text"
                  initialValue={buyersData && buyersData.id}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__hidden-field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.companyName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.companyName"
                  initialValue={buyersData?.companyName}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.companyName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.zipCode"
                  initialValue={buyersData?.zipCode}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.zipCode") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.city"
                  initialValue={buyersData?.city}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.city") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.street"
                  initialValue={buyersData?.street}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.street") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.doorNumber"
                  initialValue={buyersData?.doorNumber}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.doorNumber") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.phoneNumber"
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.users.phone") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.companyRegistrationNumber"
                  initialValue={buyersData?.companyRegistrationNumber}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.buyers.registrationNumber") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.companyPib"
                  initialValue={buyersData?.companyPib}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("table.buyers.pib") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.accountNumber"
                  initialValue={buyersData?.accountNumber}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.buyers.bankAccount") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.representativeFirstName"
                  initialValue={buyersData?.representativeFirstName}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={t("dialog.contracts.representativeName") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.representativeLastName"
                  initialValue={buyersData?.representativeLastName}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={
                            t("dialog.contracts.representativeLastname") || ""
                          }
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.representativeJmbg"
                  initialValue={buyersData?.representativeJmbg}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="number"
                          className={styles["contract-details__field"]}
                          label={t("dialog.contracts.representativeJMBG") || ""}
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Field
                  name="buyer.representativeType"
                  initialValue={buyersData?.representativeType}
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["contract-details__field"]}>
                        <InputField
                          type="text"
                          className={styles["contract-details__field"]}
                          label={
                            t("dialog.contracts.representativeFunction") || ""
                          }
                          {...input}
                        />
                        <div
                          className={
                            styles["contract-details__field--validation"]
                          }
                        >
                          <div>{isError && meta.error}</div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>

              <DialogActions>
                <FlatButton onClick={closeAndReset} type="button">
                  {t("dialog.requests.cta.quit")}
                </FlatButton>

                <div className={styles["contract-details__additional-buttons"]}>
                  <FlatButton
                    onClick={() =>
                      data &&
                      generateContract({
                        contractId: data.id,
                        name: `ugovor_${data.contractNumber}.docx`,
                      })
                    }
                    type="button"
                    color="blue"
                  >
                    {t("dialog.contracts.downloadContract")}
                  </FlatButton>
                  <FlatButton
                    onClick={() =>
                      data &&
                      generatePromissoryNotes({
                        contractId: data.id,
                        name: `menicna_izjava_ugovora_${data.contractNumber}.doc`,
                      })
                    }
                    type="button"
                    color="blue"
                  >
                    {t("dialog.contracts.downloadPromissoryNotes")}
                  </FlatButton>
                </div>

                <FlatButton
                  disabled={pristine}
                  onClick={async () => {
                    await form.submit();
                    const isSubmitted = form.getState().submitSucceeded;

                    if (!isSubmitted) return;

                    closeAndReset();
                  }}
                  type="button"
                  color="blue"
                >
                  {t("dialog.users.save")}
                </FlatButton>
              </DialogActions>
            </DialogBody>
          </Dialog>
        );
      }}
    />
  );
};

const IconMemo = React.memo(Icon);
