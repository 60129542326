export class Buyer {
  public id: number = 0;
  public companyName: string = "";
  public shortCompanyName: string = "";
  public companyPib: string = "";
  public registrationNumber: string = "";
  public accountNumber: string = "";
  public representativeFirstName: string = "";
  public lastName: string = "";
  public representativeJmbg: string = "";
  public representativeType: string = "";
  public companyPhoneNumber: string = "";
  public companyEmail: string = "";
  public zipCode: string = "";
  public city: string = "";
  public street: string = "";
  public doorNumber: string = "";
  public cooperationStartDate: Date = new Date();
  public totalDebt: number = 0;
  public debtLimit: number = 0;
  public remainingLimit: number = 0;
  public representative = {
    firstName: '',
    lastName: '',
    jmbg: 0,
    type: ''
  };
  public creditScore = {
    score: 0,
    date: '',
    trend: '',
  }
}

export enum BuyerAccountData {
  dinar = "Dinarski",
  secondDinar = "Drugi dinarski",
  includedInPaymentTransactions = "Uključen u platni promet",
}