import {
  DatePick,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Roles } from "../../state/auth/auth-params";
import { selectUser } from "../../state/auth/auth.slice";
import {
  useAllBuyersQuery,
  useBuyersQuery,
  useUpdateBuyersCompanyDataMutation,
} from "../../state/buyers/buyer-api.service";
import { Buyer } from "../../state/buyers/buyer.class";
import {
  selectBuyersPageOptions,
  selectDialogState,
} from "../../state/buyers/buyers.slice";
import { localizedTime } from "../../pages/users/users";
import styles from "./buyer-edit.module.scss";

interface IEditBuyerProps {
  open: boolean;
  onClose: () => void;
  data: Buyer | undefined;
}

const emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRgx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

export const BuyerPreview: FC<IEditBuyerProps> = ({ open, onClose, data }) => {
  const [updateBuyersCompanyData] = useUpdateBuyersCompanyDataMutation();
  const { pageSize, page } = useSelector(selectBuyersPageOptions);
  const { sortDirection, sortField } = useSelector(selectDialogState);
  const [buyersData, setBuyersData] = useState<any>({})
  const userInfo = useSelector(selectUser);

  const { refetch: refetchBuyers } = useBuyersQuery(
    {
      page,
      pageSize,
      sortField,
      sortDirection,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { refetch: refetchAllBuyers } = useAllBuyersQuery(
    {
      page,
      pageSize,
      sortField,
      sortDirection,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();

  const validateFieldRequired = (value: string) => {
    if (!value) {
      return t("warnings.requiredField");
    }
  };

  useEffect(() => {
    const changedBuyersData = {...data}
    data && data.cooperationStartDate && (changedBuyersData.cooperationStartDate = new Date(data.cooperationStartDate))
    setBuyersData(changedBuyersData)
  }, [data])

  const onSubmit = async (values: Buyer) => {
    if (!data) return;
    const editedBuyersData: any = { ...values };
    editedBuyersData.debtLimit = Number(editedBuyersData.debtLimit);
    editedBuyersData.buyerId = data.id;

    await updateBuyersCompanyData(editedBuyersData);

    userInfo.role === Roles.Admin ? await refetchAllBuyers() : await refetchBuyers();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={buyersData}
      render={({ form, pristine }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["buyer-edit"]}
          >
            <DialogHeader> {t("dialog.users.buyersData")} </DialogHeader>

            <DialogBody className={styles["buyer-edit__body"]}>
              <div className={styles["buyer-edit__body-fields-wrapper"]}>
                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="companyPib"
                    type="number"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("table.buyers.pib")}`}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="registrationNumber"
                    type="number"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                            label={`${t("dialog.buyers.registrationNumber")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="companyName"
                    type="text"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.companyName")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="shortBusinessName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.shortCompanyName")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="zipCode"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.zipCode")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="city"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.city")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="street"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.street")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="doorNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.doorNumber")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="companyPhoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.requests.companyPhoneNumber")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyEmail"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.email")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="representative.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.lastName")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.jmbg"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.jmbg")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.type"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.representativeType")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="accountNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bankAccount")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="bank"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bank")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  {userInfo.role === Roles.Client && (
                    <Field
                      name="cooperationStartDate"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["buyer-edit__body-field"]}>
                            <DatePick
                              {...input}
                              label={t("table.buyers.cooperationStartDate") || ""}
                              className={
                                styles["buyer-edit__date-picker-field"]
                              }
                            />
                            <div
                              className={
                                styles["buyer-edit__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}

                  {userInfo.role === Roles.Admin && (
                    <>
                      <Field
                        name="creditScore.score"
                        type="text"
                        render={({ input, meta }) => {
                          const isError = meta.touched && meta.error;

                          return (
                            <div className={styles["buyer-edit__body-field"]}>
                              <InputField
                                {...input}
                                label={`${t("dialog.buyers.creditScore")}`}
                                disabled={true}
                              />
                              <div
                                className={
                                  styles["buyer-edit__body-field--validation"]
                                }
                              >
                                {isError && meta.error}
                              </div>
                            </div>
                          );
                        }}
                      />

                      <Field
                        name="creditScore.date"
                        type="text"
                        render={({ input, meta }) => {
                          const isError = meta.touched && meta.error;

                          return (
                            <div className={styles["buyer-edit__body-field"]}>
                              <InputField
                                {...input}
                                label={`${t("dialog.buyers.creditScoreDate")}`}
                                value={
                                  data?.creditScore?.date
                                    ? localizedTime(data?.creditScore?.date)
                                    : ""
                                }
                                disabled={true}
                              />
                              <div
                                className={
                                  styles["buyer-edit__body-field--validation"]
                                }
                              >
                                {isError && meta.error}
                              </div>
                            </div>
                          );
                        }}
                      />

                      <Field
                        name="creditScore.trend"
                        type="text"
                        render={({ input, meta }) => {
                          const isError = meta.touched && meta.error;

                          return (
                            <div className={styles["buyer-edit__body-field"]}>
                              <InputField
                                {...input}
                                label={`${t("dialog.buyers.trend")}`}
                                disabled={true}
                              />
                              <div
                                className={
                                  styles["buyer-edit__body-field--validation"]
                                }
                              >
                                {isError && meta.error}
                              </div>
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className={styles["buyer-edit__body-fields-contact"]}>
                <p
                  className={styles["buyer-edit__body-fields-contact-subtitle"]}
                >
                  {t("dialog.buyers.contactPersonsInfo")}
                </p>
                <div className={styles["buyer-edit__body-fields"]}>
                  <Field
                    name="contact.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.lastName")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.email"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.email")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.phoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.contactPhoneNum")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              {userInfo.role === Roles.Admin && (
                <div className={styles["buyer-edit__body-fields-limit"]}>
                  <Field
                    name="debtLimit"
                    type="number"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["buyer-edit__body-field-limit"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            label={`${t("dialog.users.limit")}`}
                          />
                          <div
                            className={
                              styles["buyer-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </DialogBody>

            <DialogActions className={styles["buyer-edit__dialog-actions"]}>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();
                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  closeAndReset();
                }}
                type="button"
                color="blue"
                disabled={pristine}
              >
                {t("dialog.users.saveChanges")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
