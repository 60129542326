import { IconButton, SideNavButton } from "@faktoring/ui";
import { classnames } from "@faktoring/util";
import { FC, Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectDialogState } from "../../state/requests/request.slice";
import styles from "./side-nav.module.scss";

export interface ISideNavProps {
  isOpenSideBar: boolean;
  setIsOpenSideBar: (value: boolean) => void;

  logOutData: {
    title: string;
    icon: ReactNode;
    handleClick: (el: string) => void;
    counter?: number;
  };

  className?: string;
  sideBarData: {
    title: string;
    statusCode: string;
    icon: ReactNode;
    handleClick: (el: string) => void;
    counter?: number | null;
    disabled?: boolean;
  }[];

  iconButtonsData: {
    icon: ReactNode;
    title: string;
    color?: "blue" | "orange";
    handleClick: (el: string) => void;
  }[];
}

export const SideNav: FC<ISideNavProps> = ({
  className,
  sideBarData,
  isOpenSideBar,
  setIsOpenSideBar,
  logOutData,
  iconButtonsData,
}) => {
  const toggleSideBar = () => setIsOpenSideBar(!isOpenSideBar);
  const { requestFilteredStatus } = useSelector(selectDialogState)

  const classes = classnames(className, styles["side-nav__container"], {
    [styles["side-nav--open"]]: isOpenSideBar === true,
  });

  return (
    <div className={classes}>
      <div>
        <div className={styles["side-nav__toogle-box"]}>
          <SideNavButton
            isOpenSideBar={isOpenSideBar}
            icon={
              isOpenSideBar ? (
                <span className={styles["side-nav__toogle-icon"]}>
                  &#x261A;
                </span>
              ) : (
                <span className={styles["side-nav__toogle-icon"]}>
                  &#x261B;
                </span>
              )
            }
            onClick={toggleSideBar}
          />
        </div>

        <div className={styles["side-nav__add-container"]}>
          {iconButtonsData.map(({ icon, title, color, handleClick }, i) => (
            <Fragment key={i}>
              {isOpenSideBar ? (
                <div
                  onClick={() => handleClick(title)}
                  className={styles["side-nav__icon-box"]}
                >
                  <IconButton color={color}>{icon}</IconButton>
                  <span> {title} </span>
                </div>
              ) : (
                <SideNavButton
                  onClick={() => handleClick(title)}
                  isOpenSideBar={isOpenSideBar}
                  icon={icon}
                  title={title}
                  tooltip={title}
                />
              )}
            </Fragment>
          ))}
        </div>

        <ul className={styles["side-nav__list"]}>
          {sideBarData.map(
            ({ title, statusCode, icon, handleClick, counter, disabled }, i) => (
              <Fragment key={i}>
                {i === 0 && <div className={styles["side-nav__divider"]} />}

                <li className={styles["side-nav__list-element"]}>
                  <SideNavButton
                    disabled={disabled}
                    isOpenSideBar={isOpenSideBar}
                    icon={icon}
                    title={title}
                    onClick={() => handleClick(title)}
                    counter={counter}
                    tooltip={title}
                    active={statusCode === requestFilteredStatus}
                  />
                </li>

                {i === sideBarData.length - 1 && (
                  <div className={styles["side-nav__divider"]} />
                )}
              </Fragment>
            )
          )}
        </ul>
      </div>

      <div className={styles["side-nav__list-element"]}>
        <SideNavButton
          isOpenSideBar={isOpenSideBar}
          icon={logOutData.icon}
          title={logOutData.title}
          onClick={() => logOutData.handleClick(logOutData.title)}
          tooltip={logOutData.title}
        />
      </div>
    </div>
  );
};
