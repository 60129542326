import { classnames } from "@faktoring/util";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./status-info.module.scss";
import { ContractStatus } from "../../../../faktoring-client/src/state/contracts/contracts.class";

export enum RequestStatus {
  Created = "CREATED",
  ApprovedAdmin = "APPROVED_ADMIN",
  ApprovedUser = "APPROVED_USER",
  OnChange = "PENDING_UPDATE",
  RejectedAdmin = "REJECTED_ADMIN",
  RejectedUser = "REJECTED_USER",
  Realized = "REALIZED",
  Charged = "CHARGED",
  Unknown = "UNKNOWN",
}

interface IStatusProps {
  title: RequestStatus | ContractStatus
}

export const Status: FC<IStatusProps> = ({ title }) => {
  const { t } = useTranslation();

  const renderTitle = (title: IStatusProps["title"]) => {
    switch(title) {
      case RequestStatus.Created: return t('table.requests.reqStatus.created').toUpperCase();
      case RequestStatus.ApprovedAdmin: return t('table.requests.reqStatus.approvedAdmin').toUpperCase();
      case RequestStatus.ApprovedUser: return t('table.requests.reqStatus.approvedUser').toUpperCase();
      case RequestStatus.RejectedAdmin: return t('table.requests.reqStatus.rejectedAdmin').toUpperCase();
      case RequestStatus.RejectedUser: return t('table.requests.reqStatus.rejectedUser').toUpperCase();
      case RequestStatus.Realized: return t('table.requests.reqStatus.realized').toUpperCase();
      case RequestStatus.Charged: return t('table.requests.reqStatus.charged').toUpperCase();
      case ContractStatus.InPreparation: return t('dialog.contracts.status.inPreparation').toUpperCase();
      case ContractStatus.Valid: return t('dialog.contracts.status.valid').toUpperCase();
      case ContractStatus.Expired: return t('dialog.contracts.status.expired').toUpperCase();
      case undefined: return t('table.requests.reqStatus.unknown').toUpperCase();
    }
  }

  return (
    <span
      className={classnames(styles["status-info"], {
        [styles["status-info__charged"]]: title === RequestStatus.Charged || title === ContractStatus.Valid,
        [styles["status-info__realized"]]: title === RequestStatus.Realized,
        [styles["status-info__accepted"]]: title === RequestStatus.ApprovedUser || title === RequestStatus.ApprovedAdmin,
        [styles["status-info__placed"]]: title === RequestStatus.Created || title === ContractStatus.InPreparation,
        [styles["status-info__rejected"]]: title === RequestStatus.RejectedAdmin || title === RequestStatus.RejectedUser || title === ContractStatus.Expired,
        [styles["status-info__unknown"]]:
          title === (RequestStatus.Unknown || null || undefined),
      })}
    >
      {renderTitle(title)}
    </span>
  );
};
