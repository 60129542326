import { FC, lazy, Suspense } from "react";
import styles from "./icon.module.scss";

interface IIconProps {
  className?: string;
  name: string;
}

export const Icon: FC<IIconProps> = ({ className, name, ...props }) => {
  const SomeIcon = lazy(() =>
    import(`../../assets/svg/${name}.svg`).then((s) => {
      return { default: s.ReactComponent };
    })
  );

  return (
    <>
      <Suspense fallback={<div className={styles["icon-placeholder"]}></div>}>
        <SomeIcon />
      </Suspense>
    </>
  );
};
