import { classnames } from "@faktoring/util";
import { FC, ReactNode } from "react";
import styles from "./step.module.scss";

interface IStepProps {
  index?: number;
  children?: ReactNode;
  className?: string;
  finished?: boolean;
  active?: boolean;
  disabled?: boolean;
  activeStep?: number;
}

export const Step: FC<IStepProps> = ({
  index,
  className,
  children,
  active,
  finished,
  disabled,
  activeStep,
  ...props
}) => {
  const isFinished =
    finished ||
    (activeStep !== undefined && index !== undefined && activeStep > index);

  const classes = classnames(className, styles["step"], {
    [styles["step--active"]]: active || activeStep === index,
    [styles["step--finished"]]: isFinished,
    [styles["step--disabled"]]: disabled === true,
  });

  return (
    <li className={classes} {...props}>
      <span>{isFinished ? "✓" : children || index}</span>
    </li>
  );
};
