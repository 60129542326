import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "../user/user.class";

const initialState = {
  userInfo: {
    id: 1,
    firstName: '',
    lastName: '',
    city: '',
    street: '',
    zipCode: '',
    doorNumber: '',
    companyName: '',
    companyPib: '',
    companyRegistrationNumber: '',
    companyEmail: '',
    companyPhoneNumber: '',
    foreignCompany: true,
    role: ''
  },
  userToken: '',
  userRefreshToken: '',
  isLoggedIn: false
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.userRefreshToken = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setUser, setToken, setIsLoggedIn, setRefreshToken } =
  authSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectUser = (state: RootState) => state.auth.userInfo;
export const selectToken = (state: RootState) => state.auth.userToken;
export const selectRefreshToken = (state: RootState) =>
  state.auth.userRefreshToken;

export default authSlice.reducer;
