import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "./auth/auth-params";
import { setRefreshToken, setToken } from "./auth/auth.slice";
import { RootState, store } from "./store";

const baseQuery = fetchBaseQuery({
  baseUrl: auth.appUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.userToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const requestData = {
    grant_type: "refresh_token",
    client_id: auth.clientId,
    client_secret: auth.clientSecret,
    refresh_token: (store.getState() as RootState).auth.userRefreshToken,
  };

  const encodeFormData = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  if (result.error && result.error.status === 401) {
    // get a new token
    const refreshResult = await baseQuery(
      {
        url: auth.openidUrl + "/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encodeFormData(requestData),
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      // store the new token
      api.dispatch(setToken((refreshResult as any).data.access_token));
      api.dispatch(setRefreshToken((refreshResult as any).data.refresh_token));

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      window.location.href = `${window.location.origin}/login`;
    }
  }
  return result;
};

export default createApi({
  keepUnusedDataFor: 0,
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
