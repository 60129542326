import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { FormApi } from "final-form";
import { FC } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  useNewAccountMutation,
  useUserAccountsQuery,
} from "../../state/accounts/accounts-api.service";
import styles from "./new-bank-account.module.scss";

type FormValues = Record<"accountNumber", string>;

interface INewBankAccountProps {
  open: boolean;
  onClose: () => void;
  form?: FormApi<FormValues, Partial<string>>;
}

interface INewAccountValue {
  accountNumber: string;
  bank: string;
  userId: number;
}

export const NewBankAccount: FC<INewBankAccountProps> = ({
  open,
  onClose,
  form,
}) => {
  const [addNewAccount] = useNewAccountMutation();
  const { t } = useTranslation();

  const { refetch } = useUserAccountsQuery(null);

  const onSubmit = async (values: INewAccountValue) => {
    await addNewAccount(values);
    refetch();
  };

  const currentAccountState = form?.getFieldState("accountNumber");

  return (
    <Form
      onSubmit={onSubmit}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog open={open} onClose={closeAndReset}>
            <DialogHeader> {t("dialog.requests.newAccount")} </DialogHeader>
            <DialogBody className={styles["new-bank-account"]}>
              <Field
                name="accountNumber"
                type="text"
                validate={(value) => {
                  if (!value) {
                    return t("warnings.requiredField");
                  }
                }}
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["new-bank-account__field"]}>
                      <InputField
                        {...input}
                        label={t("dialog.buyers.bankAccount") || ""}
                      />

                      <div
                        className={
                          styles["new-bank-account__field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="bank"
                type="text"
                validate={(value) => {
                  if (!value) {
                    return t("warnings.requiredField");
                  }
                }}
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["new-bank-account__field"]}>
                      <InputField
                        {...input}
                        label={t("dialog.buyers.bank") || ""}
                      />

                      <div
                        className={
                          styles["new-bank-account__field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />
            </DialogBody>

            <DialogActions>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.requests.cta.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();

                  const isSubmitted = form.getState().submitSucceeded;

                  if (isSubmitted) {
                    currentAccountState?.change(
                      form.getState().values.accountNumber
                    );
                    closeAndReset();
                  }
                }}
                type="button"
                color="blue"
              >
                {t("dialog.requests.cta.saveAccount")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
