import { IconButton } from "@faktoring/ui";
import { classnames } from "@faktoring/util";
import React, { FC, useEffect } from "react";
import styles from "./dialog.module.scss";
import { DialogPortal } from "./dialog-portal";

interface IDialogHeaderProps {
  children?: React.ReactNode;
  className?: string;
}

interface IDialogActionsProps {
  children?: React.ReactNode;
  className?: string;
  align?: "left" | "right";
}

interface IDialogBodyProps {
  children?: React.ReactNode | JSX.Element;
  className?: string;
}

interface IDialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode | JSX.Element;
  className?: string;
}

const DialogHeader: FC<IDialogHeaderProps> = ({ children, className }) => {
  return (
    <h3 className={classnames(className, styles["dialog__header"])}>
      {children}
    </h3>
  );
};

const DialogBody: FC<IDialogBodyProps> = ({ children, className }) => {
  return (
    <div className={classnames(className, styles["dialog__body"])}>
      {children}
    </div>
  );
};

const DialogActions: FC<IDialogActionsProps> = ({
  children,
  className,
  align,
}) => {
  const classes = classnames(className, styles["dialog__actions-group"], {
    [styles["dialog__actions-group--left"]]: align === "left",
    [styles["dialog__actions-group--right"]]: align === "right",
  });

  return <div className={classes}>{children}</div>;
};

const Dialog: FC<IDialogProps> = ({
  open,
  onClose,
  children,
  className,
  ...props
}) => {

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden'
    }
  }, [open])
  

  return (
    <DialogPortal wrapperId="react-portal-modal-container">
      <div
        className={classnames(styles["dialog-wrapper"], {
          [styles["dialog--open"]]: open,
        })}
      >
        <div className={styles["dialog-centered"]}>
          <div className={classnames(styles["dialog"], className)}>
            <IconButton
              color="blue"
              type="button"
              onClick={() => {
                document.body.style.overflowY = 'auto'
                onClose()
              }}
              className={styles["dialog__close"]}
            >
              ×
            </IconButton>
            {children}
          </div>
        </div>
      </div>
    </DialogPortal>
  );
};

export default Dialog;
export { DialogBody, DialogHeader, DialogActions };
