import {
  DatePick,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InfoBox,
  Spinner,
} from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import React, { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDownloadInvoicesFromSefMutation } from "../../state/requests/requests-api.service";
import { useUserQuery } from "../../state/user/users-api.service";
import { InvoicesList } from "../invoices-list/invoices-list";
import { UserDataSettingsDialog } from "../user-data-settings-dialog/user-data-settings-dialog";
import styles from "./download-invoices.module.scss";

type FormValues = Record<"invoiceList", string>;

export interface InvoiceFromSef {
  amount: number;
  actualDeliveryDate: string;
  creationDate: string | Date;
  currency: string;
  invoiceNumber: string;
  issueDate: string | Date;
  paymentDate: string | Date;
  pdfVase64: string;
  salesInvoiceId: string;
}

interface DownloadInvoicesProps {
  open: boolean;
  onClose: () => void;
  newRequestForm: FormApi<FormValues, Partial<string>>;
  setSelectedIndex?: (value: number | undefined) => void;
  setStep: (step: number) => void;
  form: any;
}
interface SearchValues {
  apiKey: string;
  dateFrom: Date;
  dateTo: Date;
}

export const DownloadInvoices: FC<DownloadInvoicesProps> = ({
  open,
  onClose,
  newRequestForm,
  setSelectedIndex,
  setStep,
  form,
}) => {
  const { data: userData } = useUserQuery(null);
  const [downloadingInvoicesFromSef, { isLoading }] =
    useDownloadInvoicesFromSefMutation();
  const [invoices, setInvoices] = useState<InvoiceFromSef[] | []>([]);
  const [isShowNoInvoiceMessage, setisShowNoInvoiceMessage] =
    useState<boolean>(false);
  const [isShownErrorMessage, setIsShownErrorMessage] =
    useState<boolean>(false);
  const [isOpenUserDataDialog, setIsOpenUserDataDialog] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const onSearchInvoices = async (values: SearchValues) => {
    await downloadingInvoicesFromSef({
      buyerPib: form.getState().values.buyerPib,
      ...values,
    }).then((res: any) => {
      if (res.error?.data.errorCode) {
        setIsShownErrorMessage(true);
        setInvoices([]);
        return;
      } else {
        setIsShownErrorMessage(false);
      }

      !res.data.length
        ? setisShowNoInvoiceMessage(true)
        : setisShowNoInvoiceMessage(false);

      const newInvoices: InvoiceFromSef[] = res.data.map(
        (invoice: InvoiceFromSef) => {
          return {
            ...invoice,
            paymentDate: new Date(invoice.paymentDate),
            issueDate: new Date(invoice.issueDate),
            creationDate: new Date(invoice.creationDate),
          };
        }
      );

      setInvoices(newInvoices);
    });
  };

  const removeInvoices = (i: number) => {
    let newInvoices = [...invoices];
    newInvoices.splice(i, 1);
    setInvoices(newInvoices);
  };

  return (
    <Form
      onSubmit={() => {
        return;
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={({ form }) => {
        const closeAndRestart = () => {
          form.restart();
          setInvoices([]);
          setisShowNoInvoiceMessage(false);
          setIsShownErrorMessage(false);
          onClose();
        };

        return (
          <>
            <Dialog open={open} onClose={closeAndRestart}>
              <DialogHeader>
                {t("dialog.requests.addInvoicesFromSef")}
              </DialogHeader>

              <DialogBody className={styles["download-invoices__body"]}>
                <InfoBox
                  title=""
                  className={styles["download-invoices__info-box"]}
                >
                  {t("dialog.requests.downloadingInvoicesFromSefInstructions")}
                  <Link
                    to="https://www.efaktura.gov.rs/view_file.php?file_id=314&cache=sr"
                    target="_blank"
                    className={styles["download-invoices__info-link"]}
                  >
                    {t("dialog.requests.downloadInstructionsToAccessSEF")}
                  </Link>
                  <Link
                    to="https://www.efaktura.gov.rs/view_file.php?file_id=59&cache=sr"
                    target="_blank"
                    className={styles["download-invoices__info-link"]}
                  >
                    {t(
                      "dialog.requests.downloadInstructionsForGeneratingAPIkey"
                    )}
                  </Link>
                </InfoBox>

                {!userData.apiKey && (
                  <>
                    <InfoBox
                      title=""
                      errorMessage={true}
                      className={styles["download-invoices__info-box"]}
                    >
                      <p
                        className={
                          styles["download-invoices__error-info-box-content"]
                        }
                      >
                        {t(
                          "dialog.requests.youNeedToAddAPIKeyBeforeDownloadingInvoices"
                        )}
                      </p>
                      <FlatButton
                        onClick={() => setIsOpenUserDataDialog(true)}
                        className={
                          styles["download-invoices__add-api-key-button"]
                        }
                      >
                        {t("dialog.requests.addApiKey")}
                      </FlatButton>
                    </InfoBox>
                  </>
                )}

                <div className={styles["download-invoices__dates-wrapper"]}>
                  <Field
                    name="dateFrom"
                    type="text"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["download-invoices__field"]}>
                          <DatePick
                            icon={<IconMemo name="Datum-saradnje" />}
                            endDate={
                              form.getState().values.dateTo || new Date()
                            }
                            placeholder={`${t(
                              "dialog.requests.invoiceCreationDate"
                            )}`}
                            {...input}
                          />

                          <div
                            className={
                              styles["download-invoices__field--validation"]
                            }
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <span className={styles["download-invoices__divider"]}></span>
                  <Field
                    name="dateTo"
                    type="text"
                    render={({ input }) => {
                      return (
                        <div className={styles["download-invoices__field"]}>
                          <DatePick
                            startDate={form.getState().values.dateFrom}
                            endDate={new Date()}
                            icon={<IconMemo name="Datum-saradnje" />}
                            placeholder={`${t("dialog.requests.endDate")}`}
                            {...input}
                          />
                        </div>
                      );
                    }}
                  />
                </div>

                <FlatButton
                  type="button"
                  className={styles["download-invoices__download-button"]}
                  color="orange"
                  onClick={() => onSearchInvoices(form.getState().values)}
                  disabled={!userData.apiKey || !form.getState().values.dateFrom}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    t("dialog.requests.cta.downloadInvoices")
                  )}
                </FlatButton>

                {invoices.length > 0 && (
                  <FieldArray name="invoices">
                    {() => {
                      return (
                        <div className={styles["new-request__step-container"]}>
                          <div>
                            <InvoicesList
                              invoices={invoices}
                              actions={true}
                              onRemoveElement={removeInvoices}
                              setSelectedIndex={() => setSelectedIndex}
                              setIsOpenNewInvoice={() => console.log("")}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                )}
                {isShowNoInvoiceMessage && (
                  <div
                    className={styles["download-invoices__no-invoice-message"]}
                  >
                    {t("dialog.requests.noDownloadedInvoices")}
                  </div>
                )}
                {isShownErrorMessage && (
                  <div
                    className={styles["download-invoices__no-invoice-message"]}
                  >
                    {t("dialog.requests.invalidApiKeyErrorMessage")}
                    <button onClick={() => setIsOpenUserDataDialog(true)} className={styles["download-invoices__change-key-button"]}>{t("dialog.requests.changeApiKey")}</button>
                  </div>
                )}
              </DialogBody>

              <DialogActions
                className={styles["download-invoices__dialog-actions"]}
              >
                <FlatButton type="button" onClick={closeAndRestart}>
                  {t("dialog.requests.cta.quit")}
                </FlatButton>
                <FlatButton
                  type="button"
                  color="blue"
                  disabled={!invoices.length}
                  onClick={() => {
                    invoices.forEach((invoice: InvoiceFromSef) =>
                      newRequestForm.mutators.push("invoices", invoice)
                    );

                    setStep(4);
                  }}
                >
                  {t("dialog.requests.cta.continue")}
                </FlatButton>
              </DialogActions>
            </Dialog>
            <UserDataSettingsDialog
              open={isOpenUserDataDialog}
              onClose={() => setIsOpenUserDataDialog(false)}
            />
          </>
        );
      }}
    />
  );
};

const IconMemo = React.memo(Icon);
