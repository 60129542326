import React, { FC, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportPreview } from "../../components/report-preview/report-preview";
import {
  useReportsByDateQuery,
  useReportsByRequestQuery,
  useReportsQuery,
} from "../../state/reports/reports-api.service";
import {
  InvoiceForReports,
  Report,
  ReportByDate,
  ReportByRequests,
  initialReportByDate,
} from "../../state/reports/reports.class";
import { Table } from "@faktoring/ui";
import { useAllBuyersQuery } from "../../state/buyers/buyer-api.service";
import { Buyer } from "../../state/buyers/buyer.class";
import { localizedCurrency, localizedTime } from "../users/users";
import styles from "./reports.module.scss";

const BarChart = React.lazy(
  () => import("../../components/bar-chart/bar-chart")
);

export const Reports: FC = () => {
  const [reportByDay, setReportByDay] =
    useState<ReportByDate>(initialReportByDate);
  const [isOpenReportPreview, setIsOpenReportPreview] = useState(false);
  const { t } = useTranslation();
  const { data: reportsByMonth, isFetching } = useReportsQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: reportsByDay } = useReportsByDateQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: reportsByRequests } = useReportsByRequestQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: buyers } = useAllBuyersQuery(
    {
      page: 0,
      pageSize: 100,
      sortField: "",
      sortDirection: "",
    },
    { refetchOnMountOrArgChange: true }
  );

  const onIndexClick = (num: number) => {
    const report = reportsByDay?.content.find(
      (report) => report.date === dates[num]
    );

    report && setReportByDay(report);
    setIsOpenReportPreview(true);
  };

  const currentMonth = new Date().getMonth() + 1;
  const dates = reportsByDay?.content.map((report) => report.date) || [];

  const calculateTotalDayAmount = (invoices: InvoiceForReports[]) => {
    let totalAmount = 0;
    invoices.forEach((invoice) => (totalAmount += invoice.amount));
    return totalAmount;
  };

  const chartDataForMonths = {
    labels: reportsByMonth?.content
      .filter((report) => currentMonth <= report.month)
      .map(
        (report) =>
          `${new Date(`${report.month}/20`).toLocaleString(
            `${t("time.date")}`,
            { month: "long" }
          )}`
      ),
    datasets: [
      {
        label: t("table.reports.monthlyAmount"),
        data: reportsByMonth?.content
          .filter((report) => currentMonth <= report.month)
          .map((report: Report) => report.inflow),
        backgroundColor: "#50AF95",
        fill: true,
      },
    ],
  };

  const chartDataForDays = {
    labels: dates?.map(
      (date) =>
        `${new Date(`${date}`).toLocaleString(`${t("time.date")}`, {
          month: "numeric",
          day: "numeric",
        })}`
    ),
    datasets: [
      {
        label: t("table.reports.dailyAmount"),
        data: reportsByDay?.content.map((report) =>
          calculateTotalDayAmount(report.invoices)
        ),
        backgroundColor:
          reportsByDay?.content.map((report) =>
            new Date(report.date).setHours(0, 0, 0, 0) >=
            new Date().setHours(0, 0, 0, 0)
              ? "#50af95"
              : "#FFCD56"
          ) || "#50af95",
        fill: true,
      },
    ],
  };

  const columnsDefinition = [
    {
      header: t("table.requests.paymentDate"),
      field: (row: ReportByRequests) => (
        <p>
          {localizedTime(row.dateCreated)}
        </p>
      ),
    },
    {
      header: t("table.requests.user"),
      field: (row: ReportByRequests) => row.userCompanyName,
    },
    {
      header: t("table.requests.buyer"),
      field: (row: ReportByRequests) =>
        buyers?.content?.find((buyer: Buyer) => buyer.id === row.buyerId)
          ?.companyName,
    },
    {
      header: t("table.requests.requestedAmount"),
      field: (row: ReportByRequests) => localizedCurrency(row.amount),
    },
    {
      header: t("table.reports.retainedAmount"),
      field: (row: ReportByRequests) => localizedCurrency(row.retainedAmount),
    },
    {
      header: t("table.reports.interestPercent"),
      field: (row: ReportByRequests) => `${row.interestPercent}%`,
    },
    {
      header: t("table.reports.interestPercentAmount"),
      field: (row: ReportByRequests) => localizedCurrency(row.interestAmount),
    },
    {
      header: t("dialog.requests.appFeePercent"),
      field: (row: ReportByRequests) => `${row.appFeePercent}%`,
    },
    {
      header: t("dialog.requests.appFeeTotal"),
      field: (row: ReportByRequests) => localizedCurrency(row.appFeeAmount),
    },
    {
      header: t("dialog.requests.totalAmount"),
      field: (row: ReportByRequests) => localizedCurrency(row.totalIncome),
    },
    {
      header: t("table.reports.penaltyInterest"),
      field: (row: ReportByRequests) => localizedCurrency(row.penaltyInterest),
    },
    {
      header: t("table.reports.latestMaturity"),
      field: (row: ReportByRequests) => (
        <p>
          {row.latestMaturity
            ? localizedTime(row.latestMaturity)
            : ""}
        </p>
      ),
    },
  ];

  return (
    <>
      {reportsByRequests && (
        <Table
          data={reportsByRequests}
          currentPage={0}
          isAdded={false}
          onPageChange={() => console.log("")}
          columns={columnsDefinition}
          className={styles["reports__table"]}
        />
      )}
      {!isFetching && (
        <Suspense fallback={<span></span>}>
          <BarChart
            chartData={chartDataForDays}
            title={t("chart.incomePerDay")}
            onIndexClick={(num: number) => {
              onIndexClick(num);
            }}
          />
        </Suspense>
      )}
      {!isFetching && (
        <Suspense fallback={<span></span>}>
          <BarChart
            chartData={chartDataForMonths}
            title={t("chart.incomePerMonth")}
          />
        </Suspense>
      )}

      <ReportPreview
        data={reportByDay}
        open={isOpenReportPreview}
        onClose={() => setIsOpenReportPreview(false)}
      />
    </>
  );
};
