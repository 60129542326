import {
  DropDown,
  FlatButton,
  SelectField,
  Spinner,
  Status,
  Table,
} from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ContractDetails } from "../../components/contract-details/contract-details";
import { NewContract } from "../../components/new-contract/new-contract";
import { useAllContractsQuery } from "../../state/contracts/contracts-api.service";
import {
  Contract,
  ContractStatus,
  ContractType,
} from "../../state/contracts/contracts.class";
import {
  onFilteredStatusChange,
  onFilteredTypeChange,
  onPageChange,
  onSortDirectionChange,
  onSortFieldChange,
  selectContractsData,
} from "../../state/contracts/contracts.slice";
import { localizedCurrency, localizedTime } from "../users/users";
import styles from "./contracts.module.scss";

interface IDropDownElement {
  isOpen: boolean;
  actions: {
    icon: ReactElement;
    name: string;
    handleClick: (value: string) => void;
  }[];
}

export const Contracts: FC = () => {
  const dispatch = useDispatch();
  const {
    page,
    pageSize,
    filteredStatus,
    filteredType,
    sortDirection,
    sortField,
  } = useSelector(selectContractsData);
  const { data: allContracts, refetch: refetchContracts } =
    useAllContractsQuery({
      page,
      pageSize,
      sortField,
      sortDirection,
      contractType: filteredType,
      status: filteredStatus,
    });
  const [isOpenNewContractDialog, setIsOpenNewContractDialog] =
    useState<boolean>(false);
  const [isOpenContractDetailsDialog, setIsOpenContractDetailsDialog] =
    useState<boolean>(false);
  const [isAddedNewContract, setIsAddedNewContract] = useState<boolean>(false);
  const [isContractChanged, setIsContractChanged] = useState<boolean>(false);
  const [dropDownData, setDropDownData] =
    useState<IDropDownElement[] | null>(null);
  const [selectedContractId, setSelectedContractId] = useState<number>(0);
  const { t } = useTranslation();

  const renderContractType = (contractType: string) => {
    if (contractType === ContractType.OneTime) {
      return t("dialog.contracts.oneTimeContract")
    } else if (contractType === ContractType.LongTerm) {
      return t("dialog.contracts.longTermContract")
    }
  }

  const columnsDefinitionContracts = [
    {
      header: (
        <div className={styles["contracts__th"]}>
          <span>{t("table.requests.idNumber")}</span>
        </div>
      ),
      field: (row: Contract) => row.id,
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "contractNumber"
                ) {
                  dispatch(onSortFieldChange("contractNumber"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "contractNumber") {
                  dispatch(onSortFieldChange("contractNumber"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("dialog.contracts.contractNumber")}
          </p>
        </div>
      ),
      field: (row: Contract) => row.contractNumber,
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "userCompanyName") {
                  dispatch(onSortFieldChange("userCompanyName"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "userCompanyName") {
                  dispatch(onSortFieldChange("userCompanyName"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("chart.client")}
          </p>
        </div>
      ),
      field: (row: any) => row.user.companyName,
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "buyerCompanyName") {
                  dispatch(onSortFieldChange("buyerCompanyName"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "buyerCompanyName") {
                  dispatch(onSortFieldChange("buyerCompanyName"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("chart.buyer")}
          </p>
        </div>
      ),
      field: (row: any) => row.buyer.companyName,
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "contractLimit") {
                  dispatch(onSortFieldChange("contractLimit"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "contractLimit") {
                  dispatch(onSortFieldChange("contractLimit"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("dialog.contracts.contractLimit")}
          </p>
        </div>
      ),
      field: (row: Contract) => 
      <p className={styles["contracts__text-align-right"]}>
        {localizedCurrency(row.contractLimit)}
      </p>
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "status") {
                  dispatch(onSortFieldChange("status"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "status") {
                  dispatch(onSortFieldChange("status"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("dialog.contracts.status.name")}
          </p>
        </div>
      ),
      field: (row: Contract) => <Status title={row.status} />,
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "startDate") {
                  dispatch(onSortFieldChange("startDate"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "startDate") {
                  dispatch(onSortFieldChange("startDate"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-left"]}>
            {t("dialog.contracts.dateCreated")}
          </p>
        </div>
      ),
      field: (row: Contract) =>
      <p className={styles["contracts__text-align-right"]}>
        {row.startDate ? localizedTime(row.startDate) : ""}
      </p>
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "endDate") {
                  dispatch(onSortFieldChange("endDate"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "endDate") {
                  dispatch(onSortFieldChange("endDate"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-left"]}>
            {t("dialog.contracts.endDate")}
          </p>
        </div>
      ),
      field: (row: Contract) => 
      <p className={styles["contracts__text-align-right"]}>
        {row.endDate ? localizedTime(row.endDate) : ""}
      </p>
    },
    {
      header: (
        <div
          className={classnames(
            styles["contracts__th"],
            styles["contracts__text-align-right"]
          )}
        >
          <div className={styles["contracts__th-buttons-wrapper"]}>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "contractType") {
                  dispatch(onSortFieldChange("contractType"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["contracts__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "contractType") {
                  dispatch(onSortFieldChange("contractType"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["contracts__text-align-right"]}>
            {t("dialog.contracts.contractType")}
          </p>
        </div>
      ),
      field: (row: any) => renderContractType(row.contractType),
    },
    {
      header: "",
      field: (row: Contract, index: number) => (
        <DropDown
          id={row.id.toString()}
          index={index}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
        />
      ),
    },
  ];

  const actions = [
    {
      icon: (
        <span className={styles["contracts__action-icon"]}>
          <Icon name="Detalj" />
        </span>
      ),
      name: t("table.actions.details"),
      handleClick: (value: string) => {
        setSelectedContractId(Number(value));
        setIsOpenContractDetailsDialog(true);
      },
    },
  ];

  useEffect(() => {
    (isContractChanged || isAddedNewContract) && refetchContracts();
  }, [isAddedNewContract, isContractChanged]);

  useEffect(() => {
    refetchContracts();
  }, [filteredStatus, filteredType]);

  useEffect(() => {
    const tableRowsNumber = allContracts?.content.length;

    if (!tableRowsNumber) return;

    const rowsButtonData = [];
    for (let i = 0; i < tableRowsNumber; i++) {
      rowsButtonData.push({ isOpen: false, actions });
    }

    setDropDownData(rowsButtonData);
  }, [allContracts]);

  return (
    <>
      <div className={styles["contracts__actions-button"]}>
        <FlatButton
          color="orange"
          onClick={() => setIsOpenNewContractDialog(true)}
          className={styles["contracts__actions-button--create-contract"]}
        >
          {t("dialog.contracts.makeNewContract")}
        </FlatButton>

        <p>{t("dialog.contracts.filters")}:</p>
        <div className={styles["contracts__select-wrapper"]}>
          <SelectField
            options={[
              {
                value: "All",
                label: t("dialog.contracts.allContracts"),
              },
              {
                value: ContractType.OneTime,
                label: t("dialog.contracts.contractOneTimeType"),
              },
              {
                value: ContractType.LongTerm,
                label: t("dialog.contracts.contractWithLimitType"),
              },
            ]}
            value={filteredType === "" ? "All" : filteredType}
            onChange={(e) =>
              dispatch(
                onFilteredTypeChange(
                  e.target.value === "All" ? "" : e.target.value
                )
              )
            }
            placeholder={t("dialog.contracts.chooseContractType")}
            label={t("dialog.contracts.contractType") || ""}
          />
        </div>
        <div className={styles["contracts__select-wrapper"]}>
          <SelectField
            options={[
              {
                value: "All",
                label: t("dialog.contracts.allContracts"),
              },
              {
                value: ContractStatus.InPreparation,
                label: t("dialog.contracts.status.inPreparation"),
              },
              {
                value: ContractStatus.Valid,
                label: t("dialog.contracts.status.valid"),
              },
              {
                value: ContractStatus.Expired,
                label: t("dialog.contracts.status.expired"),
              },
            ]}
            value={filteredStatus === "" ? "All" : filteredStatus}
            onChange={(e) =>
              dispatch(
                onFilteredStatusChange(
                  e.target.value === "All" ? "" : e.target.value
                )
              )
            }
            placeholder={t("dialog.contracts.chooseContractStatus")}
            label={t("dialog.contracts.status.name") || ""}
          />
        </div>
      </div>

      {allContracts ? (
        <Table
          isAdded={false}
          data={allContracts}
          currentPage={page}
          onPageChange={(value) => dispatch(onPageChange(value))}
          columns={columnsDefinitionContracts}
        />
      ) : (
        <div className={styles["contracts__spinner-wrapper"]}>
          <Spinner />
        </div>
      )}

      <NewContract
        open={isOpenNewContractDialog}
        onClose={() => setIsOpenNewContractDialog(false)}
        setIsAddedNewContract={(value: boolean) => setIsAddedNewContract(value)}
      />

      <ContractDetails
        open={isOpenContractDetailsDialog}
        onClose={() => setIsOpenContractDetailsDialog(false)}
        setIsContractChanged={(value: boolean) => setIsContractChanged(value)}
        data={allContracts?.content.find(
          (contract: Contract) => contract.id === selectedContractId
        )}
      />
    </>
  );
};

const IconMemo = React.memo(() => {
  return <Icon name="down" />;
});
