import api from "../api";
import { Report, ReportByDate, ReportByRequests } from "./reports.class";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    reports: build.query({
      query: () => "reports",
      transformResponse(response: Report[]) {
        return { content: response };
      },
    }),
    reportsByDate: build.query({
      query: () => "reports/invoices",
      transformResponse(response: ReportByDate[]) {
        return { content: response };
      },
    }),
    reportsByRequest: build.query({
      query: () => "reports/invoice-requests",
      transformResponse(response: ReportByRequests[]) {
        return { content: response };
      },
    }),
  }),
});

export const {
  useReportsQuery,
  useReportsByDateQuery,
  useReportsByRequestQuery,
} = extendedApi;
