import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { FormApi } from "final-form";
import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../state/auth/auth-params";
import { selectUser } from "../../state/auth/auth.slice";
import { Invoice } from "../../state/requests/invoice.class";
import { selectDialogState } from "../../state/requests/request.slice";
import { useUpdatePaymentDataMutation } from "../../state/requests/requests-api.service";
import { PaymentData, Request } from "../../state/requests/requests.class";
import { onChangeRequestData } from "../../state/requests/total-request.slice";
import styles from "./total-request-preview.module.scss";

type FormValues = Record<"total-request-preview", string>;
interface ITotalReqProps {
  open: boolean;
  data: Request;
  form?: FormApi<FormValues, Partial<string>>;
  onClose: () => void;
}

interface IChangedReqData {
  id: number;
  invoices: Invoice[];
  paymentData: PaymentData;
}

interface IChangedPaymentData {
  id: number;
  paymentData: PaymentData;
}

export const TotalRequestPreview: FC<ITotalReqProps> = ({
  open,
  onClose,
  data,
  form,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const [requestData, setRequestData] = useState<any>(data);
  const [updatePaymentData] = useUpdatePaymentDataMutation();
  const { requestPreviewId } = useSelector(selectDialogState);
  const { t } = useTranslation();

  const [retainedAdvancePercent, setRetainedAdvancePercent] = useState(0);
  const [appFeePercent, setAppFeePercent] = useState(0);
  const [monthlyInterestRatePercent, setMonthlyInterestRatePercent] =
    useState(0);

  useEffect(() => {
    setRequestData(data);
    setRetainedAdvancePercent(data?.paymentData.retainedAdvancePercent);
    setAppFeePercent(data?.paymentData.appFeePercent);
    setMonthlyInterestRatePercent(data?.paymentData.monthlyInterestRatePercent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, requestPreviewId, data]);

  const totalCalculation = async () => {
    let invoices = [...requestData.invoices];
    let allInvoicesPaymentData: IChangedPaymentData[] = [];
    let totalInterestRate = 0;

    let finalAmount = 0;

    invoices.forEach((invoice: Invoice) => {
      const invoiceCopy = { ...invoice };
      let invoicePaymentData = { ...invoiceCopy.paymentData };

      // Calculation per invoice
      invoicePaymentData.retainedAdvancePercent = retainedAdvancePercent;
      invoicePaymentData.retainedAdvanceAmount = Math.round(invoicePaymentData.amount * (retainedAdvancePercent/ 100));
      invoicePaymentData.amountAfterAdvance = invoicePaymentData.amount - invoicePaymentData.retainedAdvanceAmount;
      invoicePaymentData.appFeePercent = appFeePercent
      invoicePaymentData.appFeeTotal = Number((invoicePaymentData.amount * (invoicePaymentData.appFeePercent/100)).toFixed(3))
      invoicePaymentData.monthlyInterestRatePercent = Number(monthlyInterestRatePercent.toFixed(2))
      invoicePaymentData.dailyInterestRatePercent = Number((monthlyInterestRatePercent * 12/360).toFixed(3))
      invoicePaymentData.interestRateTotal = Number((invoicePaymentData.amountAfterAdvance * (invoicePaymentData.dailyInterestRatePercent/100) * invoicePaymentData.totalDays).toFixed(2))

      // Calculate final total interest rate
      if (invoice.status !== RequestStatus.RejectedAdmin) {
        totalInterestRate =  Number((totalInterestRate + invoicePaymentData.amountAfterAdvance * (invoicePaymentData.dailyInterestRatePercent/100) * invoicePaymentData.totalDays).toFixed(2))
      }

      invoicePaymentData.finalPaymentAmount = Number((invoicePaymentData.amountAfterAdvance - invoicePaymentData.interestRateTotal).toFixed(2));

      allInvoicesPaymentData.push({id: invoiceCopy.id, paymentData: invoicePaymentData})

      if (invoiceCopy?.status !== RequestStatus.RejectedAdmin) {
        finalAmount += invoice.paymentData.amount
      }
    })

    setRequestData((prevState: any) => ({
      id: data?.id,
      status: RequestStatus.Created,
      paymentData: {
        ...prevState.paymentData,
        amount: finalAmount,
        retainedAdvancePercent: retainedAdvancePercent,
        retainedAdvanceAmount: finalAmount * (retainedAdvancePercent/100),
        monthlyInterestRatePercent: monthlyInterestRatePercent,
        dailyInterestRatePercent:  Number((monthlyInterestRatePercent * 12/360).toFixed(3)),
        amountAfterAdvance: finalAmount - finalAmount * (retainedAdvancePercent/100),
        interestRateTotal: totalInterestRate,
        appFeePercent: appFeePercent,
        appFeeTotal: Number(finalAmount * appFeePercent/100).toFixed(3),
        finalPaymentAmount: Number((finalAmount - finalAmount*(retainedAdvancePercent/100)- totalInterestRate).toFixed(2)),
      },
      invoices: allInvoicesPaymentData
    }))
  };

  const formatNumber = (number: string | number) => {
    return Number(number).toLocaleString(`${t("date")}`, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const onSubmit = async () => {
    if (!data) return;

    const refreshReqData = () => {
      dispatch(onChangeRequestData(true));

      setTimeout(() => {
        dispatch(onChangeRequestData(false));
      }, 500);
    };

    await totalCalculation();
    await updatePaymentData(requestData);
    refreshReqData();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={requestData?.paymentData}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          setRetainedAdvancePercent(data?.paymentData.retainedAdvancePercent)
          setAppFeePercent(data?.paymentData.appFeePercent)
          setMonthlyInterestRatePercent(data?.paymentData.monthlyInterestRatePercent)
          setTimeout(() => {
            onClose();
          }, 250);
        };

        return (
          <Dialog open={open} onClose={closeAndReset}>
            <DialogHeader> {t("dialog.requests.totalRequest")} </DialogHeader>

            <DialogBody className={styles["total-request"]}>
              <Field
                name="amount"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.amount")}`}
                      value={formatNumber(data?.paymentData.amount) || ""}
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="retainedAdvancePercent"
                type="number"
                render={({ input }) => {
                  return (
                    <InputField
                      {...input}
                      className={styles["total-request__input-field"]}
                      type="number"
                      step='any'
                      label={`${t(
                        "dialog.requests.retainedAdvancePercent"
                      )} (%)`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRetainedAdvancePercent(Number(e.target.value))
                      }
                      value={retainedAdvancePercent}
                      disabled={
                        userInfo.role === Roles.Client ||
                        requestData?.status !== RequestStatus.Created
                      }
                    />
                  );
                }}
              />

              <Field
                name="retainedAdvanceAmount"
                type="number"
                placeholder="retainedAdvanceAmount"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.retainedAdvanceAmount")}`}
                      value={
                        formatNumber(
                          requestData?.paymentData.retainedAdvanceAmount
                        ) || ""
                      }
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="amountAfterAdvance"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.amountAfterAdvance")}`}
                      value={
                        formatNumber(
                          requestData?.paymentData.amountAfterAdvance
                        ) || ""
                      }
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="dailyInterestRatePercent"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t(
                        "dialog.requests.dailyInterestRatePercent"
                      )} (%)`}
                      value={`${requestData?.paymentData.dailyInterestRatePercent}`}
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="monthlyInterestRatePercent"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      type="number"
                      step='any'
                      label={`${t(
                        "dialog.requests.monthlyInterestRatePercent"
                      )} (%)`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setMonthlyInterestRatePercent(Number(e.target.value))
                      }
                      value={monthlyInterestRatePercent}
                      disabled={
                        userInfo.role === Roles.Client ||
                        requestData?.status !== RequestStatus.Created
                      }
                    />
                  );
                }}
              />

              <Field
                name="totalDays"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.totalDays")}`}
                      value={
                        requestData?.paymentData.minDays ===
                        requestData?.paymentData.maxDays
                          ? requestData?.paymentData.minDays
                          : `${requestData?.paymentData.minDays} - ${requestData?.paymentData.maxDays}`
                      }
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="interestRateTotal"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.interestRateTotal")}`}
                      value={
                        formatNumber(
                          requestData?.paymentData.interestRateTotal
                        ) || ""
                      }
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="appFeePercent"
                type="number"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      type="number"
                      step='any'
                      label={`${t("dialog.requests.appFeePercent")} (%)`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAppFeePercent(Number(e.target.value))
                      }
                      value={appFeePercent}
                      disabled={
                        userInfo.role === Roles.Client ||
                        requestData?.status !== RequestStatus.Created
                      }
                    />
                  );
                }}
              />

              <Field
                name="appFeeTotal"
                type="number"
                placeholder="appFeeTotal"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.appFeeTotal")}`}
                      value={
                        formatNumber(requestData?.paymentData.appFeeTotal) || ""
                      }
                      disabled={true}
                    />
                  );
                }}
              />

              <Field
                name="finalPaymentAmount"
                type="text"
                render={() => {
                  return (
                    <InputField
                      className={styles["total-request__input-field"]}
                      label={`${t("dialog.requests.finalPaymentAmount")}`}
                      value={
                        formatNumber(
                          requestData?.paymentData.finalPaymentAmount
                        ) || ""
                      }
                      disabled={true}
                    />
                  );
                }}
              />
            </DialogBody>

            {data?.status === RequestStatus.Created &&
              userInfo.role === Roles.Admin && (
                <DialogActions
                  className={styles["total-request___buttons-wrapper"]}
                >
                  <FlatButton type="button" onClick={closeAndReset}>
                    {t("dialog.users.quit")}
                  </FlatButton>

                  <FlatButton type="button" onClick={totalCalculation}>
                    {t("dialog.requests.cta.calculate")}
                  </FlatButton>

                  <FlatButton
                    onClick={async () => {
                      await form.submit();
                      const isSubmitted = form.getState().submitSucceeded;

                      if (!isSubmitted) return;

                      closeAndReset();
                    }}
                    type="button"
                    color="blue"
                    disabled={
                      requestData?.paymentData.retainedAdvancePercent ===
                        data?.paymentData.retainedAdvancePercent &&
                      requestData?.paymentData.appFeePercent ===
                        data?.paymentData.appFeePercent &&
                      requestData?.paymentData.dailyInterestRatePercent ===
                        data?.paymentData.dailyInterestRatePercent
                    }
                  >
                    {t("dialog.users.saveChanges")}
                  </FlatButton>
                </DialogActions>
              )}
          </Dialog>
        );
      }}
    />
  );
};
