import { classnames, isInViewport } from "@faktoring/util";
import { FC, Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./drop-down.module.scss";

interface IDropDownElement {
  isOpen: boolean;
  actions: {
    icon: ReactElement;
    name: string;
    handleClick: (value: string) => void;
  }[];
}

interface IDropDown {
  className?: string;
  setDropDownData: (value: any) => void;
  dropDownData: IDropDownElement[] | null;
  index: number;
  id: string;
}

export const DropDown: FC<IDropDown> = ({
  className,
  dropDownData,
  index,
  setDropDownData,
  id
}) => {
  const { t } = useTranslation();
  const [isMenuInViewPort, setIsMenuInViewPort] = useState<boolean>(false);

  const dropDownRef: any = useRef();
  const dropButtonRef: any = useRef();

  const classesDropDown = classnames(className, styles["drop-down__menu"], {
    [styles["drop-down__menu--top"]]: isMenuInViewPort,
    [styles["drop-down__menu--bottom"]]: !isMenuInViewPort,
  });

  const dropDown = dropDownData && dropDownData[index];

  const handleDropDown = () => {
    setIsMenuInViewPort(false);
    setDropDownData((prev: IDropDownElement[]) => {
      return [
        ...prev.map((el, i) =>
          index === i && el.isOpen !== true
            ? { ...el, isOpen: true }
            : { ...el, isOpen: false }
        ),
      ];
    });
  };

  const handleClickOutside = async (e: Event) => {
    setIsMenuInViewPort(false);

    const dropDownButton: any = dropButtonRef.current;
    const dropDownMenu: any = dropDownRef.current;

    if (!dropDownMenu) return;

    if (!isInViewport(dropDownRef.current)) setIsMenuInViewPort(true);

    if (
      !dropDownButton.contains(dropDownMenu) &&
      !dropDownButton.contains(e.target)
    ) {
      setDropDownData((prev: IDropDownElement[]) => {
        return [
          ...prev.map((el) => {
            return { ...el, isOpen: false };
          }),
        ];
      });
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["drop-down"]}>
      <div className={styles["drop-down__wrapper"]}>
        <button
          type="button"
          className={styles["drop-down__button"]}
          ref={dropButtonRef}
          onClick={handleDropDown}
          id={id}
        >
          {t("table.actions.action")}
        </button>

        {dropDown && dropDown.isOpen && (
          <div className={classesDropDown} ref={dropDownRef}>
            {dropDown.actions.map(({ name, handleClick, icon }, i: number) => (
              <Fragment key={i}>
                <div
                  className={styles["drop-down__element"]}
                  onClick={() => handleClick(id)}
                >
                  {icon}
                  {name}
                </div>
                {i !== dropDown.actions.length - 1 && (
                  <div className={styles["divider"]} />
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
