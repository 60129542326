import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { createSlice } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { store } from "../store";

const requestSlice = createSlice({
  name: "request",
  initialState: {
    isRequestPreviewOpen: false,
    requestPreviewId: null,
    requestFilteredStatus: RequestStatus.Created,
    requestsSearchedValue: "",
    requestsSortField: "lastStatusChangeDate",
    requestsSortDirection: "desc",
  },
  reducers: {
    openRequestPreview: (state, {payload}) => {
      state.isRequestPreviewOpen = true;
      state.requestPreviewId = payload
    },
    closeRequestPreiew: (state) => {
      state.requestPreviewId = null;
      state.isRequestPreviewOpen = false;
    },
    changeRequestFilteredStatus: (state, { payload }) => {
      state.requestFilteredStatus = payload;
    },
    changeRequestsSearchedValue: (state, { payload }) => {
      state.requestsSearchedValue = payload;
    },
    changeRequestsSortField: (state, { payload }) => {
      state.requestsSortField = payload;
    },
    changeRequestsSortDirection: (state, { payload }) => {
      state.requestsSortDirection = payload;
    },
  },
});

export default requestSlice;

export const selectDialogState = (state: ReturnType<typeof store.getState>) => ({
  isRequestPreviewOpen: state.request.isRequestPreviewOpen,
  requestPreviewId: state.request.requestPreviewId,
  requestFilteredStatus: state.request.requestFilteredStatus,
  requestsSearchedValue: state.request.requestsSearchedValue,
  requestsSortField: state.request.requestsSortField,
  requestsSortDirection: state.request.requestsSortDirection
});

export const useRequestPreviewState = () => {
  const state = useSelector(selectDialogState, shallowEqual);
  const dispatch = useDispatch();
  return {
    open: state.isRequestPreviewOpen,
    id: state.requestPreviewId,
    onClose: () => dispatch(closeRequestPreiew()),
  };
};

export const {
  openRequestPreview,
  closeRequestPreiew,
  changeRequestFilteredStatus,
  changeRequestsSearchedValue,
  changeRequestsSortDirection,
  changeRequestsSortField
} = requestSlice.actions;