export class Report {
    public year: number = 0;
    public month: number = 0;
    public inflow: number = 0;
}

export class InvoiceForReports {
    public amount: number = 0;
    public buyerId: number = 0;
    public invoiceId: number = 0;
    public invoiceRequestId: number = 0;
    public userId: number = 0;
    public buyerCompanyName: string = '';
    public paymentDate: string = '';
}

export class ReportByRequests {
    public invoiceRequestId: number = 0;
    public buyerId: number = 0;
    public buyerCompanyName: string = "";
    public userId: number = 0;
    public userCompanyName: string = "";
    public dateCreated: string = "";
    public amount: number = 0;
    public retainedAmount: number = 0;
    public interestPercent: number = 0;
    public interestAmount: number = 0;
    public appFeePercent: number = 0;
    public appFeeAmount: number = 0;
    public totalIncome: number = 0;
    public penaltyInterest: number = 0;
    public latestMaturity: string = "";
    public individualMaturities: string = "";
    public settled: boolean = true;
}

const inititalInvoice = {
    amount: 0,
    buyerId: 0,
    invoiceId: 0,
    invoiceRequestId: 0,
    userId: 0,
    buyerCompanyName: '',
    paymentDate: '',
}

export const initialReportByDate = {
    date: '',
    invoices: [inititalInvoice]
}

export class ReportByDate {
    public date: string = '';
    public invoices: InvoiceForReports[] = [inititalInvoice]
}