export const currencies = [
  {
    value: "RSD",
    label: "RSD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
];

export const deadline = [
  {
    value: "30",
    label: "30 dana",
  },
  {
    value: "60",
    label: "60 dana",
  },
];
