import { FlatButton, InfoBox, SelectField } from "@faktoring/ui";
import { FormApi } from "final-form";
import { FC, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useUserAccountsQuery } from "../../state/accounts/accounts-api.service";
import { NewBankAccount } from "../new-bank-account/new-bank-account";
import styles from "./new-request-steps.module.scss";
import { validateFieldRequired } from "./step-utils";

type FormValues = Record<"accountNumber", string>;

interface IBankAccountProps {
  form: FormApi<FormValues, string>;
}

export const StepOneBankAccount: FC<IBankAccountProps> = ({ form }) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [isOpenAddAccount, setIsOpenAddAccount] = useState<boolean>(false);

  const { data, isSuccess } = useUserAccountsQuery(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isSuccess) return;
    const newOptions: { value: string; label: string }[] = [];

    data?.forEach(({ accountNumber }: { accountNumber: string }) => {
      newOptions.push({
        value: accountNumber,
        label: accountNumber,
      });

      setOptions(newOptions);
    });
  }, [data, isSuccess]);

  return (
    <div className={styles["new-request__step-container"]}>
      <InfoBox title={t("dialog.requests.info")}>
        {t("dialog.requests.forMakingRequestYouNeedBankAccount")}
      </InfoBox>

      <div className={styles["new-request__row--two-fields"]}>
        <Field
          name="accountNumber"
          type="select"
          initialValue={data && data.length > 0 && data[data.length - 1].accountNumber}
          validate={validateFieldRequired}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-request__field"]}>
                <SelectField
                  placeholder={t("dialog.requests.chooseBankAccount")}
                  options={options}
                  {...input}
                />
                <div className={styles["new-request__field--validation"]}>
                  {isError && meta.error}
                </div>
              </div>
            );
          }}
        />

        <FlatButton
          type="button"
          onClick={() => setIsOpenAddAccount(true)}
          color="orange"
        >
          <div className={styles["new-request__row-button"]}>
            + &nbsp; {t("dialog.requests.addNewBankAccount")}
          </div>
        </FlatButton>
      </div>
      <NewBankAccount
        form={form}
        open={isOpenAddAccount}
        onClose={() => setIsOpenAddAccount(false)}
      />
    </div>
  );
};
