import api from "../api";
import { IPageRequestData, PaginationResult } from "../pagination-types";
import { Buyer } from "./buyer.class";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    buyers: build.query<PaginationResult<Buyer>, IPageRequestData>({
      query: ({ 
        page, 
        pageSize, 
        search,
        sortField, 
        sortDirection 
      }) =>
        `buyers?pageSize=${pageSize}&pageNumber=${page}` +
        `&search=${search || ""}` +
        `&sortField=${sortField || ""}` +
        `&sortDirection=${sortDirection || ""}`,
    }),
    allBuyers: build.query<PaginationResult<Buyer>, IPageRequestData>({
      query: ({ 
        page, 
        pageSize, 
        search,
        sortField, 
        sortDirection 
      }) =>
        `buyers/all?pageSize=${pageSize}&pageNumber=${page}` +
        `&search=${search || ""}` +
        `&sortField=${sortField || ""}` +
        `&sortDirection=${sortDirection || ""}`,
    }),
    newBuyer: build.mutation({
      query: (payload: Buyer) => ({
        url: "buyers/add",
        method: "POST",
        body: payload,
      }),
    }),
    updateBuyersCompanyData: build.mutation({
      query: (payload: any) => ({
        url: "buyers/update/company-data",
        method: "PUT",
        body: payload
      }),
    }),
    deleteBuyer: build.mutation({
      query: (id: number) => ({
        url: `buyers/delete?id=${id}`,
        method: "DELETE",
      }),
    }),
    findBuyerByRegistrationNumber: build.mutation({
      query: (companyRegistrationNumber: number) => ({
        url: `buyers/find/registrationNumber/${companyRegistrationNumber}`,
        method: "GET",
      }),
    }),
    findBuyerByPib: build.mutation({
      query: (pib: number) => ({
        url: `buyers/find/pib/${pib}`,
        method: "GET",
      }),
    }),
    getBuyersDataFromAPR: build.mutation({
      query: (companyRegistrationNumber: number) => ({
        url: `b2b/buyer/${companyRegistrationNumber}`,
        method: "GET",
      }),
    }),
    findAllBuyersUsers: build.mutation({
      query: (userId: number) => ({
        url: `buyers/find/u/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useNewBuyerMutation,
  useAllBuyersQuery,
  useBuyersQuery,
  useUpdateBuyersCompanyDataMutation,
  useDeleteBuyerMutation,
  useFindBuyerByRegistrationNumberMutation,
  useFindBuyerByPibMutation,
  useGetBuyersDataFromAPRMutation,
  useFindAllBuyersUsersMutation,
} = extendedApi;
