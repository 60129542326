import { FC, useEffect } from "react";
import { auth } from "../../state/auth/auth-params";

export const LoginPage: FC<{}> = () => {
  useEffect(() => {
    const params = [
      `response_type=code`,
      `client_id=${auth.clientId}`,
      `redirect_uri=${auth.loginRedirectUri}`,
      `client_secret=${auth.clientSecret}`,
      `scope=${auth.scope}`,
    ].join("&");

    try {
      const url = auth.openidUrl + `/auth?${params}`;
      window.location.assign(url);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return <></>;
};
