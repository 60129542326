import {
  DatePick,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
  SelectField,
} from "@faktoring/ui";
import { classnames } from "@faktoring/util";
import { FormApi } from "final-form";
import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../state/auth/auth-params";
import {
  useAllBuyersQuery,
  useBuyersQuery,
  useFindBuyerByPibMutation,
  useFindBuyerByRegistrationNumberMutation,
  useGetBuyersDataFromAPRMutation,
  useNewBuyerMutation,
} from "../../state/buyers/buyer-api.service";
import {
  onAddNewBuyer,
  onPageChange,
  selectBuyersPageOptions,
} from "../../state/buyers/buyers.slice";
import { useUserQuery } from "../../state/user/users-api.service";
import styles from "./new-buyer.module.scss";
import { BuyerAccountData } from "../../state/buyers/buyer.class";

const emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRgx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

type FormValues = Record<"buyerPib", string>;

interface IEditBuyerProps {
  open: boolean;
  onClose: () => void;
  form?: FormApi<FormValues, Partial<string>>;
}

interface INewBuyer {
  id: number;
  companyName: string;
  companyPib: string;
  companyPhoneNumber: string;
  companyEmail: string;
  foreignCompany: boolean;
  cooperationStartDate: string;
  representativeFirstName: string;
  lastName: string;
  totalDebt: number;
  debtLimit: number;
  remainingLimit: number;
}

interface IDropdownOption {
  value: string;
  label: string;
}

const fetchBuyers = {
  [Roles.Admin]: useAllBuyersQuery,
  [Roles.Client]: useBuyersQuery,
};

export const EditBuyer: FC<IEditBuyerProps> = ({ open, onClose, form }) => {
  const dispatch = useDispatch();
  const { pageSize, page } = useSelector(selectBuyersPageOptions);
  const [addNewBuyer] = useNewBuyerMutation();
  const [findBuyerByRegistrationNumber] =
    useFindBuyerByRegistrationNumberMutation();
  const [findBuyerByPib] = useFindBuyerByPibMutation();
  const [getBuyersDataFromAPR] = useGetBuyersDataFromAPRMutation();
  const { data: userInfo } = useUserQuery(null);
  const { data } = fetchBuyers[userInfo.role as Roles]({
    page,
    pageSize,
  });
  const { t } = useTranslation();
  const [buyerData, setBuyerData] = useState<any>({});
  const [isBuyerNotFound, setIsBuyerNotFound] = useState<boolean>(false)
  const [options, setOptions] = useState<IDropdownOption[]>([
    { value: "", label: "" },
  ]);
  const [activeButton, setActiveButton] = useState(
    `${t("dialog.buyers.searchByPib")}`
  );

  const findBuyer = {
    [t("dialog.buyers.searchByPib")]: findBuyerByPib,
    [t("dialog.buyers.searchByIDNumber")]: findBuyerByRegistrationNumber,
  };

  useEffect(() => {
    const newOptions: IDropdownOption[] = [];

    // Make dropdown list with all user's bank accounts
    buyerData?.bankAccounts
      ?.filter(
        (account: any) =>
          (account.type === BuyerAccountData.dinar ||
            account.type === BuyerAccountData.secondDinar) &&
          account.status === BuyerAccountData.includedInPaymentTransactions
      )
      .forEach((account: any) =>
        newOptions.push({
          value: account.accountNumber,
          label: account.accountNumber,
        })
      );

    setOptions(newOptions);
  }, [buyerData]);

  const validateFieldRequired = (value: any) => {
    if (!value) {
      return t("warnings.requiredField");
    }
  };

  const currentSelectedBuyer = form?.getFieldState("buyerPib");

  const displayNewBuyer = () => {
    dispatch(onAddNewBuyer(true));

    setTimeout(() => {
      dispatch(onAddNewBuyer(false));
    }, 2000);
  };

  const onSubmit = async (values: any) => {
    if (!data) return;
    const { totalPages, totalElements } = data;

    const newBuyerData = { ...values };
    newBuyerData.creditScore = buyerData.creditScore;

    await addNewBuyer(newBuyerData);

    const updateData = async () => {
      dispatch(onAddNewBuyer(true));
      displayNewBuyer();
      currentSelectedBuyer?.change(values.companyPib);
    };

    const isNextPageOnReload =
      Number.isInteger(totalElements / pageSize) && totalPages !== 0;

    if (isNextPageOnReload) {
      dispatch(onPageChange(totalPages));
      updateData();
      return;
    }

    if (totalPages === 0) {
      dispatch(onPageChange(totalPages));
      updateData();
      return;
    }

    dispatch(onPageChange(totalPages - 1));
    updateData();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={buyerData}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          setBuyerData({});
          setActiveButton(`${t("dialog.buyers.searchByPib")}`);
          setIsBuyerNotFound(false);
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["new-buyer"]}
          >
            <DialogHeader> {t("dialog.users.buyersData")} </DialogHeader>

            <DialogBody className={styles["new-buyer__body"]}>
              <div className={styles["new-buyer__body-search-field-wrapper"]}>
                <div
                  className={
                    styles["new-buyer__body-search-field-wrapper--buttons"]
                  }
                >
                  <button
                    className={classnames(
                      styles["new-buyer__body-search-field-wrapper--button"],
                      {
                        [styles["active"]]:
                          activeButton === t("dialog.buyers.searchByPib"),
                      }
                    )}
                    onClick={() => {
                      setActiveButton(`${t("dialog.buyers.searchByPib")}`);
                      setBuyerData({});
                      setIsBuyerNotFound(false);
                      form.restart();
                    }}
                  >
                    {t("dialog.buyers.searchByPib")}
                  </button>
                  <button
                    className={classnames(
                      styles["new-buyer__body-search-field-wrapper--button"],
                      {
                        [styles["active"]]:
                          activeButton === t("dialog.buyers.searchByIDNumber"),
                      }
                    )}
                    onClick={() => {
                      setActiveButton(`${t("dialog.buyers.searchByIDNumber")}`);
                      setBuyerData({});
                      setIsBuyerNotFound(false);
                      form.restart();
                    }}
                  >
                    {t("dialog.buyers.searchByIDNumber")}
                  </button>
                </div>
                <div
                  className={
                    styles["new-buyer__body-search-field-wrapper--input"]
                  }
                >
                  <Field
                    name="searchBy"
                    type="number"
                    validate={(value: number) => {
                      if (activeButton === t("dialog.buyers.searchByPib")) {
                        if (value && value.toString().length !== 9)
                          return t("warnings.pibMustHave9Num");
                      }

                      if (
                        activeButton === t("dialog.buyers.searchByIDNumber")
                      ) {
                        if (value && value.toString().length !== 8)
                          return t("warnings.IDNumMustHave8Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field-search"]}>
                          <InputField
                            {...input}
                            type="number"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                            placeholder={
                              (activeButton ===
                              t("dialog.buyers.searchByIDNumber")
                                ? t("dialog.buyers.enterIDNum")
                                : t("dialog.buyers.enterPib")) || ""
                            }
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <FlatButton
                    color="blue"
                    disabled={
                      (activeButton === t("dialog.buyers.searchByPib") &&
                        form.getState().values?.searchBy?.length !== 9) ||
                      (activeButton === t("dialog.buyers.searchByIDNumber") &&
                        form.getState().values?.searchBy?.length !== 8)
                    }
                    // Fetch buyers data and fill the fields with it
                    onClick={async () => {
                      const registrationNumber =
                        form.getState().values.searchBy;
                      let isError = false;

                      setBuyerData({});
                      setIsBuyerNotFound(false);

                      await findBuyer[activeButton](registrationNumber).then(
                        async (res: any) => {
                          res.error ? (isError = true) : setBuyerData(res.data);
                        }
                      );

                      isError &&
                        await getBuyersDataFromAPR(registrationNumber)
                          .then((res: any) => {
                            if (res.error?.originalStatus) {
                              setIsBuyerNotFound(true)
                            } else {
                              setBuyerData(res.data)
                            }
                          });
                    }}
                  >
                    {t("dialog.requests.cta.search")}
                  </FlatButton>
                </div>
                  {isBuyerNotFound && <span className={styles["new-buyer__error-message"]}>{t("dialog.buyers.buyerWithPibOrRegNumIsNotFound")}</span>}
              </div>

              <div className={styles["new-buyer__body-fields-wrapper"]}>
                <div className={styles["new-buyer__body-fields"]}>
                  <Field
                    name="companyPib"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 9) {
                        return t("warnings.pibMustHave9Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("table.buyers.pib")}`}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="registrationNumber"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 8) {
                        return t("warnings.IDNumMustHave8Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                            label={`${t("dialog.buyers.registrationNumber")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyName"
                    type="text"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.companyName")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="shortBusinessName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.shortCompanyName")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["new-buyer__body-fields"]}>
                  <Field
                    name="zipCode"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.zipCode")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="city"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.city")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="street"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.street")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="doorNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.doorNumber")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["new-buyer__body-fields"]}>
                  <Field
                    name="companyPhoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.requests.companyPhoneNumber")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyEmail"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.email")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["new-buyer__body-fields"]}>
                  <Field
                    name="accountNumber"
                    type="text"
                    initialValue={""}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          {buyerData?.bankAccounts?.length ? (
                            <SelectField
                              label={`${t("dialog.buyers.bankAccount")}`}
                              placeholder={t(
                                "dialog.requests.chooseBankAccount"
                              )}
                              options={options}
                              {...input}
                            />
                          ) : (
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.bankAccount")}`}
                            />
                          )}
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="bank"
                    type="text"
                    initialValue={
                      buyerData?.bankAccounts?.find(
                        (acc: any) =>
                          acc.accountNumber ===
                          form.getState().values.accountNumber
                      )?.bank || ""
                    }
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bank")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  {userInfo.role === Roles.Client && (
                    <Field
                      name="cooperationStartDate"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["new-buyer__body-field"]}>
                            <DatePick
                              {...input}
                              label={
                                t("table.buyers.cooperationStartDate") || ""
                              }
                              className={styles["new-buyer__date-picker-field"]}
                            />
                            <div
                              className={
                                styles["new-buyer__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>

                <p className={styles["new-buyer__body-subtitle"]}>
                  {t("dialog.buyers.representative")}
                </p>
                <div className={styles["new-buyer__body-fields"]}>
                  <Field
                    name="representative.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.lastName")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.jmbg"
                    type="text"
                    render={({ input, meta }) => {
                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.jmbg")}`}
                          />
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.type"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["new-buyer__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.representativeType")}`}
                          />
                          <div
                            className={
                              styles["new-buyer__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles["new-buyer__body-fields-contact"]}>
                <p>{t("dialog.buyers.addContactPersonsInfo")}</p>
                <div className={styles["new-buyer__body-fields-wrapper"]}>
                  <div className={styles["new-buyer__body-fields"]}>
                    <Field
                      name="contact.firstName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["new-buyer__body-field"]}>
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.name")}`}
                            />
                            <div
                              className={
                                styles["new-buyer__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.lastName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["new-buyer__body-field"]}>
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.lastName")}`}
                            />
                            <div
                              className={
                                styles["new-buyer__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.email"
                      type="email"
                      validate={(value) => {
                        if (value && !value.match(emailRgx)) {
                          return t("warnings.wrongEmail");
                        }
                      }}
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["new-buyer__body-field"]}>
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.email")}`}
                            />
                            <div
                              className={
                                styles["new-buyer__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.phoneNumber"
                      type="tel"
                      validate={(value) => {
                        if (value && !value.match(phoneRgx)) {
                          return t("warnings.wrongPhoneNumber");
                        }
                      }}
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["new-buyer__body-field"]}>
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.contactPhoneNum")}`}
                            />
                            <div
                              className={
                                styles["new-buyer__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </DialogBody>

            <DialogActions className={styles["new-buyer__dialog-actions"]}>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.requests.cta.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();

                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  closeAndReset();
                }}
                type="button"
                color="blue"
              >
                {t("dialog.requests.cta.saveBuyer")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
