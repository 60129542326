import { ReactNode, useState, useLayoutEffect, FC } from "react";
import { createPortal } from "react-dom";

const defaultDialogPortalProps = {
  wrapperId: "react-portal",
};

interface DialogPortalProps {
  children: ReactNode;
  wrapperId: string;
}

export const DialogPortal: FC<DialogPortalProps> = ({
  children,
  wrapperId,
}) => {
  const [wrapper, setWrapper] = useState<Element | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let created = false;

    if (!element) {
      created = true;
      const wrapper = document.createElement("div");
      wrapper.setAttribute("id", wrapperId);
      document.body.appendChild(wrapper);
      element = wrapper;
    }

    setWrapper(element);

    return () => {
      if (created && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapper === null) return null;

  return createPortal(children, wrapper);
};

DialogPortal.defaultProps = defaultDialogPortalProps;
