import { Step, Stepper } from "@faktoring/ui";
import { FC } from "react";
import styles from "./new-invoice-header.module.scss";

interface INewInvoiceProps {
  activeStep: number;
  title: string;
}

export const NewInvoicetHeader: FC<INewInvoiceProps> = ({
  activeStep,
  title,
}) => {
  return (
    <div className={styles["new-invoice__header"]}>
      <Stepper activeStep={activeStep}>
        <Step />
        <Step />
      </Stepper>
      <h3>{`${activeStep}. ${title}`}</h3>
    </div>
  );
};
