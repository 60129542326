import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InfoBox,
  InputField,
  SelectField,
} from "@faktoring/ui";
import { classnames } from "@faktoring/util";
import { FormApi } from "final-form";
import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BuyerAccountData } from "../../state/buyers/buyer.class";
import { useGetUsersDataFromAprMutation, useUpdateUsersCompanyDataMutation } from "../../state/user/users-api.service";
import { validatePib } from "../../state/user/users.slice";
import styles from "./company-data-dialog.module.scss";

const emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRgx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

type FormValues = Record<"userData", string>;

interface IUserCompanyDataProps {
  open: boolean;
  onClose: () => void;
  form?: FormApi<FormValues, Partial<string>>;
}

interface IUserData {
  companyName: string;
  businessName: string;
  shortBusinessName: string | null;
  companyPib: string;
  registrationNumber: string;
  companyPhoneNumber: string | null;
  companyEmail: string;
  zipCode: string | null;
  city: string;
  doorNumber: string;
  representative: {
    firstName: string;
    lastName: string;
    jmbg: string;
    type: string;
  };
  creditScore: string | null;
  bankAccounts: {
    accountNumber: string;
    bank: string;
    status: string;
    type: string;
  }[];
}

interface IDropdownOption {
  value: string;
  label: string;
}

export const CompanyDataDialog: FC<IUserCompanyDataProps> = ({
  open,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const [getUsersDataFromApr] = useGetUsersDataFromAprMutation();
  const [updateUsersCompanyData] = useUpdateUsersCompanyDataMutation();
  const { t } = useTranslation();
  const [userData, setUserData] = useState<any>({});
  const [options, setOptions] = useState<IDropdownOption[]>([{ value: "", label: "" }]);
  const [activeButton, setActiveButton] = useState(
    `${t("dialog.buyers.searchByPib")}`
  );

  useEffect(() => {
    const newOptions: IDropdownOption[] = [];

    // Making dropdown list with all user's bank accounts
    userData?.bankAccounts
      ?.filter(
        (account: any) =>
          (account.type === BuyerAccountData.dinar || account.type === BuyerAccountData.secondDinar) &&
          account.status === BuyerAccountData.includedInPaymentTransactions
      )
      .forEach((account: any) =>
        newOptions.push({
          value: account.accountNumber,
          label: account.accountNumber,
        })
      );

    setOptions(newOptions);
  }, [userData]);

  const validateFieldRequired = (value: string) => {
    if (!value) {
      return t("warnings.requiredField");
    }
  };

  const onSubmit = async (values: any) => {
    await updateUsersCompanyData(values)
    dispatch(validatePib())
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={userData}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          setUserData({});
          setOptions([])
          setActiveButton(`${t("dialog.buyers.searchByPib")}`);
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["company-data-dialog"]}
          >
            <DialogHeader> {t("dialog.users.yoursCompanyData")} </DialogHeader>

            <DialogBody className={styles["company-data-dialog__body"]}>
              <InfoBox
                title={t("dialog.requests.info")}
                className={styles["company-data-dialog__infobox"]}
              >
                {t("dialog.users.youNeedToAddCompanysData")}
              </InfoBox>
              <div
                className={
                  styles["company-data-dialog__body-search-field-wrapper"]
                }
              >
                <div
                  className={
                    styles[
                      "company-data-dialog__body-search-field-wrapper--buttons"
                    ]
                  }
                >
                  <button
                    className={classnames(
                      styles[
                        "company-data-dialog__body-search-field-wrapper--button"
                      ],
                      {
                        [styles["active"]]:
                          activeButton === t("dialog.buyers.searchByPib"),
                      }
                    )}
                    onClick={() => {
                      setActiveButton(`${t("dialog.buyers.searchByPib")}`);
                      setUserData({});
                      form.restart();
                    }}
                  >
                    {t("dialog.buyers.searchByPib")}
                  </button>
                  <button
                    className={classnames(
                      styles[
                        "company-data-dialog__body-search-field-wrapper--button"
                      ],
                      {
                        [styles["active"]]:
                          activeButton === t("dialog.buyers.searchByIDNumber"),
                      }
                    )}
                    onClick={() => {
                      setActiveButton(`${t("dialog.buyers.searchByIDNumber")}`);
                      setUserData({});
                      form.restart();
                    }}
                  >
                    {t("dialog.buyers.searchByIDNumber")}
                  </button>
                </div>
                <div
                  className={
                    styles["company-data-dialog__body-search-field-wrapper--input"]
                  }
                >
                  <Field
                    name="searchBy"
                    type="number"
                    validate={(value: number) => {
                      if (activeButton === t("dialog.buyers.searchByPib")) {
                        if (value && value.toString().length !== 9)
                          return t("warnings.pibMustHave9Num");
                      }

                      if (
                        activeButton === t("dialog.buyers.searchByIDNumber")
                      ) {
                        if (value && value.toString().length !== 8)
                          return t("warnings.IDNumMustHave8Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div
                          className={
                            styles["company-data-dialog__body-field-search"]
                          }
                        >
                          <InputField
                            {...input}
                            type="number"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                            placeholder={
                              (activeButton ===
                              t("dialog.buyers.searchByIDNumber")
                                ? t("dialog.buyers.enterIDNum")
                                : t("dialog.buyers.enterPib")) || ""
                            }
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <FlatButton
                    color="blue"
                    disabled={
                      (activeButton === t("dialog.buyers.searchByPib") &&
                        form.getState().values?.searchBy?.length !== 9) ||
                      (activeButton === t("dialog.buyers.searchByIDNumber") &&
                        form.getState().values?.searchBy?.length !== 8)
                    }
                    // Fetch buyers data and fill the fields with it
                    onClick={async () => {
                      setUserData({});

                      getUsersDataFromApr(form.getState().values.searchBy)
                        .unwrap()
                        .then((res: IUserData) => setUserData(res));
                    }}
                  >
                    {t("dialog.requests.cta.search")}
                  </FlatButton>
                </div>
              </div>

              <div className={styles["company-data-dialog__body-fields-wrapper"]}>
                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="companyPib"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 9) {
                        return t("warnings.pibMustHave9Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("table.buyers.pib")}`}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="registrationNumber"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 8) {
                        return t("warnings.IDNumMustHave8Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                            label={`${t("dialog.buyers.registrationNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="companyName"
                    type="text"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.companyName")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="shortBusinessName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.shortCompanyName")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="zipCode"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.zipCode")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="city"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.city")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="street"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.street")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="doorNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.doorNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="companyPhoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.requests.companyPhoneNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyEmail"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.email")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <p className={styles["company-data-dialog__body-subtitle"]}>{t("dialog.buyers.representative")}</p>
                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="representative.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t(
                              "dialog.buyers.lastName"
                            )}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.jmbg"
                    type="text"
                    render={({ input, meta }) => {
                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.jmbg")}`}
                          />
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.type"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t(
                              "dialog.buyers.representativeType"
                            )}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                  name="accountNumber"
                    type="select"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          {userData?.bankAccounts?.length ? (
                            <SelectField
                              label={`${t("dialog.buyers.bankAccount")}`}
                              placeholder={t(
                                "dialog.requests.chooseBankAccount"
                              )}
                              options={options}
                              {...input}
                            />
                          ) : (
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.bankAccount")}`}
                            />
                          )}
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="bank"
                    type="text"
                    initialValue={
                      userData?.bankAccounts?.find(
                        (acc: any) =>
                          acc.accountNumber ===
                          form.getState().values.accountNumber
                      )?.bank || ""
                    }
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bank")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles["company-data-dialog__body-fields-contact"]}>
                <p>{t("dialog.buyers.addContactPersonsInfo")}</p>
                <div className={styles["company-data-dialog__body-fields"]}>
                  <Field
                    name="contact.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.lastName")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.email"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.email")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="contact.phoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-dialog__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.contactPhoneNum")}`}
                          />
                          <div
                            className={
                              styles["company-data-dialog__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </DialogBody>

            <DialogActions>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.requests.cta.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();

                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;
                  
                  closeAndReset();
                }}
                type="button"
                color="blue"
              >
                {t("dialog.users.save")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};