import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { store } from "../store";

const buyersSlice = createSlice({
  name: "buyers",
  initialState: {
    isEditDialogOpen: false,
    editDialogId: null,
    isAddedNewBuyer: false,
    pageSize: 12,
    page: 0,
    sortField: "",
    sortDirection: ""
  },
  reducers: {
    openBuyerEditDialog: (state, action?) => {
      let id = null;

      if (action && action.payload) id = action.payload.id;
      state.isEditDialogOpen = true;
      state.editDialogId = id;
    },

    closeBuyerEditDialog: (state) => {
      state.editDialogId = null;
      state.isEditDialogOpen = false;
    },

    onPageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    onAddNewBuyer: (state, action: PayloadAction<boolean>) => {
      state.isAddedNewBuyer = action.payload;
    },
    changeBuyersSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    changeBuyersSortDirection: (state, action: PayloadAction<string>) => {
      state.sortDirection = action.payload;
    },
  },
});

export default buyersSlice;

export const selectDialogState = (state: ReturnType<typeof store.getState>) => ({
  isEditDialogOpen: state.buyers.isEditDialogOpen,
  editDialogId: state.buyers.editDialogId,
  sortField: state.buyers.sortField,
  sortDirection: state.buyers.sortDirection
});

export const selectBuyersPageOptions = (state: ReturnType<typeof store.getState>) => ({
  page: state.buyers.page,
  pageSize: state.buyers.pageSize,
  isAddedNewBuyer: state.buyers.isAddedNewBuyer,
});

export const {
  openBuyerEditDialog,
  closeBuyerEditDialog,
  onPageChange,
  onAddNewBuyer,
  changeBuyersSortDirection,
  changeBuyersSortField
} = buyersSlice.actions;

export const useEditBuyerDialogState = () => {
  const state = useSelector(selectDialogState, shallowEqual);
  const dispatch = useDispatch();
  return {
    open: state.isEditDialogOpen,
    id: state.editDialogId,
    onClose: () => dispatch(closeBuyerEditDialog()),
  };
};
