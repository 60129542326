export class Contract {
  public id: number = 0;
  public invoiceRequestId: number = 0;
  public user = {
    id: 0,
    companyName: "",
    companyPib: "",
    companyRegistrationNumber: "",
    street: "",
    doorNumber: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    accountNumber: "",
    representativeFirstName: "",
    representativeLastName: "",
    representativeJmbg: "",
    representativeType: "",
  };
  public buyer = {
    id: 0,
    companyName: "",
    companyPib: "",
    companyRegistrationNumber: "",
    street: "",
    doorNumber: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    accountNumber: "",
    representativeFirstName: "",
    representativeLastName: "",
    representativeJmbg: "",
    representativeType: "",
  };
  public status: ContractStatus = ContractStatus.InPreparation;
  public startDate: Date = new Date();
  public dateUpdated: Date = new Date();
  public endDate: Date = new Date();
  public contractNumber: string = "";
  public contractDate: string = "";
  public retainedAdvancePercent: number = 0;
  public appFeePercent: number = 0;
  public monthlyInterestRate: number = 0;
  public promissoryNotes: string = "";
  public promissoryNoteCount: number = 0;
  public contractLimit: number = 0;
  public contractType: ContractType = ContractType.OneTime;
}

export enum ContractStatus {
  InPreparation = "PENDING",
  Valid = "ACTIVE",
  Expired = "EXPIRED"
}

export enum ContractType {
  OneTime = "ONE_TIME",
  LongTerm = "LONG_TERM"
}