import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
} from "@faktoring/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useBuyersQuery,
  useDeleteBuyerMutation,
} from "../../state/buyers/buyer-api.service";
import { selectBuyersPageOptions } from "../../state/buyers/buyers.slice";
import styles from "./delete-confirmation-dialog.module.scss";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  buyerId: number;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  buyerId,
}) => {
  const [deleteBuyer] = useDeleteBuyerMutation();
  const { pageSize, page } = useSelector(
    selectBuyersPageOptions
  );
  const { refetch: refetchBuyers } = useBuyersQuery(
    {
      page,
      pageSize,
      sortField: "",
      sortDirection: "",
    },
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();
  
  const deleteBuyerAndRefetch = async (id: number) => {
    await deleteBuyer(id);
    refetchBuyers();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader>
        {t("dialog.buyers.areYouSureYouWantDeleteBuyer")}
      </DialogHeader>

      <DialogBody>{t("dialog.buyers.buyerWillBeDeletedForever")}</DialogBody>

      <DialogActions className={styles["delete-confirmation__actions-group"]}>
        <FlatButton type="button" onClick={onClose}>
          {t("dialog.users.quit")}
        </FlatButton>

        <FlatButton
          onClick={async () => {
            await deleteBuyerAndRefetch(buyerId);
            onClose();
          }}
          type="button"
          color="blue"
        >
          {t("dialog.buyers.delete")}
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};
