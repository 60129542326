import { FlatButton } from "@faktoring/ui";
import { FormApi } from "final-form";
import { FC, useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../state/requests/invoice.class";
import { DownloadInvoices } from "../download-invoices/download-invoices";
import { InvoicesList } from "../invoices-list/invoices-list";
import { NewInvoice } from "../new-invoice/new-invoice";
import styles from "./new-request-steps.module.scss";
import { validateFieldRequired } from "./step-utils";

interface IStepThreeinvoicesDataProps {
  form?: FormApi<FormValues, Partial<string>>;
  update: (invoices: string, selectedIndex: number, data: Invoice) => void;
  setStep: (step: number) => void;
}

type FormValues = Record<"invoiceList", string>;

export const StepThreeInvoicesData: FC<IStepThreeinvoicesDataProps> = ({
  form,
  update,
  setStep,
}) => {
  const [isOpenNewInvoice, setIsOpenNewInvoice] = useState<boolean>(false);
  const [isOpenDownloadInvoices, setIsOpenDownloadInvoices] =
    useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] =
    useState<number | undefined>(undefined);
  const { t } = useTranslation();

  return (
    <>
      <FieldArray validate={validateFieldRequired} name="invoices">
        {({ fields, meta }) => {
          const isError = meta.touched && meta.error;

          return (
            <div className={styles["new-request__step-container"]}>
              <div className={styles["new-request__invoices-list-wrapper"]}>
                <InvoicesList
                  actions
                  invoices={fields.value}
                  onRemoveElement={fields.remove}
                  form={form}
                  setIsOpenNewInvoice={setIsOpenNewInvoice}
                  setSelectedIndex={setSelectedIndex}
                />
              </div>
              <div className={styles["new-request__field"]}>
                <FlatButton
                  className={styles["new-request__add-invoice-button"]}
                  type="button"
                  color="blue"
                  onClick={() => setIsOpenNewInvoice(true)}
                >
                  {t("dialog.requests.addInvoice")}
                </FlatButton>
                <p className={styles["new-request__field-divider"]}>
                  {t("dialog.requests.or").toLocaleLowerCase()}
                </p>
                <FlatButton
                  className={styles["new-request__add-invoice-button"]}
                  type="button"
                  color="blue"
                  onClick={() => setIsOpenDownloadInvoices(true)}
                >
                  {t("dialog.requests.addInvoicesFromSef")}
                </FlatButton>
                <div className={styles["new-request__field--validation"]}>
                  <div>{isError && t("warnings.minOneDocIsRequired")}</div>
                </div>
              </div>
            </div>
          );
        }}
      </FieldArray>

      {form && (
        <>
          <DownloadInvoices
            open={isOpenDownloadInvoices}
            onClose={() => setIsOpenDownloadInvoices(false)}
            newRequestForm={form}
            setSelectedIndex={setSelectedIndex}
            setStep={setStep}
            form={form}
          />

          <NewInvoice
            open={isOpenNewInvoice}
            onClose={() => setIsOpenNewInvoice(false)}
            newRequestForm={form}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            update={update}
          />
        </>
      )}
    </>
  );
};
