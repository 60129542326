interface IAuth {
  clientId: string;
  clientSecret: string;
  scope: string;
  appUrl: string;
  openidUrl: string;
  loginRedirectUri: string;
}

/*export const auth: IAuth = {
  scope: "openid",
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET as string,
  appUrl: process.env.REACT_APP_BACKEND_BASE as string,
  openidUrl: process.env.REACT_APP_OPENID_URL as string,
  loginRedirectUri: `${window.location.origin}/login_callback`
};*/

export const auth: IAuth = {
  scope: "openid",
  clientId: window._env_.REACT_APP_CLIENT_ID as string,
  clientSecret: window._env_.REACT_APP_CLIENT_SECRET as string,
  appUrl: window._env_.REACT_APP_BACKEND_BASE as string,
  openidUrl: window._env_.REACT_APP_OPENID_URL as string,
  loginRedirectUri: `${window.location.origin}/login_callback`
};

export enum Roles {
  Admin = "FAKTORING_ADMIN",
  Client = "FAKTORING_USER",
}
