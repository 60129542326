import api from "../api";

interface INewAccount {
  accountNumber: string;
  bank: string;
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    userAccounts: build.query({
      query: () => `users/accountNumbers`,
    }),

    newAccount: build.mutation({
      query: ({ 
        accountNumber,
        bank
      }: INewAccount) => ({
        url: `users/accountNumbers/add?&accountNumber=${accountNumber}&bank=${bank}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useUserAccountsQuery, useNewAccountMutation } = extendedApi;
