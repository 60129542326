import { Dialog, DialogActions, DialogHeader, FlatButton } from "@faktoring/ui";
import { ComponentType, FC, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useAllBuyersQuery } from "../../state/buyers/buyer-api.service";
import { useNewContractMutation } from "../../state/contracts/contracts-api.service";
import { Contract } from "../../state/contracts/contracts.class";
import { Request } from "../../state/requests/requests.class";
import { useAllUsersQuery } from "../../state/user/users-api.service";
import { NewRequestHeader } from "../new-request/stepper-header";
import styles from "./new-contract.module.scss";
import { StepOneUserBuyer } from "./step-one-user-buyer";
import { StepTwoContractData } from "./step-two-contract-data";

interface INewContractProps {
  requestData?: Request;
  open: boolean;
  onClose: () => void;
  setIsAddedNewContract: (value: boolean) => void;
}

export const NewContract: FC<INewContractProps> = ({
  requestData,
  open,
  onClose,
  setIsAddedNewContract,
}) => {
  const [step, setStep] = useState<number>(1);
  const { data: allUsers } = useAllUsersQuery({ page: 0, pageSize: 1000 });
  const { data: allBuyers } = useAllBuyersQuery({ page: 0, pageSize: 1000 });
  const [newContract] = useNewContractMutation();
  const { t } = useTranslation();

  const formConfig: {
    step: number;
    title: string;
    BodyComponent: ComponentType<any>;
  }[] = [
    {
      step: 1,
      title: t("dialog.contracts.chooseClientAndBuyer"),
      BodyComponent: StepOneUserBuyer,
    },
    {
      step: 2,
      title: t("dialog.contracts.contractsDetails"),
      BodyComponent: StepTwoContractData,
    },
  ];

  const { title, BodyComponent } = formConfig.filter(
    (el) => el.step === step
  )[0];

  const stepForward = () => step < formConfig.length && setStep(step + 1);

  const stepBack = () => setStep(step - 1);

  const onSubmit = async (newContractData: Contract) => {
    requestData && (newContractData.invoiceRequestId = requestData.id)
    await newContract(newContractData);
    setIsAddedNewContract(true);

    setTimeout(() => {
      setIsAddedNewContract(false);
    }, 0);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, valid }) => {
        const closeAndRestart = () => {
          form.restart();
          setStep(1);
          onClose();
        };

        const handleBack = () => (step === 1 ? closeAndRestart() : stepBack());

        const handleForward = async () => {
          if (!valid || step === formConfig.length) {
            await form.submit();

            const isSubmitted = form.getState().submitSucceeded;

            if (isSubmitted) {
              closeAndRestart();
            }

            return;
          }

          stepForward();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={open}
              onClose={closeAndRestart}
              className={styles["new-contract"]}
            >
              <DialogHeader>
                <NewRequestHeader steps={2} activeStep={step} title={title} />
              </DialogHeader>

              <BodyComponent
                form={form}
                setStep={setStep}
                allUsers={allUsers}
                allBuyers={allBuyers}
                requestData= {requestData}
                buyersOptions={{ value: "", label: "" }}
              />

              <DialogActions>
                <FlatButton onClick={handleBack} type="button">
                  {step === 1
                    ? t("dialog.requests.cta.quit")
                    : t("dialog.requests.cta.back")}
                </FlatButton>
                <FlatButton onClick={handleForward} type="button" color="blue">
                  {step === formConfig.length
                    ? t("dialog.contracts.create")
                    : t("dialog.requests.cta.continue")}
                </FlatButton>
              </DialogActions>
            </Dialog>
          </form>
        );
      }}
    />
  );
};
