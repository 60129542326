import { FC, ReactNode, SelectHTMLAttributes } from "react";
import styles from "./select-field.module.scss";

interface ISelectOption {
  value: string | number | undefined;
  label: ReactNode;
}

interface ISelectField extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  options: Array<ISelectOption>;
  placeholder: string;
  label?: string;
}

const SelectField: FC<ISelectField> = ({
  options,
  className,
  placeholder,
  label,
  ...props
}) => {
  return (
    <>
        {label && <label className={styles["select__label"]}>{label}</label>}
        <div className={styles["select-wrapper"]}>
          <select
            value={props.value}
            className={styles["native-select"]}
            {...props}
          >
            <option value="" disabled>
              {!options.length ? "Nema dodatih opcija" : placeholder}
            </option>

            {!!options.length &&
              options.map(({ value, label }, i) => {
                return (
                  <option className={styles["select-option"]} key={i} value={value}>
                    {label}
                  </option>
                );
              })}
          </select>
        </div>
    </>
  );
};

export { type ISelectField, SelectField };
