import { classnames } from "@faktoring/util";
import { ButtonHTMLAttributes } from "react";
import styles from "./flat-button.module.scss";
export interface IFlatButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: "blue" | "orange" | "red" | "green";
}

export const FlatButton: React.FC<IFlatButtonProps> = ({
  className,
  color,

  ...props
}) => {
  const classes = classnames(className, styles["flat-button"], {
    [styles["flat-button__blue"]]: color === "blue",
    [styles["flat-button__orange"]]: color === "orange",
    [styles["flat-button__red"]]: color === "red",
    [styles["flat-button__green"]]: color === "green",
  });

  return <button className={classes} {...props}></button>;
};
