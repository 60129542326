import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import api from "../api";
import { Invoice } from "./invoice.class";
import { PaymentData, Request } from "./requests.class";
import { Payment } from "../../components/invoice-payment-dialog/invoice-payment-dialog";

interface IAllReqPayload {
  page: number;
  pageSize: number;
  searchedValue?: string;
  sortField?: string;
  sortDirection?: string;
  status?: RequestStatus;
}

interface IUpdateInvoiceStatus {
  requestId: number;
  requestStatus?: RequestStatus;
  invoiceId: number;
  contractId?: number;
  invoiceStatus: string;
}

interface IUpdateReqStatus {
  requestId: number;
  requestStatus: RequestStatus;
  contractId?: number;
  invoices?: { invoiceId: number; invoiceStatus: string }[];
  adminComment?: string;
}

interface IUpdatePaymentData {
  id: number;
  paymentData: Request["paymentData"];
  invoices: { id: number; paymentData: PaymentData };
}
interface IDownloadFile {
  fileReference: string;
  name: string;
  type: string;
}

export interface INewRequest {
  accountNumber: string;
  buyerPib: string;
  invoices: Invoice[];
  followingDocs?: File[];
  files?: File[];
  paymentOption: string;
}

interface IAddFilesToReq {
  id: number | string;
  files: {
    fileName: string;
    uploadIdentifier: string;
  }[];
}

interface IDownloadInvoices {
  buyerPib: number;
  apiKey: string;
  dateFrom: string | Date;
  dateTo: string | Date;
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    requests: build.query({
      query: ({
        page,
        pageSize,
        searchedValue,
        sortField,
        sortDirection,
        status,
      }: IAllReqPayload) => ({
        url: `invoice-requests?pageSize=${pageSize}&pageNumber=${page}&search=${
          searchedValue || ""
        }&sortField=${sortField || ""}&sortDirection=${
          sortDirection || ""
        }&status=${status || ""}`,
        method: "GET",
      }),
    }),
    allRequests: build.query({
      query: ({
        page,
        pageSize,
        searchedValue,
        sortField,
        sortDirection,
        status,
      }: IAllReqPayload) => ({
        url: `invoice-requests/all?pageSize=${pageSize}&pageNumber=${page}&search=${
          searchedValue || ""
        }&sortField=${sortField || ""}&sortDirection=${
          sortDirection || ""
        }&status=${status || ""}`,
        method: "GET",
      }),
    }),
    newRequest: build.mutation({
      query: (payload: Request | INewRequest) => ({
        url: "invoice-requests/add",
        method: "POST",
        body: payload,
      }),
    }),
    updateInvoiceStatus: build.mutation({
      query: ({
        requestId,
        requestStatus,
        contractId,
        invoiceId,
        invoiceStatus,
      }: IUpdateInvoiceStatus) => ({
        url: "invoice-requests/status?calc=true",
        method: "PUT",
        body: {
          requestId: +requestId,
          requestStatus: requestStatus,
          contractId: contractId,
          invoices: [
            {
              invoiceId: invoiceId,
              invoiceStatus: invoiceStatus,
            },
          ],
        },
      }),
    }),
    updateRequestStatus: build.mutation({
      query: (payload: IUpdateReqStatus) => ({
        url: "invoice-requests/status",
        method: "PUT",
        body: payload,
      }),
    }),
    updatePaymentData: build.mutation({
      query: (payload: IUpdatePaymentData) => ({
        url: "invoice-requests/update",
        method: "PUT",
        body: payload,
      }),
    }),
    uploadFiles: build.mutation({
      query: (payload: FormData) => ({
        url: "documents/upload",
        method: "POST",
        body: payload,
      }),
    }),
    addFilesToRequest: build.mutation({
      query: ({ 
        id, 
        files 
      }: IAddFilesToReq) => ({
        url: `documents/add/invoice-request/${id}`,
        method: "POST",
        body: { files: files },
      }),
    }),
    downloadFile: build.mutation({
      query: ({ fileReference, name }: IDownloadFile)  => {
        return {
          url: `documents/download/${fileReference}`,
          method: "GET",
          responseHandler: async (response) => {
            const url = window.URL.createObjectURL(await response.blob());

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
          },
          cache: "no-cache",
        };
      },
    }),
    downloadInvoicesFromSef: build.mutation({
      query: (payload: IDownloadInvoices) => ({
        url: "sef/invoices",
        method: "POST",
        body: payload
      }),
    }),
    downloadDocumentation: build.mutation({
      query: ({requestId, name})  => {
        return {
          url: `invoice-requests/documentation/generate/${requestId}`,
          method: "GET",
          responseHandler: async (response) => {
            const url = window.URL.createObjectURL(await response.blob());

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
          },
          cache: "no-cache",
        };
      },
    }),
    setPaymentPerInvoice: build.mutation({
      query: (payload: Payment ) => ({
        url: "payments",
        method: "POST",
        body: payload,
      }),
    }),
    getPaymentPerInvoice: build.mutation({
      query: (invoiceId: number ) => ({
        url: `payments/invoice/${invoiceId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useNewRequestMutation,
  useRequestsQuery,
  useAllRequestsQuery,
  useUpdateInvoiceStatusMutation,
  useUpdateRequestStatusMutation,
  useUpdatePaymentDataMutation,
  useUploadFilesMutation,
  useAddFilesToRequestMutation,
  useDownloadFileMutation,
  useDownloadInvoicesFromSefMutation,
  useDownloadDocumentationMutation,
  useSetPaymentPerInvoiceMutation,
  useGetPaymentPerInvoiceMutation
} = extendedApi;
