import { FileList, FileUpload, InfoBox } from '@faktoring/ui';
import { FC } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import styles from './new-request-steps.module.scss';

interface IFollowingDocsProps {
  values: any;
  push: (arg1: string, arg2: File[]) => void;
}

export const StepFourFollowingDoc: FC<IFollowingDocsProps> = ({ push }) => {
  const handleFiles = (files: File[]) => push('followingDocs', files);
  const { t } = useTranslation();

  // TODO add validation if needed
  // const validateFieldRequired = (value: File[]) => {
  //   if (!value || value.length < 2) {
  //     return t("warnings.minTwoDocIsRequired");
  //   }
  // };

  return (
    <FieldArray name="followingDocs">
      {({ fields, meta }) => {
        const isError = meta.touched && meta.error;

        return (
          <div className={styles['new-request__step-container']}>
            <div className={styles['new-request__field']}>
              <InfoBox
                title=""
                className={styles['new-request__step-container-infobox']}>
                {t('dialog.additionalDoc.youNeedToFollowingDocs')}:
                <ul>
                  <li>{t('dialog.additionalDoc.idCardWithMoreThan25pct')}</li>
                  <li>{t('dialog.additionalDoc.representativeIdCard')}</li>
                  <li>{t('dialog.additionalDoc.grossBalance')}</li>
                  <li>{t('dialog.additionalDoc.customerCard')}</li>
                  <li>{t('dialog.additionalDoc.finalAccountForLastYear')}</li>
                </ul>
              </InfoBox>
              <p className={styles['new-invoice__field--information']}>
                <b> {t('dialog.requests.fileSizeLimitInfo')} </b>
              </p>
              <FileUpload
                onChange={handleFiles}
                value={fields.value}
                label={t('dialog.requests.cta.clickHere')}
                multiple
                accept="image/*, application/pdf">
                <div className={styles['new-request__file-upload-content']}>
                  {t('dialog.requests.docFormats')} <br />
                </div>
              </FileUpload>
              <br />
              <FileList onRemoveElement={fields.remove} files={fields.value} />
              <div className={styles['new-request__field--validation']}>
                <div>{isError && t('warnings.minTwoDocIsRequired')}</div>
              </div>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
