import { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
} from "@faktoring/ui";
import arrayMutators from "final-form-arrays";
import { ComponentType, FC, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../state/auth/auth-params";
import {
  useAllBuyersQuery,
  useBuyersQuery,
} from "../../state/buyers/buyer-api.service";
import { Buyer } from "../../state/buyers/buyer.class";
import {
  useNewRequestMutation,
  useAllRequestsQuery,
  useRequestsQuery,
  useUploadFilesMutation,
  INewRequest,
} from "../../state/requests/requests-api.service";
import {
  onAddNewRequestOnBeginning,
  selectRequestsPageOptions,
  onPageChange,
} from "../../state/requests/requests.slice";
import { useUserQuery } from "../../state/user/users-api.service";
import { NewRequestHeader } from "./stepper-header";
import { StepFourFollowingDoc } from "./step-four-following-doc";
import { StepOneBankAccount } from "./step-one-bank-account";
import { StepThreeInvoicesData } from "./step-three-invoices-data";
import { StepTwoCustomerData } from "./step-two-customer-data";
import { selectBuyersPageOptions } from "../../state/buyers/buyers.slice";
import { useTranslation } from "react-i18next";
import {
  changeRequestFilteredStatus,
  selectDialogState,
} from "../../state/requests/request.slice";
import { Invoice } from "../../state/requests/invoice.class";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import styles from './new-request-steps.module.scss';

interface INewRequestProps {
  open: boolean;
  onClose: () => void;
}

const buyersRequestMap = {
  [Roles.Admin]: useAllBuyersQuery,
  [Roles.Client]: useBuyersQuery,
};

const requestMap = {
  [Roles.Admin]: useAllRequestsQuery,
  [Roles.Client]: useRequestsQuery,
};

export const NewRequest: FC<INewRequestProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { pageSize, page } = useSelector(selectRequestsPageOptions);
  const [step, setStep] = useState<number>(1);
  const [addNewRequest] = useNewRequestMutation();
  const { data: userData } = useUserQuery(null);
  const {
    isSuccess,
    data: allBuyers,
    refetch: refetchBuyers,
  } = buyersRequestMap[userData.role as Roles]({ page: 0, pageSize: 30 }); // TODO add max num
  const [uploadFiles] = useUploadFilesMutation();
  const {
    requestFilteredStatus,
    requestsSortField: sortField,
    requestsSortDirection: sortDirection,
    requestsSearchedValue,
  } = useSelector(selectDialogState);
  const { data, refetch } = requestMap[userData.role as Roles](
    {
      page,
      pageSize,
      searchedValue: requestsSearchedValue,
      sortField,
      sortDirection,
      status: requestFilteredStatus,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { isAddedNewBuyer } = useSelector(selectBuyersPageOptions);
  const { t } = useTranslation();

  const formConfig: {
    step: number;
    title: string;
    BodyComponent: ComponentType<any>;
  }[] = [
    {
      step: 1,
      title: t("dialog.requests.bankAccountDetails"),
      BodyComponent: StepOneBankAccount,
    },
    {
      step: 2,
      title: t("dialog.users.buyersData"),
      BodyComponent: StepTwoCustomerData,
    },
    {
      step: 3,
      title: t("dialog.requests.invoices"),
      BodyComponent: StepThreeInvoicesData,
    },
    {
      step: 4,
      title: t("dialog.requests.additionalDocumentationForRequest"),
      BodyComponent: StepFourFollowingDoc,
    },
  ];

  useEffect(() => {
    refetchBuyers();
  }, [open, isAddedNewBuyer]);

  const { title, BodyComponent } = formConfig.filter(
    (el) => el.step === step
  )[0];

  const stepForward = () => step < formConfig.length && setStep(step + 1);

  const stepBack = () => setStep(step - 1);

  const displayNewRequest = () => {
    dispatch(onAddNewRequestOnBeginning(true));

    setTimeout(() => {
      dispatch(onAddNewRequestOnBeginning(false));
    }, 2000);
  };

  const selectBuyersOptions =
    isSuccess &&
    allBuyers.content?.map(({ companyName, companyPib }: Buyer) => {
      return {
        label: companyName,
        value: companyPib,
      };
    });

  const onSubmit = async (newRequestData: INewRequest) => {
    if (!data) return;

    // Update and change files data
    const updateFiles = async () => {
      const reqFileList = newRequestData.followingDocs;
      const formData = new FormData();

      // Update request files
      if (reqFileList) {
        Object.keys(reqFileList).forEach((index: string) => {
          formData.append("files", reqFileList[Number(index)]);
        });

        await uploadFiles(formData)
          .unwrap()
          .then((data) => {
            newRequestData.files = data.files;
            delete newRequestData.followingDocs;
          });
      }
      // Update invoices files
      await Promise.all(
        newRequestData.invoices.map(async (invoice: Invoice, index: number) => {
          if (!invoice.pdfBase64) {
            const formData = new FormData();

            Object.keys(invoice.files).forEach((index: string) => {
              formData.append("files", invoice.files[Number(index)]);
            });

            await uploadFiles(formData)
              .unwrap()
              .then(
                (data) => (newRequestData.invoices[index].files = data.files)
              );
          }
        })
      );
    };

    const updateData = async () => {
      await refetch();
      onClose();
    };

    await updateFiles();
    await addNewRequest(newRequestData);
    await updateData();

    dispatch(changeRequestFilteredStatus(RequestStatus.Created));
    dispatch(onPageChange(0));
    displayNewRequest();
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form,
        valid,
        form: {
          mutators: { push, update },
        },
      }) => {
        const closeAndRestart = () => {
          form.restart();
          setStep(1);
          onClose();
        };

        const handleBack = () => (step === 1 ? closeAndRestart() : stepBack());

        const handleForward = async () => {
          if (!valid || step === formConfig.length) {
            await form.submit();

            const isSubmitted = form.getState().submitSucceeded;

            if (isSubmitted) {
              closeAndRestart();
            }

            return;
          }

          stepForward();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Dialog open={open} onClose={closeAndRestart} className={styles["new-request__dialog"]}>
              <DialogHeader>
                <NewRequestHeader steps={4} activeStep={step} title={title} />
              </DialogHeader>

              <DialogBody className={styles["new-request__dialog-body"]}>
                <BodyComponent
                  form={form}
                  push={push}
                  update={update}
                  setStep={setStep}
                  buyersOptions={selectBuyersOptions}
                />
              </DialogBody>


              <DialogActions className={styles["new-request__dialog-actions-group"]}>
                <FlatButton onClick={handleBack} type="button">
                  {step === 1
                    ? t("dialog.requests.cta.quit")
                    : t("dialog.requests.cta.back")}
                </FlatButton>
                <FlatButton onClick={handleForward} type="button" color="blue">
                  {step === formConfig.length
                    ? t("dialog.requests.cta.send")
                    : t("dialog.requests.cta.continue")}
                </FlatButton>
              </DialogActions>
            </Dialog>
          </form>
        );
      }}
    />
  );
};
