interface IClassNamesDefinition {
	[className: string]: boolean,
}

export default function classnames(...classNameDefs: Array<string | IClassNamesDefinition | null | undefined>) {
	const classNamesArray: string[] = [];
	classNameDefs.forEach((classNameDef) => {
		if (typeof (classNameDef) === 'string') {
			classNamesArray.push(classNameDef);
		} else if (typeof (classNameDef) === 'object' && classNameDef) {
			Object.keys(classNameDef).forEach((className) => {
				if (classNameDef[className]) {
					classNamesArray.push(className);
				}
			});
		}
	});

	return classNamesArray.join(' ');
}