import { DropDown, FloatButton, Spinner, Table } from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BuyerPreview } from "../../components/buyer-edit/buyer-edit";
import { HeaderPortal } from "../../components/header/header-portal";
import { Roles } from "../../state/auth/auth-params";
import {
  useAllBuyersQuery,
  useBuyersQuery,
  useDeleteBuyerMutation,
} from "../../state/buyers/buyer-api.service";
import { Buyer } from "../../state/buyers/buyer.class";
import {
  changeBuyersSortDirection,
  changeBuyersSortField,
  onPageChange,
  openBuyerEditDialog,
  selectBuyersPageOptions,
  selectDialogState,
} from "../../state/buyers/buyers.slice";
import { useUserQuery } from "../../state/user/users-api.service";
import styles from "./buyers.module.scss";
import { localizedCurrency, localizedTime } from "../users/users";
import { DeleteConfirmationDialog } from "../../components/delete-confirmation-dialog/delete-confirmation-dialog";

interface IDropDownElement {
  isOpen: boolean;
  actions: {
    icon: ReactElement;
    name: string;
    handleClick: (value: string) => void;
  }[];
}

const buyersRequestMap = {
  [Roles.Client]: useBuyersQuery,
  [Roles.Admin]: useAllBuyersQuery,
};

export const Buyers: FC<{}> = () => {
  const dispatch = useDispatch();
  const { pageSize, page, isAddedNewBuyer } = useSelector(
    selectBuyersPageOptions
  );
  const { data: userData } = useUserQuery(null);
  const [dropDownData, setDropDownData] =
    useState<IDropDownElement[] | null>(null);
  const [isOpenBuyerPreview, setIsOpenBuyerPreview] = useState<boolean>(false);
  const [isOpenDeleteConfirmationDialog, setIsOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [selectedBuyerId, setSelectedBuyerId] = useState<number>(0);
  const { sortDirection, sortField } = useSelector(selectDialogState);
  const { t } = useTranslation();

  const { data, refetch: refetchBuyers } = buyersRequestMap[
    userData.role as Roles
  ](
    {
      page,
      pageSize,
      sortField,
      sortDirection,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [buyerData, setBuyerData] = useState<Buyer>();

  useEffect(() => {
    refetchBuyers();
  }, [isAddedNewBuyer]);

  const clientActions = [
    {
      icon: (
        <span className={styles["buyers__action-icon"]}>
          <IconMemo name="Detalj" />
        </span>
      ),
      name: t("table.actions.details"),
      handleClick: (value: string) => {
        setBuyerData(
          data?.content.filter((data) => data.id === Number(value))[0]
        );
        setIsOpenBuyerPreview(true);
      },
    },
    {
      icon: (
        <span className={styles["buyers__action-icon"]}>
          <IconMemo name="Obrisi" />
        </span>
      ),
      name: t("table.actions.delete"),
      handleClick: (value: string) => {
        setSelectedBuyerId(Number(value));
        setIsOpenDeleteConfirmationDialog(true);
      },
    },
  ];

  const adminActions = [
    {
      icon: (
        <span className={styles["buyers__action-icon"]}>
          <IconMemo name="Detalj" />
        </span>
      ),
      name: t("table.actions.details"),
      handleClick: (value: string) => {
        setBuyerData(
          data?.content.filter((data) => data.id === Number(value))[0]
        );
        setIsOpenBuyerPreview(true);
      },
    },
  ];

  const columnsDefinitionAdmin = () => {
    const additionalColumnLimit = {
      header: (
        <div
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          <div className={styles["buyers__th-buttons-wrapper"]}>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "debtLimit") {
                  dispatch(changeBuyersSortField("debtLimit"));
                  dispatch(changeBuyersSortDirection("asc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "debtLimit") {
                  dispatch(changeBuyersSortField("debtLimit"));
                  dispatch(changeBuyersSortDirection("desc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
          </div>
          <span>{t("table.users.limit")}</span>
        </div>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>
          {localizedCurrency(row.debtLimit)}
        </p>
      ),
    };

    const additionalRemainingLimit = {
      header: (
        <div
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          <div className={styles["buyers__th-buttons-wrapper"]}>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "remainingLimit"
                ) {
                  dispatch(changeBuyersSortField("remainingLimit"));
                  dispatch(changeBuyersSortDirection("asc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "remainingLimit") {
                  dispatch(changeBuyersSortField("remainingLimit"));
                  dispatch(changeBuyersSortDirection("desc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
          </div>
          <span>{t("dialog.users.remainingLimit")}</span>
        </div>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>
          {localizedCurrency(row.remainingLimit)}
        </p>
      ),
    };
    const columns = [...columnsDefinitionBuyers];
    columns.splice(1, 0, additionalColumnLimit);
    columns.splice(3, 0, additionalRemainingLimit);
    columns.splice(5, 3);
    return columns;
  };

  useEffect(() => {
    const tableRowsNumber = data?.content.length;

    if (!tableRowsNumber) return;

    const rowsButtonData = [];
    for (let i = 0; i < tableRowsNumber; i++) {
      rowsButtonData.push({
        isOpen: false,
        actions: userData.role === Roles.Admin ? adminActions : clientActions,
      });
    }

    setDropDownData(rowsButtonData);
  }, [data]);

  const columnsDefinitionBuyers = [
    {
      header: (
        <div className={styles["buyers__th"]}>
          <div className={styles["buyers__th-buttons-wrapper"]}>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "companyName") {
                  dispatch(changeBuyersSortField("companyName"));
                  dispatch(changeBuyersSortDirection("asc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "companyName") {
                  dispatch(changeBuyersSortField("companyName"));
                  dispatch(changeBuyersSortDirection("desc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
          </div>
          <span>{t("table.users.companyName")}</span>
        </div>
      ),
      field: (row: Buyer) => row.companyName,
    },
    {
      header: (
        <div
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          <div className={styles["buyers__th-buttons-wrapper"]}>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "totalDebt") {
                  dispatch(changeBuyersSortField("totalDebt"));
                  dispatch(changeBuyersSortDirection("asc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "totalDebt") {
                  dispatch(changeBuyersSortField("totalDebt"));
                  dispatch(changeBuyersSortDirection("desc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
          </div>
          <span>{t("table.users.debt")}</span>
        </div>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>
          {row.totalDebt && localizedCurrency(row.totalDebt)}
        </p>
      ),
    },
    {
      header: t("table.buyers.email"),
      field: (row: Buyer) => row.companyEmail,
    },
    {
      header: (
        <p
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          {t("table.buyers.pib")}
        </p>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>{row.companyPib}</p>
      ),
    },
    {
      header: (
        <p
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          {t("dialog.buyers.registrationNumber")}
        </p>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>
          {row.registrationNumber}
        </p>
      ),
    },
    {
      header: (
        <div
          className={classnames(
            styles["buyers__th"],
            styles["buyers__text-align-right"]
          )}
        >
          <div className={styles["buyers__th-buttons-wrapper"]}>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "cooperationStartDate"
                ) {
                  dispatch(changeBuyersSortField("cooperationStartDate"));
                  dispatch(changeBuyersSortDirection("asc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
            <button
              className={styles["buyers__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "cooperationStartDate"
                ) {
                  dispatch(changeBuyersSortField("cooperationStartDate"));
                  dispatch(changeBuyersSortDirection("desc"));
                  refetchBuyers();
                }
              }}
            >
              <IconMemo name="down" />
            </button>
          </div>
          <span>{t("table.buyers.cooperationStartDate")}</span>
        </div>
      ),
      field: (row: Buyer) => (
        <p className={styles["buyers__text-align-right"]}>
          {row.cooperationStartDate
            ? localizedTime(row.cooperationStartDate)
            : ""}
        </p>
      ),
    },
    {
      header: "",
      field: (_row: Buyer, index: number) => (
        <DropDown
          id={_row.id.toString()}
          index={index}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
        />
      ),
    },
  ];

  return (
    <>
      <CreateNewBuyer />
      {data ? (
        <Table
          isAdded={isAddedNewBuyer}
          data={data}
          currentPage={page}
          onPageChange={(value) => dispatch(onPageChange(value))}
          columns={
            userData.role === Roles.Admin
              ? columnsDefinitionAdmin()
              : columnsDefinitionBuyers
          }
        />
      ) : (
        <div className={styles["buyers__spinner-wrapper"]}>
          <Spinner />
        </div>
      )}

      <DeleteConfirmationDialog
        open={isOpenDeleteConfirmationDialog}
        onClose={() => setIsOpenDeleteConfirmationDialog(false)}
        buyerId={selectedBuyerId}
      />

      <BuyerPreview
        data={buyerData}
        open={isOpenBuyerPreview}
        onClose={() => setIsOpenBuyerPreview(false)}
      />
    </>
  );
};

const CreateNewBuyer = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <HeaderPortal>
      <FloatButton
        color="orange"
        onClick={() => dispatch(openBuyerEditDialog(null))}
      >
        <Icon name="Novi-kupac" />
        {t("cta.createNewBuyer")}
      </FloatButton>
    </HeaderPortal>
  );
});

const IconMemo = React.memo(Icon);
