import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FullLogoImage from "../../assets/images/logo/logo-natpis.png";
import LogoImage from "../../assets/images/logo/logo.png";
import { Roles } from "../../state/auth/auth-params";
import { selectUser } from "../../state/auth/auth.slice";
import { selectDialogState } from "../../state/user/users.slice";
import { CompanyDataSettingsDialog } from "../company-data-settings/company-data-settings";
import { InfoDialog } from "../info-dialog/info-dialog";
import { CompanyDataDialog } from "../company-data-dialog/company-data-dialog";
import { HeaderLinkMemo } from "./header-link";
import { UserDataSettingsDialog } from "../user-data-settings-dialog/user-data-settings-dialog";
import styles from "./header.module.scss";

export interface IHeaderProps {
  isOpenSideBar: boolean;
}

export const HeaderPortalContext =
  createContext<{
    portalEl: HTMLElement | null;
    setPortalEl: (el?: HTMLElement) => void;
  } | null>(null);

export const Header: FC<IHeaderProps> = ({ isOpenSideBar }) => {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const headerPortalContext = useContext(HeaderPortalContext);
  const userInfo = useSelector(selectUser);
  const user = useSelector(selectDialogState);
  const [isOpenCompanySettingsDialog, setIsOpenCompanySettingsDialog] =
    useState<boolean>(false);
  const [isOpenInfoDialog, setIsOpenInfoDialog] = useState<boolean>(false);
  const [isOpenUserDataSettingsDialog, setIsOpenUserDataSettingsDialog] =
    useState<boolean>(false);
  const [isOpenCompanyDataDialog, setIsOpenCompanyDataDialog] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    headerPortalContext &&
      headerPortalContext.setPortalEl(portalRef.current as HTMLElement);
  }, [headerPortalContext]);

  return (
    <header className={styles["header"]}>
      <div className={styles["header__pages"]}>
        <div className={styles["header__logo"]}>
          {isOpenSideBar ? (
            <img src={FullLogoImage} alt="Faktoring logo" />
          ) : (
            <img src={LogoImage} alt="Faktoring logo" />
          )}
        </div>
        <HeaderLinkMemo
          endpoint="requests"
          title={t("header.myRequests")}
          icon="Moji-fajlovi"
          className={styles["header__page-link"]}
        />
        {userInfo.role === Roles.Admin && (
          <HeaderLinkMemo
            endpoint="users"
            title={t("header.myUsers")}
            icon="Moji-kupci"
            className={styles["header__page-link"]}
          />
        )}
        <HeaderLinkMemo
          endpoint="buyers"
          title={t("header.myBuyers")}
          icon="Moji-kupci"
          className={styles["header__page-link"]}
        />
        {userInfo.role === Roles.Admin && (
          <>
            <HeaderLinkMemo
              endpoint="reports"
              title={t("header.reports")}
              icon="Izvestaj"
              className={styles["header__page-link"]}
            />
            <HeaderLinkMemo
              endpoint="contracts"
              title={t("header.contracts")}
              icon="Moji-fajlovi"
              className={styles["header__page-link"]}
            />
            <HeaderLinkMemo
              endpoint="payments"
              title={t("header.payments")}
              icon="Moji-fajlovi"
              className={styles["header__page-link"]}
            />
          </>
        )}
      </div>

      <div className={styles["header__account-details"]}>
        {userInfo.role === Roles.Client && (
          <button
            className={styles["header__info"]}
            onClick={() => setIsOpenInfoDialog(true)}
          >
            ?
          </button>
        )}

        <button
          className={styles["header__account"]}
          onClick={() => setIsOpenUserDataSettingsDialog(true)}
        >
          {t("header.myProfile")}
        </button>

        <button
          className={styles["header__account"]}
          onClick={() =>
            userInfo.companyPib !== "000000000" || user.isValidPib
              ? setIsOpenCompanySettingsDialog(true)
              : setIsOpenCompanyDataDialog(true)
          }
        >
          {t("header.myCompany")}
        </button>

        <div ref={portalRef} className={styles["header__portal"]}></div>
      </div>

      {userInfo.role === Roles.Client && (
        <InfoDialog
          open={isOpenInfoDialog}
          onClose={() => setIsOpenInfoDialog(false)}
        />
      )}

      <UserDataSettingsDialog
        open={isOpenUserDataSettingsDialog}
        onClose={() => setIsOpenUserDataSettingsDialog(false)}
      />

      <CompanyDataDialog
        open={isOpenCompanyDataDialog}
        onClose={() => setIsOpenCompanyDataDialog(false)}
      />
      <CompanyDataSettingsDialog
        open={isOpenCompanySettingsDialog}
        onClose={() => setIsOpenCompanySettingsDialog(false)}
      />
    </header>
  );
};
