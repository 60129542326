import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styles from "./register.module.scss";

interface IValues {
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  PIB: string;
}

export const Register = () => {
  const navigate = useNavigate();
  const [isOpenRegister, setIsOpenRegister] = useState<boolean>(false);
  const handleSubmit = (values: IValues) => console.log(values);

  useEffect(() => {
    setIsOpenRegister(true);
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form }) => {
        const handleClose = () => {
          setIsOpenRegister(false);
          form.restart();
          navigate("/login");
        };

        return (
          <div className={styles["register"]}>
            <Dialog open={isOpenRegister} onClose={handleClose}>
              <DialogHeader> Register </DialogHeader>
              <DialogBody>
                <div className={styles["register__body"]}>
                  <Field
                    name="name"
                    type="text"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="Ime" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="surname"
                    type="text"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="Prezime" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="phone"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="Broj telefona" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="email"
                    type="email"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="E-mail" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="password"
                    type="password"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="Lozinka" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="confirmPassword"
                    type="password"
                    validate={(value, allValues: any) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }

                      if (allValues && value !== allValues.password) {
                        return "Loznika nije ista";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField
                            {...input}
                            placeholder="Potvrdite Lozinku"
                          />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="PIB"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return "Polje je obavezno";
                      }

                      if (value.length !== 9) {
                        return "PIB mora imati 9 cifara";
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["register__field"]}>
                          <InputField {...input} placeholder="PIB" />

                          <div
                            className={styles["register__field--validation"]}
                          >
                            <div>{isError && meta.error}</div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </DialogBody>
              <DialogActions align="right">
                <FlatButton
                  onClick={() => form.submit()}
                  type="button"
                  color="blue"
                >
                  Potvrdi
                </FlatButton>
              </DialogActions>
            </Dialog>
          </div>
        );
      }}
    />
  );
};
