import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "../store";

const contractsSlice = createSlice({
  name: "contracts",
  initialState: {
    page: 0,
    pageSize: 12,
    filteredType: "",
    filteredStatus: "",
    sortField: "",
    sortDirection: "",
  },
  reducers: {
    onPageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    onFilteredTypeChange: (state, action: PayloadAction<string>) => {
      state.filteredType = action.payload;
    },
    onFilteredStatusChange: (state, action: PayloadAction<string>) => {
      state.filteredStatus = action.payload;
    },
    onSortFieldChange: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    onSortDirectionChange: (state, action: PayloadAction<string>) => {
      state.sortDirection = action.payload;
    },
  },
});

export const selectContractsData = (
  state: ReturnType<typeof store.getState>
) => ({
  page: state.contracts.page,
  pageSize: state.contracts.pageSize,
  filteredType: state.contracts.filteredType,
  filteredStatus: state.contracts.filteredStatus,
  sortField: state.contracts.sortField,
  sortDirection: state.contracts.sortDirection,
});

export default contractsSlice;

export const {
  onPageChange,
  onFilteredStatusChange,
  onFilteredTypeChange,
  onSortDirectionChange,
  onSortFieldChange,
} = contractsSlice.actions;
