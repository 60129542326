import { classnames } from "@faktoring/util";
import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import styles from "./icon-button.module.scss";

interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  color?: "blue" | "orange";
}

export const IconButton: FC<IIconButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => {
  const classes = classnames(className, styles["button__icon"], {
    [styles["button__icon--blue"]]: color === "blue",
    [styles["button__icon--orange"]]: color === "orange",
  });

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};
