import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
} from "@faktoring/ui";
import { ComponentType, FC, useState } from "react";
import { NewInvoicetHeader } from "./new-invoice-header";
import { StepOneData } from "./step-one-data";
import { StepTwoFiles } from "./step-two-files";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../state/requests/invoice.class";
import { FormApi } from "final-form";
import styles from './new-invoice-step.module.scss';

type FormValues = Record<"invoiceList", string>;

interface INewInvoiceProps {
  open: boolean;
  onClose: () => void;
  newRequestForm: FormApi<FormValues, Partial<string>>;
  selectedIndex?: number | undefined;
  setSelectedIndex?: (value: number | undefined) => void;
  update: (name: string, selectedIndex: number, data: Invoice) => void;
}

export const NewInvoice: FC<INewInvoiceProps> = ({
  open,
  onClose,
  newRequestForm,
  selectedIndex,
  setSelectedIndex,
  update,
}) => {
  const [step, setStep] = useState<number>(1);
  const { t } = useTranslation();

  const formConfig: {
    step: number;
    title: string;
    BodyComponent: ComponentType<any>;
  }[] = [
    {
      step: 1,
      title: t("table.actions.details"),
      BodyComponent: StepOneData,
    },
    { step: 2, title: t("dialog.info.addInvoiceDocuments"), BodyComponent: StepTwoFiles },
  ];

  const stepForward = () => step < formConfig.length && setStep(step + 1);

  const stepBack = () => setStep(step - 1);

  const { title, BodyComponent } = formConfig.filter(
    (el) => el.step === step
  )[0];

  const onSubmit = async (data: Invoice) => {  
    if (selectedIndex !== undefined) {
      update("invoices", selectedIndex, data);
      return;
    }

    await newRequestForm.mutators.push("invoices", data);
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        form,
        valid,
        form: {
          mutators: { push },
        },
      }) => {
        const closeAndRestart = () => {
          form.restart();
          setStep(1);
          onClose();
          if (setSelectedIndex) setSelectedIndex(undefined);
        };

        const handleBack = () => (step === 1 ? closeAndRestart() : stepBack());

        const handleForward = async () => {
          if (!valid || step === formConfig.length) {
            await form.submit();

            const isSubmitted = form.getState().submitSucceeded;

            if (isSubmitted) {
              closeAndRestart();
            }

            return;
          }

          stepForward();
        };

        return (
          <Dialog open={open} onClose={closeAndRestart} className={styles["new-invoice__dialog"]}>
            <DialogHeader>
              <NewInvoicetHeader activeStep={step} title={title} />
            </DialogHeader>

            <DialogBody className={styles["new-invoice__dialog-body"]}>
              <BodyComponent
                newRequestForm={newRequestForm}
                form={form}
                push={push}
                selectedIndex={selectedIndex}
              />
            </DialogBody>
            
            <DialogActions className={styles["new-invoice__dialog-actions-group"]}>
              <FlatButton type="button" onClick={handleBack}>
                {step === 1 ? t("dialog.requests.cta.quit") : t("dialog.requests.cta.back")}
              </FlatButton>
              <FlatButton type="button" color="blue" onClick={handleForward}>
                {step === formConfig.length ? t("dialog.requests.cta.add") : t("dialog.requests.cta.continue")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
