import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { store } from "../store";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isEditDialogOpen: false,
    isValidPib: false,
    editDialogId: 0,
    pageSize: 12,
    page: 0,
  },
  reducers: {
    onPageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    openUserEditDialog: (state, action: PayloadAction<number>) => {
      state.editDialogId = action.payload
      state.isEditDialogOpen = true;
    },
    closeUserEditDialog: (state) => {
      state.editDialogId = 0;
      state.isEditDialogOpen = false;
    },
    validatePib: (state) => {
      state.isValidPib = true;
    },
  },
});

export default usersSlice;

export const selectUsersPageOptions = (state: ReturnType<typeof store.getState>) => ({
  page: state.users.page,
  pageSize: state.users.pageSize,
});

export const selectDialogState = (state: ReturnType<typeof store.getState>) => ({
  isEditDialogOpen: state.users.isEditDialogOpen,
  editDialogId: state.users.editDialogId,
  isValidPib: state.users.isValidPib
});

export const useEditUserDialogState = () => {
  const state = useSelector(selectDialogState, shallowEqual);
  const dispatch = useDispatch();
  return {
    open: state.isEditDialogOpen,
    id: state.editDialogId,
    onClose: () => dispatch(closeUserEditDialog()),
  };
};

export const {
  onPageChange,
  openUserEditDialog,
  closeUserEditDialog,
  validatePib
} = usersSlice.actions;