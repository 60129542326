import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../state/auth/auth.service";

export const Logout: FC = () => {
  const navigate = useNavigate();
  const didLogRef = useRef<boolean>(false);
  const [logout] = useLogoutMutation();

  useEffect(() => {
    if (!didLogRef.current) {
      didLogRef.current = true;

      logout(null)
        .unwrap()
        .then(() => navigate("/login"))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
