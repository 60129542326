import { DropDown, Spinner, Table } from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import i18next from 'i18next';
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EditUser } from "../../components/user-edit/user-edit";
import { User } from "../../state/user/user.class";
import { useAllUsersQuery } from "../../state/user/users-api.service";
import {
  onPageChange,
  openUserEditDialog,
  selectUsersPageOptions,
  useEditUserDialogState,
} from "../../state/user/users.slice";
import styles from "./users.module.scss";

interface IDropDownElement {
  isOpen: boolean;
  actions: {
    icon: ReactElement;
    name: string;
    handleClick: (value: string) => void;
  }[];
}

export const localizedCurrency = (amount: number | string) => {
  return  Number(amount).toLocaleString("sr-RS", {
    style: "currency",
    currency: "RSD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const localizedTime = (date: Date | string) => {
  return new Date(date).toLocaleString(`${i18next.t("time.date")}`, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export const Users: FC = () => {
  const dispatch = useDispatch();
  const { pageSize, page } = useSelector(selectUsersPageOptions);
  const { t } = useTranslation();
  const [dropDownData, setDropDownData] = useState<IDropDownElement[] | null>(
    null
  );
  const { data } = useAllUsersQuery(
    {
      page,
      pageSize,
    },
    { refetchOnMountOrArgChange: true }
  );

  const actions = [
    {
      icon: <span className={styles["users__action-icon"]}><Icon name="Da-potpise" /></span>,
      name: t("table.actions.change"),
      handleClick: (id: string) => dispatch(openUserEditDialog(Number(id))),
    },
  ];

  useEffect(() => {
    const tableRowsNumber = data?.content.length;

    if (!tableRowsNumber) return;

    const rowsButtonData = [];
    for (let i = 0; i < tableRowsNumber; i++) {
      rowsButtonData.push({ isOpen: false, actions });
    }

    setDropDownData(rowsButtonData);
  }, [data]);

  const columnsDefinitionUsers = [
    {
      header: t("table.users.companyName"),
      field: (row: User) => row.companyName,
    },
    {
      header: t("table.users.email"),
      field: (row: User) => row.companyEmail,
    },
    {
      header: (
        <p className={styles["users__text-align-right"]}>
          {t("table.users.phone")}
        </p>
      ),
      field: (row: User) => (
        <p className={styles["users__text-align-right"]}>
          {row.companyPhoneNumber}
        </p>
      ),
    },
    {
      header: (
        <p className={styles["users__text-align-right"]}>
          {t("table.users.limit")}
        </p>
      ),
      field: (row: User) => (
        <p className={styles["users__text-align-right"]}>
          {row.debtLimit && localizedCurrency(row.debtLimit)}
        </p>
      ),
    },
    {
      header: (
        <p className={styles["users__text-align-right"]}>
          {t("table.users.debt")}
        </p>
      ),
      field: (row: User) => (
        <p className={styles["users__text-align-right"]}>
          {row.debt && localizedCurrency(row.debt)}
        </p>
      ),
    },
    {
      header: (
        <p className={styles["users__text-align-right"]}>
          {t("table.users.retainedAdvancePercent")}
        </p>
      ),
      field: (row: User) => (
        <p className={styles["users__text-align-right"]}>
          {row.retainedAdvancePercent ? `${row.retainedAdvancePercent}%` : "10%"}
        </p>
      ),
    },
    {
      header: (
        <p className={styles["users__text-align-right"]}>
          {t("table.users.monthlyInterestRatePercent")}
        </p>
      ),
      field: (row: User) => (
        <p className={styles["users__text-align-right"]}>
          {row.monthlyInterestRatePercent && `${row.monthlyInterestRatePercent}%`}
        </p>
      ),
    },
    {
      header: "",
      field: (_row: User, index: number) => (
        <DropDown
          id={_row.id.toString()}
          index={index}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
        />
      ),
    },
  ];

  return (
    <>
      {data ? (
        <Table
          isAdded={false}
          data={data}
          currentPage={page}
          onPageChange={(value) => dispatch(onPageChange(value))}
          columns={columnsDefinitionUsers}
        />
      ) : (
        <div className={styles["users__spinner-wrapper"]}>
          <Spinner />
        </div>
      )}
      <EditUser {...useEditUserDialogState()} />
    </>
  );
};
