import React from 'react';
import { DatePick, InputField, SelectField } from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { FormApi } from "final-form";
import { FC } from "react";
import { Field } from "react-final-form";
import { currencies } from "../../constants/new-request-constants";
import styles from "./new-invoice-step.module.scss";
import { useTranslation } from 'react-i18next';

interface IStepOneNewInvoice {
  form: FormApi;
  newRequestForm: FormApi;
  selectedIndex: number;
}

export const StepOneData: FC<IStepOneNewInvoice> = ({
  form,
  newRequestForm,
  selectedIndex,
}) => {
  const formValues = form.getState().values;
  const invoices = newRequestForm.getFieldState("invoices");
  const { t } = useTranslation();

  const initValue = (fieldName: string) => {
    if (selectedIndex !== undefined && invoices?.value) {
      return invoices?.value[selectedIndex][fieldName];
    } else {
      return "";
    }
  };

  return (
    <div className={styles["new-invoice__step-container"]}>
      <div className={styles["new-invoice__row--three-fields"]}>
        <Field
          name="invoiceNumber"
          type="text"
          initialValue={initValue("invoiceNumber")}
          validate={(value) => {
            if (!value) {
              return t("warnings.requiredField");
            }
          }}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-invoice__field"]}>
                <InputField {...input} placeholder={`${t("dialog.requests.invoiceNumber")}`} />

                <div className={styles["new-invoice__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>

      <div className={styles["new-invoice__row--three-fields"]}>

        <Field
          name="issueDate"
          type="text"
          initialValue={initValue("issueDate")}
          validate={(value) => {
            if (!value) {
              return t("warnings.requiredField");
            }
          }}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;
            const { paymentDate } = formValues;

            return (
              <div className={styles["new-invoice__field"]}>
                <DatePick
                  endDate={paymentDate}
                  icon={<IconMemo name="Datum-saradnje" />}
                  placeholder={`${t("dialog.requests.issueDate")}`}
                  {...input}
                />

                <div className={styles["new-invoice__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="paymentDate"
          type="select"
          initialValue={initValue("paymentDate")}
          validate={(value) => {
            if (!value) {
              return t("warnings.requiredField");
            }
          }}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;
            const { issueDate } = formValues;

            return (
              <div className={styles["new-invoice__field"]}>
                <DatePick
                  startDate={issueDate}
                  icon={<IconMemo name="Datum-dospeca" />}
                  placeholder={`${t("dialog.requests.paymentDate")}`}
                  {...input}
                />

                <div className={styles["new-invoice__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>

      <div className={styles["new-invoice__row--three-fields"]}>
        <Field
          name="amount"
          type="number"
          initialValue={initValue("amount")}
          validate={(value) => {
            if (!value) {
              return t("warnings.requiredField");
            }
          }}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-invoice__field"]}>
                <InputField
                  className={styles["new-invoice__number-field"]}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  {...input}
                  min={0}
                  step="any"
                  placeholder={`${t("dialog.requests.totalAmount")}`}
                />

                <div className={styles["new-invoice__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="currency"
          type="select"
          initialValue={currencies[0].value}
          validate={(value) => {
            if (!value) {
              return t("warnings.requiredField");
            }
          }}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-invoice__field"]}>
                <SelectField
                  placeholder={`${t("dialog.requests.currency")}`}
                  options={currencies}
                  {...input}
                />

                <div className={styles["new-invoice__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

const IconMemo = React.memo(Icon)