import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  Spinner,
} from "@faktoring/ui";
import arrayMutators from "final-form-arrays";
import { FC } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Roles } from "../../state/auth/auth-params";
import {
  useAddFilesToRequestMutation,
  useUploadFilesMutation,
} from "../../state/requests/requests-api.service";
import { useUserQuery } from "../../state/user/users-api.service";
import { StepTwoFiles } from "../new-invoice/step-two-files";
import styles from "./message-preview.module.scss";

interface IMessagePreviewProps {
  open: boolean;
  onClose: () => void;
  message: string;
  idRequest: number;
}

export const MessagePreview: FC<IMessagePreviewProps> = ({
  open,
  onClose,
  message,
  idRequest,
}) => {
  const { data: userInfo } = useUserQuery(null);
  const [uploadFiles] = useUploadFilesMutation();
  const [addFilesToRequest] = useAddFilesToRequestMutation();
  const [areFilesAdded, setAreFilesAdded] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSumbit = async (values: {files: File[]}) => {
    const files = values.files;
    const formData = new FormData();

    if (files) {
      await Promise.all(
        files.map((file: File) => formData.append("files", file))
      );
    }

    await uploadFiles(formData)
      .unwrap()
      .then((data) =>
        addFilesToRequest({ id: `${idRequest}`, files: data.files })
      )
      .then(() => setAreFilesAdded(true));
  };

  return (
    <Form
      onSubmit={handleSumbit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        form,
        form: {
          mutators: { push },
        },
      }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <>
            <Dialog open={open} onClose={closeAndReset}>
              <DialogHeader>{t("dialog.requests.message")}</DialogHeader>
              <DialogBody>
                <p>{message}</p>

                {userInfo.role === Roles.Client && (
                  <>
                    <h4>*{t("dialog.requests.addDocs")}:</h4>
                    <StepTwoFiles push={push} />

                    <DialogActions
                      className={styles["message-preview__buttons-wrapper"]}
                      align="left"
                    >
                      <FlatButton type="button" onClick={closeAndReset}>
                        {t("dialog.requests.cta.quit")}
                      </FlatButton>
                      <FlatButton
                        color="blue"
                        className={styles["message-preview__button-add"]}
                        onClick={async () => {
                          await form.submit();
                          
                          setTimeout(() => {
                            setAreFilesAdded(false);
                            closeAndReset();
                          }, 350);
                        }}
                      >
                        {areFilesAdded ? (
                          <Spinner />
                        ) : (
                          t("dialog.requests.cta.send")
                        )}
                      </FlatButton>
                    </DialogActions>
                  </>
                )}
              </DialogBody>
            </Dialog>
          </>
        );
      }}
    />
  );
};
