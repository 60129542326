import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../icon/icon";
import styles from "./search-bar.module.scss";

interface ISearchBar {
  onSearchedValueChange: (value: string) => void;
}

export const SearchBar: FC<ISearchBar> = ({ onSearchedValueChange }) => {
  const [searchedText, setSearchedText] = useState<string>("");
  const { t } = useTranslation();

  return (
    <div className={styles["search-bar"]}>
      <form
        id="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSearchedValueChange(searchedText);
        }}
        className={styles["search-bar__form"]}
      >
        <IconMemo name="Search" />
        <input
          type="text"
          onChange={(e) => setSearchedText(e.target.value)}
          className={styles["search-bar__input"]}
        />
      <button type="submit" form="form" className={styles["search-bar__submit-button"]}>
        {t("dialog.requests.cta.search")}
      </button>
      </form>
    </div>
  );
};

const IconMemo = React.memo(Icon);
