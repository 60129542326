import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Roles } from "../../state/auth/auth-params";
import { selectUser } from "../../state/auth/auth.slice";
import styles from "./header-link.module.scss";

interface IHeaderLinkProps {
  endpoint: string;
  title: string;
  icon: string;
  className?: string;
}

const HeaderLink: FC<IHeaderLinkProps> = ({ endpoint, title, icon }) => {
  const userInfo = useSelector(selectUser);

  return (
    <NavLink
      to={`/${userInfo.role === Roles.Client ? "client" : "admin"}/${endpoint}`}
      className={({ isActive }) =>
        classnames(styles["header-link"], {
          [styles["active"]]: isActive,
        })
      }
    >
      <Icon name={icon} />
      {title}
    </NavLink>
  );
};

export const HeaderLinkMemo = React.memo(HeaderLink)