import { classnames } from "@faktoring/util";
import { cloneElement, FC, ReactElement } from "react";
import styles from "./stepper.module.scss";

interface IStepperProps {
  children: ReactElement[];
  className?: string;
  activeStep?: number;
}

export const Stepper: FC<IStepperProps> = ({
  className,
  children,
  activeStep,
  ...props
}) => {
  const classes = classnames(className, styles["stepper"]);

  return (
    <section className={classes} {...props}>
      <ul>
        {children.map((child: ReactElement, i: number) =>
          cloneElement(child, { key: i, index: i + 1, activeStep })
        )}
      </ul>
    </section>
  );
};
