import {
  Dialog,
  DialogBody,
  DialogHeader,
  FlatButton,
  Spinner,
  Status
} from "@faktoring/ui";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectDialogState } from "../../state/requests/request.slice";
import {
  useDownloadFileMutation,
  useRequestsQuery,
  useUpdateRequestStatusMutation
} from "../../state/requests/requests-api.service";
import { Request, initialRequest } from "../../state/requests/requests.class";
import { selectRequestsPageOptions, selectRequestState } from "../../state/requests/requests.slice";
import {
  openTotalRequestPreview,
  useTotalRequestPreviewState
} from "../../state/requests/total-request.slice";
import { InvoicePreview, formatNumber } from "../invoice-preview/invoice-preview";
import { InvoicesList } from "../invoices-list/invoices-list";
import { MessagePreview } from "../message-preview/message-preview";
import { TotalRequestPreview } from "../total-request-preview/total-request-preview";
import styles from "./client-request-preview.module.scss";

interface IRequestPreviewProps {
  open: boolean;
  onClose: () => void;
  onStatusChange: (value: boolean) => void
}

interface IFile {
  fileReference: string;
  name: string;
  type: string;
}

export const ClientRequestPreview: FC<IRequestPreviewProps> = ({
  open,
  onClose,
  onStatusChange
}) => {
  const dispatch = useDispatch();
  const { pageSize, page } = useSelector(selectRequestsPageOptions);
  const {requestFilteredStatus, requestsSearchedValue, requestsSortDirection: sortDirection, requestsSortField: sortField} = useSelector(selectDialogState)
  const { requestsData: data } = useSelector(selectRequestState)
  const {
    isFetching,
  } = useRequestsQuery({
    page,
    pageSize,
    status: requestFilteredStatus,
    searchedValue: requestsSearchedValue,
    sortDirection,
    sortField,
  });
  const [updateRequest] = useUpdateRequestStatusMutation();
  const [downloadFile] = useDownloadFileMutation();
  const { requestPreviewId } = useSelector(selectDialogState);
  const [isOpenMessagePreview, setisOpenMessagePreview] = useState<boolean>(false);
  const [isOpenInvoicePreview, setisOpenInvoicePreview] = useState<boolean>(false);
  const [selectedIndex, setselectedIndex] = useState<number>(0);
  const { t } = useTranslation();

  const request = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className={styles["client-request-preview"]}
      >
        <DialogHeader> {t("dialog.requests.requestDetails")}: {request?.id} </DialogHeader>

        <DialogBody>
          <InvoicesList
            actions={false}
            invoices={request?.invoices}
            setIsOpenNewInvoice={(value) => setisOpenInvoicePreview(value)}
            setSelectedIndex={setselectedIndex}
            requestStatus={request?.status}
            className={styles["client-request-preview__invoice_list"]}
          />
          <div
            className={
              styles["client-request-preview__additional-info"]
            }
          >
            <div>
              <span className={styles["client-request-preview__additional-files"]}>{t("dialog.requests.additionalFiles")}:</span>
              {request?.files.map((file: IFile, index: number) => (
                <button
                  key={index}
                  onClick={() =>
                    downloadFile({
                      fileReference: file.fileReference,
                      name: file.name,
                      type: file.type,
                    })
                  }
                  className={styles["client-request-preview__file"]}
                >
                  {file.name}
                </button>
              ))}
            </div>
            {request?.adminComment && request?.status === RequestStatus.Created && (
              <button
                className={styles["client-request-preview__message"]}
                onClick={() => setisOpenMessagePreview(true)}
              >
                {t("dialog.requests.message")}
              </button>
            )}
          </div>
          <div
            className={styles["client-request-preview__additional_info"]}
          >
            <p className={styles["client-request-preview__additional_info-payment-data"]}>
              {t("table.requests.reqStatus.name")}:
              <Status title={request?.status || RequestStatus.Created} />
            </p>
            <p className={styles["client-request-preview__additional_info-payment-data"]}>
              {t("dialog.requests.total")}:&nbsp;
              {request?.paymentData.amount && formatNumber(request?.paymentData.amount)}
            </p>
            {request?.status !== RequestStatus.Created &&
              request?.status !== RequestStatus.RejectedAdmin && (
                <p className={styles["client-request-preview__additional_info-payment-data"]}>
                  {t("dialog.requests.finalPaymentAmount")}:&nbsp;
                  {request?.paymentData.finalPaymentAmount && formatNumber(request?.paymentData.finalPaymentAmount)}
                </p>
              )}
          </div>
          {(request?.status === RequestStatus.ApprovedAdmin ||
            request?.status === RequestStatus.ApprovedUser) && (
              <FlatButton
                className={styles["client-request-preview__flatbutton-wide"]}
                color={"blue"}
                onClick={() => dispatch(openTotalRequestPreview(null))}
              >
                {t("dialog.requests.totalCalculation")}
              </FlatButton>
            )}
          {request?.status !== RequestStatus.Created && (
            <>
              {request?.status === RequestStatus.ApprovedAdmin && (
                <div
                  className={styles["client-request-preview__dialog-flatbuttons"]}
                >
                  <FlatButton
                    className={
                      styles["client-request-preview__dialog-flatbutton"]
                    }
                    color="red"
                    onClick={async () => {
                      if (requestPreviewId) {
                        await updateRequest({
                          requestId: Number(requestPreviewId),
                          requestStatus: RequestStatus.RejectedUser,
                        });
                      }

                      onStatusChange(true)
                      onClose()

                      setTimeout(() => {
                        onStatusChange(false)
                      }, 0);
                    }}
                  >
                    {isFetching ? (
                      <Spinner />
                    ) : (
                      t("dialog.requests.cta.rejectRequest")
                    )}
                  </FlatButton>
                  <FlatButton
                    className={
                      styles["client-request-preview__dialog-flatbutton"]
                    }
                    color="blue"
                    onClick={async () => {
                      if (requestPreviewId) {
                        await updateRequest({
                          requestId: Number(requestPreviewId),
                          requestStatus: RequestStatus.ApprovedUser,
                        });
                      }

                      onStatusChange(true)
                      onClose()

                      setTimeout(() => {
                        onStatusChange(false)
                      }, 0);
                    }}
                  >
                    {isFetching ? (
                      <Spinner />
                    ) : (
                      t("dialog.requests.cta.acceptRequest")
                    )}
                  </FlatButton>
                </div>
              )}
            </>
          )}
        </DialogBody>
      </Dialog>

      <TotalRequestPreview {...useTotalRequestPreviewState()} data={request ? request : initialRequest}  />

      <InvoicePreview
        invoiceIndex={selectedIndex}
        open={isOpenInvoicePreview}
        onClose={() => setisOpenInvoicePreview(false)}
      />

      <MessagePreview
        message={request?.adminComment || ""}
        open={isOpenMessagePreview}
        onClose={() => setisOpenMessagePreview(false)}
        idRequest={request?.id || 0}
      />
    </>
  );
};
