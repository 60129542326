import { FlatButton, SelectField } from "@faktoring/ui";
import { FormApi } from "final-form";
import { FC } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  openBuyerEditDialog,
  useEditBuyerDialogState,
} from "../../state/buyers/buyers.slice";
import { EditBuyer } from "../new-buyer/new-buyer";
import styles from "./new-request-steps.module.scss";
import { validateFieldRequired } from "./step-utils";

type FormValues = Record<"buyerPib", string>;

interface ICustomerDataProps {
  buyersOptions: { value: string; label: string }[];
  form?: FormApi<FormValues, Partial<string>>;
}

export const StepTwoCustomerData: FC<ICustomerDataProps> = ({
  buyersOptions,
  form,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={styles["new-request__step-container-row"]}>
      <Field
        name="buyerPib"
        type="select"
        validate={validateFieldRequired}
        render={({ input, meta }) => {
          const isError = meta.touched && meta.error;

          return (
            <div className={styles["new-request__field"]}>
              <SelectField
                placeholder={t("dialog.requests.chooseBuyer")}
                options={buyersOptions}
                {...input}
              />
              <div className={styles["new-request__field--validation"]}>
                <div>{isError && meta.error}</div>
              </div>
            </div>
          );
        }}
      />

      <FlatButton
        type="button"
        onClick={() => dispatch(openBuyerEditDialog(null))}
        color="orange"
      >
        <div className={styles["new-request__row-button"]}>
          + &nbsp; {t("dialog.requests.addNewBuyer")}
        </div>
      </FlatButton>

      <EditBuyer {...useEditBuyerDialogState()} form={form} />
    </div>
  );
};
