import { Status } from "@faktoring/ui";
import { classnames } from "@faktoring/util";
import { FormApi } from "final-form";
import { FC, Fragment, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../state/requests/invoice.class";
import { InvoiceFromSef } from "../download-invoices/download-invoices";
import { InvoiceFromSefPreview } from "../invoice-from-sef-preview/invoice-from-sef-preview";
import { formatNumber } from "../invoice-preview/invoice-preview";
import styles from "./invoices-list.module.scss";

interface IInvoicesListProps {
  invoices?: Invoice[] | InvoiceFromSef[];
  children?: ReactNode;
  className?: string;
  onRemoveElement?: (index: number) => void;
  actions?: boolean;
  form?: FormApi<FormValues, Partial<string>>;
  setIsOpenNewInvoice: (value: boolean) => void;
  setSelectedIndex: (value: number) => void;
  requestStatus?: string;
}

type FormValues = Record<"invoiceList", string>;

export const InvoicesList: FC<IInvoicesListProps> = ({
  invoices,
  onRemoveElement,
  actions,
  children,
  className,
  form,
  setIsOpenNewInvoice,
  setSelectedIndex,
  requestStatus,
  ...props
}) => {
  const [isOpenInvoiceFromSefPreview, setIsOpenInvoiceFromSefPreview] =
    useState<boolean>(false);
  const [invoiceFromSefData, setInvoiceFromSefData] = useState<any>({});
  const { t } = useTranslation();
  const classes = classnames(className, styles["invoices-list"]);

  return (
    <>
      <ul className={classes} {...props}>
        {invoices &&
          invoices.map((invoice: any, i: number) => {
            return (
              <Fragment key={i}>
                <li className={styles["invoices-list__element"]}>
                  <div className={styles["invoices-list__number-and-amount"]}>
                    <div>
                      {`${i + 1})`} {invoice.invoiceNumber}
                    </div>
                    <div>
                      {t("table.requests.requestedAmount")}
                      {invoice.requestedAmount
                        ? `: ${formatNumber(invoice.requestedAmount)}`
                        : `: ${formatNumber(Number(invoice.amount))}`}
                    </div>
                  </div>
                  <div className={styles["invoices-list__status-and-cta"]}>
                    {invoice.status !== undefined && (
                      <Status title={invoice.status} />
                    )}
                    <button
                      type="button"
                      id={`${invoice.id}`}
                      onClick={() => {
                        if (invoice.files) {
                          setIsOpenNewInvoice(true);
                          setSelectedIndex(i);
                        } else {
                          setIsOpenInvoiceFromSefPreview(true);
                          setInvoiceFromSefData(invoice);
                        }
                      }}
                      className={styles["invoices-list__details"]}
                    >
                      {t("table.actions.details")}
                    </button>
                  </div>

                  {actions && onRemoveElement && (
                    <button
                      type="button"
                      onClick={() => onRemoveElement(i)}
                      className={styles["invoices-list__remove-button"]}
                    >
                      X
                    </button>
                  )}
                </li>
              </Fragment>
            );
          })}
      </ul>

      <InvoiceFromSefPreview
        open={isOpenInvoiceFromSefPreview}
        onClose={() => setIsOpenInvoiceFromSefPreview(false)}
        data={invoiceFromSefData}
      />
    </>
  );
};
