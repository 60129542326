import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState, store } from "../store";
import { auth } from "./auth-params";

interface IReqDataLogout {
  client_id: string;
  client_secret: string;
  refresh_token: string;
}

interface IReqDataForToken {
  code: string;
  grant_type: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
  client_secret: string;
}

export const encodeFormData = (data: IReqDataForToken | IReqDataLogout) => {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(
          data[key as keyof (IReqDataForToken | IReqDataLogout)]
        )
    )
    .join("&");
};

// Get authorization code
const location = window.location.search;
const code = (location.match(/code=([^&]+)/) || [])[1];

// Authorization parameters
const requestDataLogout: () => IReqDataLogout = () => ({
  client_id: auth.clientId,
  client_secret: auth.clientSecret,
  refresh_token: (store.getState() as RootState).auth.userRefreshToken,
});

const requestDataForToken: () => IReqDataForToken = () => ({
  code: code,
  grant_type: "authorization_code",
  client_id: auth.clientId,
  redirect_uri: auth.loginRedirectUri,
  scope: auth.scope,
  client_secret: auth.clientSecret,
});

const getRequestDataLogout = () => encodeFormData(requestDataLogout());
const getRequestDataForToken = () => encodeFormData(requestDataForToken());

const baseQuery = fetchBaseQuery({
  baseUrl: auth.openidUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.userToken;
    headers.set("Content-Type", "application/x-www-form-urlencoded");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const authorizationSlice = createApi({
  reducerPath: "authorization",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getToken: builder.mutation({
      query: () => ({
        url: "/token",
        method: "POST",
        body: getRequestDataForToken(),
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "POST",
        body: getRequestDataLogout(),
      }),
    }),
  }),
});

export { getRequestDataLogout, getRequestDataForToken };
export const { useGetTokenMutation, useLogoutMutation } = authorizationSlice;
