import { classnames } from "@faktoring/util";
import { FC, ReactNode } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/svg/x.svg";
import styles from "./file-list.module.scss";

interface IFileListProps {
  files: File[];
  children?: ReactNode;
  className?: string;
  onRemoveElement: (index: number) => void;
  actions?: boolean;
}

const FileList: FC<IFileListProps> = ({
  className,
  files,
  onRemoveElement,
  actions = true,
  ...props
}) => {
  const classes = classnames(className, styles["file-list"]);

  const getDocumentType = (docType: string) => {
    const docTypeMap: { [key: string]: string } = {
      "image/jpeg": "JPEG",
      "image/png": "PNG",
      "application/pdf": "PDF",
    };

    return docTypeMap[docType] || "DOC";
  };

  return (
    <ul className={classes} {...props}>
      {files &&
        files?.map(({ name, type }, i) => {
          const documentType = getDocumentType(type);

          return (
            <li key={i} className={styles["file-list__element"]}>
              <div>{name}</div>
              <div className={styles["file-list__details"]}>
                <div className={styles["file-list__document-type"]}>
                  <p> {documentType} </p>
                </div>

                {actions && (
                  <button
                    type="button"
                    onClick={() => onRemoveElement(i)}
                    className={styles["file-list__remove-button"]}
                  >
                    <RemoveIcon />
                  </button>
                )}
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export { type IFileListProps, FileList };
