import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "../store";

const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    page: 0,
    pageSize: 12,
    sortField: "",
    sortDirection: "",
  },
  reducers: {
    onPageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    onSortFieldChange: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    onSortDirectionChange: (state, action: PayloadAction<string>) => {
      state.sortDirection = action.payload;
    },
  },
});

export const selectPaymentsData = (
  state: ReturnType<typeof store.getState>
) => ({
  page: state.payments.page,
  pageSize: state.payments.pageSize,
  sortField: state.payments.sortField,
  sortDirection: state.payments.sortDirection,
});

export default paymentsSlice;

export const {
  onPageChange,
  onSortDirectionChange,
  onSortFieldChange,
} = paymentsSlice.actions;
