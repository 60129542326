import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { FormApi } from "final-form";
import { FC } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import {
  selectDialogState,
  selectUsersPageOptions,
} from "../../state/user/users.slice";
import {
  useAllUsersQuery,
  useUpdateUserPaymentInfoMutation,
} from "../../state/user/users-api.service";
import { localizedTime } from "../../pages/users/users";
import styles from "./user-edit.module.scss";

type FormValues = Record<"user", string>;

interface IUSerEditProps {
  open: boolean;
  onClose: () => void;
  form?: FormApi<FormValues, Partial<string>>;
}

interface IUserData {
  id: number;
  companyName: string;
  companyPib: string;
  companyPhoneNumber: string;
  companyEmail: string;
  foreignCompany: boolean;
  cooperationStartDate: string;
  firstName: string;
  lastName: string;
  debtLimit: number;
  debt: number;
  retainedAdvancePercent: number;
  monthlyInterestRatePercent: number;
}

const emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRgx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

export const EditUser: FC<IUSerEditProps> = ({ open, onClose, form }) => {
  const { pageSize, page } = useSelector(selectUsersPageOptions);
  const { data, refetch: allUsersRefetch } = useAllUsersQuery({
    page,
    pageSize,
  });
  const [updateUserPaymentInfo] = useUpdateUserPaymentInfoMutation();
  const { editDialogId } = useSelector(selectDialogState);
  const { t } = useTranslation();

  const usersData = data?.content.find(
    (user: any) => user.id === Number(editDialogId)
  );

  const validateFieldRequired = (value: number) => {
    if (!value) {
      return t("warnings.requiredField");
    }
  };

  const onSubmit = async (values: IUserData) => {
    if (!data) return;

    const updateData = async () => {
      await updateUserPaymentInfo({
        id: Number(editDialogId),
        debtLimit: values.debtLimit,
        retainedAdvancePercent: values.retainedAdvancePercent,
        monthlyInterestRatePercent: Number(values.monthlyInterestRatePercent),
      });
    };

    updateData();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={usersData}
      render={({ form, pristine }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["user-edit"]}
          >
            <DialogHeader> {t("dialog.users.userData")} </DialogHeader>

            <DialogBody className={styles["user-edit__body"]}>
              <div className={styles["user-edit__body-fields-wrapper"]}>
                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="companyPib"
                    type="number"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("table.buyers.pib")}`}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="registrationNumber"
                    type="number"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                            label={`${t("dialog.buyers.registrationNumber")}`}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="companyName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.companyName")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="shortBusinessName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.shortCompanyName")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="zipCode"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.zipCode")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="city"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.city")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="street"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.street")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="doorNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.doorNumber")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="companyPhoneNumber"
                    type="tel"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.requests.companyPhoneNumber")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyEmail"
                    type="email"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.email")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="representative.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.lastName")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.jmbg"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.jmbg")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.type"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.representativeType")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="accountNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bankAccount")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="bank"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bank")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="creditScore.score"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.creditScore")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="creditScore.date"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.creditScoreDate")}`}
                            value={
                              usersData?.creditScore?.date
                                ? localizedTime(usersData?.creditScore?.date)
                                : ""
                            }
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="creditScore.trend"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.trend")}`}
                            disabled={true}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["user-edit__body-fields-contact"]}>
                  <p
                    className={
                      styles["user-edit__body-fields-contact-subtitle"]
                    }
                  >
                    {t("dialog.buyers.contactPersonsInfo")}
                  </p>
                  <div className={styles["user-edit__body-fields"]}>
                    <Field
                      name="contact.firstName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["user-edit__body-field"]}>
                            <InputField
                              {...input}
                              disabled={true}
                              label={`${t("dialog.buyers.name")}`}
                            />
                            <div
                              className={
                                styles["user-edit__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.lastName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["user-edit__body-field"]}>
                            <InputField
                              {...input}
                              disabled={true}
                              label={`${t("dialog.buyers.lastName")}`}
                            />
                            <div
                              className={
                                styles["user-edit__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.email"
                      type="email"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["user-edit__body-field"]}>
                            <InputField
                              {...input}
                              disabled={true}
                              label={`${t("dialog.buyers.email")}`}
                            />
                            <div
                              className={
                                styles["user-edit__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.phoneNumber"
                      type="tel"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div className={styles["user-edit__body-field"]}>
                            <InputField
                              {...input}
                              disabled={true}
                              label={`${t("dialog.buyers.contactPhoneNum")}`}
                            />
                            <div
                              className={
                                styles["user-edit__body-field--validation"]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>

                <div className={styles["user-edit__body-fields"]}>
                  <Field
                    name="debtLimit"
                    type="number"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            label={`${t("dialog.users.limit")}`}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="retainedAdvancePercent"
                    type="number"
                    validate={(value) => {
                      if (!value) return t("warnings.requiredField");
                      if (value > 100)
                        return t("warnings.retainedAdvancePercentIsHigher");
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            label={`${t(
                              "dialog.users.retainedAdvancePercent"
                            )} (%)`}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="monthlyInterestRatePercent"
                    type="number"
                    validate={(value) => {
                      if (!value) return t("warnings.requiredField");
                      if (value > 100)
                        return t("warnings.monthlyInterestRatePercentIsHigher");
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["user-edit__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            label={`${t(
                              "dialog.requests.monthlyInterestRatePercent"
                            )} (%)`}
                          />
                          <div
                            className={
                              styles["user-edit__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </DialogBody>

            <DialogActions className={styles["user-edit__dialog-actions"]}>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();
                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  await allUsersRefetch();

                  closeAndReset();
                }}
                type="button"
                color="blue"
                disabled={pristine}
              >
                {t("dialog.users.saveChanges")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
