import { createSlice } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { store } from "../store";

const totalRequestSlice = createSlice({
  name: "totalRequest",
  initialState: {
    isTotalRequestPreviewOpen: false,
    clientRequestPreviewId: null,
    isChangedRequestData: false
  },
  reducers: {
    openTotalRequestPreview: (state, {payload}) => {
      state.isTotalRequestPreviewOpen = true;
      state.clientRequestPreviewId = payload
    },
    closeTotalRequestPreview: (state) => {
      state.clientRequestPreviewId = null;
      state.isTotalRequestPreviewOpen = false;
    },
    onChangeRequestData: (state, {payload}) => {
      state.isChangedRequestData = payload;
    },
  },
});

export default totalRequestSlice;

export const selectTotalReqDialogState = (state: ReturnType<typeof store.getState>) => ({
  isTotalRequestPreviewOpen: state.totalRequest.isTotalRequestPreviewOpen,
  clientRequestPreviewId: state.totalRequest.clientRequestPreviewId,
  isChangedRequestData: state.totalRequest.isChangedRequestData
});

export const useTotalRequestPreviewState = () => {
  const state = useSelector(selectTotalReqDialogState, shallowEqual);
  const dispatch = useDispatch();
  return {
    open: state.isTotalRequestPreviewOpen,
    id: state.clientRequestPreviewId,
    onClose: () => dispatch(closeTotalRequestPreview()),
  };
};

export const {
  openTotalRequestPreview,
  closeTotalRequestPreview,
  onChangeRequestData
} = totalRequestSlice.actions;