import { Icon } from "@faktoring/ui/src/components/icon/icon";
import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openBuyerEditDialog } from "../../state/buyers/buyers.slice";
import { openRequestDialog } from "../../state/requests/requests.slice";
import { Header, HeaderPortalContext } from "../header/header";
import { SideNav } from "../side-nav/side-nav";
import { useTranslation } from "react-i18next";
import styles from "./layout.module.scss";
import { Roles } from "../../state/auth/auth-params";
import { useUserQuery } from "../../state/user/users-api.service";
import { AdditionalDocumentsDialog } from "../additional-documents-dialog/additional-documents-dialog";
import { changeRequestFilteredStatus, changeRequestsSortDirection, changeRequestsSortField } from "../../state/requests/request.slice";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: FC<ILayoutProps> = ({ children }) => {
  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(false);
  const [headerPortalEl, setHeaderPortalEl] = useState<HTMLElement | null>(
    null
  );
  const { data: userInfo } = useUserQuery(null);
  const [isOpenAdditionalDocumentsDialog, setIsOpenAdditionalDocumentsDialog] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    userInfo.role === Roles.Client && setIsOpenSideBar(true)
  }, [])
  

  const handleFilterClick = (status: RequestStatus | "") => {
    navigate(`/${userInfo.role === Roles.Client ? "client" : "admin"}/requests`)
    dispatch(changeRequestsSortField("lastStatusChangeDate"));
    dispatch(changeRequestsSortDirection("desc"));
    dispatch(changeRequestFilteredStatus(status))
  }

  const sideBarData = [
    {
      title: t("sideBar.allRequests"),
      statusCode: "",
      icon: <IconMemo name="Svi-zahtevi" />,
      handleClick: () => handleFilterClick("")
    },
    {
      title: t("sideBar.newRequests"),
      statusCode: "CREATED",
      icon: <IconMemo name="Novi-zahtev" />,
      handleClick: () => {
        navigate(`/${userInfo.role === Roles.Client ? "client" : "admin"}/requests`)
        dispatch(changeRequestsSortField("dateCreated"));
        dispatch(changeRequestsSortDirection("desc"));
        dispatch(changeRequestFilteredStatus(RequestStatus.Created))
      }
    },
    {
      title: t("sideBar.approved"),
      statusCode: "APPROVED_ADMIN",
      icon: <IconMemo name="Odobren" />,
      handleClick: () => handleFilterClick(RequestStatus.ApprovedAdmin)
    },
    {
      title: t("sideBar.accepted"),
      statusCode: "APPROVED_USER",
      icon: <IconMemo name="Prihvacen" />,
      handleClick: () => handleFilterClick(RequestStatus.ApprovedUser)
    },
    {
      title: t("sideBar.rejected"),
      statusCode: "REJECTED_ADMIN",
      icon: <IconMemo name="Odbijen" />,
      handleClick: () => handleFilterClick(RequestStatus.RejectedAdmin)
    },
    {
      title: t("sideBar.quited"),
      statusCode: "REJECTED_USER",
      icon: <IconMemo name="Zatvoren" />,
      handleClick: () => handleFilterClick(RequestStatus.RejectedUser)
    },
    {
      title: t("sideBar.realized"),
      statusCode: "REALIZED",
      icon: <IconMemo name="Realizovan" />,
      handleClick: () => handleFilterClick(RequestStatus.Realized)
    },
    {
      title: t("sideBar.charged"),
      statusCode: "CHARGED",
      icon: <IconMemo name="Naplacen" />,
      handleClick: () => handleFilterClick(RequestStatus.Charged)
    },
  ];

  const iconButtonsDataClient = [
    {
      icon: <IconMemo name="Novi-zahtev" />,
      title: t("cta.createNewRequest"),
      handleClick: () => {
        navigate(
          `/${userInfo.role === Roles.Client ? "client" : "admin"}/requests`
        );
        dispatch(openRequestDialog())
      },
    },
    {
      icon: <IconMemo name="Novi-kupac" />,
      title: t("cta.createNewBuyer"),
      handleClick: () => {
        navigate(
          `/${userInfo.role === Roles.Client ? "client" : "admin"}/buyers`
        );
        dispatch(openBuyerEditDialog(null));
      },
    },
  ];

  const iconButtonsDataAdmin = [
    {
      icon: <IconMemo name="Novi-kupac" />,
      title: t("cta.createNewBuyer"),
      handleClick: () => {
        navigate(
          `/${userInfo.role === Roles.Client ? "client" : "admin"}/buyers`
        );
        dispatch(openBuyerEditDialog(null));
      },
    },
  ];

  const logoutData = {
    title: t("sideBar.logout"),
    icon: <IconMemo name="Log-out" />,
    handleClick: () => navigate("/logout"),
  };

  return (
    <>
      <HeaderPortalContext.Provider
        value={{
          portalEl: headerPortalEl,
          setPortalEl: (el?: HTMLElement) =>
            setHeaderPortalEl(el as HTMLElement),
        }}
      >
        <div className={styles["layout-container"]}>
          <Header isOpenSideBar={isOpenSideBar} />
          <div className={styles["main-layout"]}>
            <SideNav
              sideBarData={sideBarData}
              isOpenSideBar={isOpenSideBar}
              logOutData={logoutData}
              setIsOpenSideBar={() => setIsOpenSideBar(!isOpenSideBar)}
              iconButtonsData={
                userInfo.role === Roles.Client
                  ? iconButtonsDataClient
                  : iconButtonsDataAdmin
              }
            />
            <div className={styles["content-wrapper"]}>{children}</div>
          </div>
        </div>
      </HeaderPortalContext.Provider>
      <AdditionalDocumentsDialog
        open={isOpenAdditionalDocumentsDialog}
        onClose={() => setIsOpenAdditionalDocumentsDialog(false)}
      />
    </>
  );
};

const IconMemo = React.memo(Icon);
