import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
  InputShowAndHide,
} from "@faktoring/ui";
import { FC } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  useUpdateUserPersonalDataMutation,
  useUserQuery,
} from "../../state/user/users-api.service";
import { auth } from "../../state/auth/auth-params";
import styles from "./user-data-settings-dialog.module.scss";

interface IUserDataSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const UserDataSettingsDialog: FC<IUserDataSettingsProps> = ({
  open,
  onClose,
}) => {
  const [updateUserPersonalData] = useUpdateUserPersonalDataMutation();
  const { data, refetch } = useUserQuery(null);
  const { t } = useTranslation();

  const params = [
    `response_type=code`,
    `client_id=${auth.clientId}`,
    `redirect_uri=${auth.loginRedirectUri}`,
    `scope=${auth.scope}`,
    `kc_action=UPDATE_PASSWORD`,
  ].join("&");

  const updatePassword = () => {
    try {
      const url = auth.openidUrl + `/auth?${params}`;
      window.location.assign(url);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (values: any) => {
    await updateUserPersonalData(values);
    refetch();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ form, pristine }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["user-data-settings"]}
          >
            <DialogHeader> {t("dialog.users.yoursData")} </DialogHeader>

            <DialogBody className={styles["user-data-settings__body"]}>
              <Field
                name="email"
                type="email"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputField
                        {...input}
                        disabled={true}
                        label={`${t("dialog.users.emailForLogin")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="contactEmail"
                type="email"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputField
                        {...input}
                        label={`${t("dialog.users.contactEmail")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="firstName"
                type="text"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputField
                        {...input}
                        label={`${t("dialog.users.name")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="lastName"
                type="text"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputField
                        {...input}
                        label={`${t("dialog.users.lastName")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="phoneNumber"
                type="text"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputField
                        {...input}
                        label={`${t("dialog.buyers.contactPhoneNum")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <Field
                name="apiKey"
                type="text"
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["user-data-settings__body-field"]}>
                      <InputShowAndHide
                        {...input}
                        label={`${t("dialog.requests.apiKey")}`}
                      />
                      <div
                        className={
                          styles["user-data-settings__body-field--validation"]
                        }
                      >
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />

              <div
                className={
                  styles["user-data-settings__change-password-wrapper"]
                }
              >
                <button
                  onClick={() => updatePassword()}
                  className={
                    styles["user-data-settings__change-password-button"]
                  }
                >
                  {t("dialog.users.changePassword")}
                </button>
              </div>
            </DialogBody>

            <DialogActions
              className={styles["user-data-settings__actions-group"]}
            >
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>

              <FlatButton
                onClick={async () => {
                  await form.submit();
                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  closeAndReset();
                }}
                type="button"
                color="blue"
                className={styles["user-data-settings__save-button"]}
                disabled={pristine}
              >
                {t("dialog.users.saveChanges")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
