import { classnames } from "@faktoring/util";
import { FC, Fragment } from "react";
import styles from "./pagination.module.scss";

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination: FC<IPaginationProps> = ({
  totalPages,
  currentPage = 0,
  onPageChange,
}) => {
  let pagesArr: number[] = [];

  const pagesNums: () => JSX.Element | JSX.Element[] = () => {
    for (let i = 0; i < totalPages; i++) {
      pagesArr.push(i + 1);
    }

    if (totalPages < 8) {
      return pagesArr.map((pageNum) => (
        <button
          key={pageNum}
          onClick={() => onPageChange(pageNum - 1)}
          className={classnames({
            [styles["active"]]: pageNum === currentPage + 1,
          })}
        >
          {pageNum}
        </button>
      ));
    } else {
      const middlePages = () => {
        if (currentPage > 2) {
          return pagesArr.slice(currentPage - 2, currentPage + 3);
        } else {
          return pagesArr.slice(0, currentPage + 3);
        }
      };

      return (
        <Fragment>
          {currentPage > 2 && (
            <button onClick={() => onPageChange(0)}>1</button>
          )}
          {currentPage > 3 && <span>...</span>}
          {middlePages().map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => onPageChange(pageNum - 1)}
              className={classnames({
                [styles["active"]]: pageNum === currentPage + 1,
              })}
            >
              {pageNum}
            </button>
          ))}
          {currentPage < totalPages - 3 && <span>...</span>}
          {currentPage < totalPages - 3 && (
            <button onClick={() => onPageChange(totalPages - 1)}>
              {totalPages}
            </button>
          )}
        </Fragment>
      );
    }
  };

  return (
    <div className={styles["pagination-bttns"]}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={!currentPage}
      >
        {"<"} Prev
      </button>
      {totalPages ? pagesNums() : null}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
      >
        Next {">"}
      </button>
    </div>
  );
};
