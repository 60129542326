import { Dialog, DialogBody, DialogHeader } from "@faktoring/ui";
import i18next from "i18next";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { localizedTime } from "../../pages/users/users";
import { Payment } from "../invoice-payment-dialog/invoice-payment-dialog";
import styles from "./invoice-payments-preview.module.scss";

export const formatNumber = (number: string | number) => {
  return Number(number).toLocaleString(`${i18next.t("date")}`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

interface IInvoicePaymentsPreviewProps {
  paymentsData: Payment[];
  open: boolean;
  onClose: () => void;
}

export const InvoicePaymentsPreview: FC<IInvoicePaymentsPreviewProps> = ({
  paymentsData,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogHeader>{t("dialog.requests.payments")}</DialogHeader>

        <DialogBody className={styles["invoice-payments-preview"]}>
          {paymentsData &&
            paymentsData
              ?.slice()
              .sort(
                (a: Payment, b: Payment) =>
                  new Date(a.paymentDate).getTime() -
                  new Date(b.paymentDate).getTime()
              )
              .map((payment: Payment, index: number) => (
                <div
                  key={index}
                  className={styles["invoice-payments-preview__payment"]}
                >
                  <p>
                    <b>{t("dialog.requests.paymentDatePerInvoice")}</b>:{" "}
                    {localizedTime(payment.paymentDate)}
                  </p>
                  <p>
                    <b>{t("dialog.requests.paymentAmount")}</b>:{" "}
                    {formatNumber(payment.amount)}
                  </p>
                  {payment.comment && (
                    <p>
                      <b>{t("dialog.requests.comment")}</b>: {payment.comment}
                    </p>
                  )}
                </div>
              ))}
        </DialogBody>
      </Dialog>
    </>
  );
};
