import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Roles } from "../../state/auth/auth-params";
import { selectIsLoggedIn, setUser } from "../../state/auth/auth.slice";
import { useUserQuery } from "../../state/user/users-api.service";

export const LandingPage: FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { data } = useUserQuery(null);

  useEffect(() => {
    dispatch(setUser(data));

    if (!isLoggedIn) {
      navigate("/login");
    }

    if (data) {
      navigate(`/${data.role === Roles.Client ? 'client' : 'admin'}/requests`)
    }
  }, [data]);

  return <></>;
};
