import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { srNames } from "./sr";

const resources = {
  sr: {
    translation: srNames,
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "sr",
  interpolation: {
    escapeValue: false,
  },
  debug: true,
});
export default i18next;
