import { FC, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetTokenMutation } from "../../state/auth/auth.service";
import {
  setIsLoggedIn,
  setRefreshToken,
  setToken,
} from "../../state/auth/auth.slice";

export const LoginCallback: FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const didLogRef = useRef<boolean>(false);

  const [getToken] = useGetTokenMutation();

  useEffect(() => {
    if (!didLogRef.current) {
      didLogRef.current = true;

      getToken(null)
        .unwrap()
        .then((res) => {
          dispatch(setToken(res.access_token));
          dispatch(setRefreshToken(res.refresh_token));
          dispatch(setIsLoggedIn(true));
        })
        .then(() => navigate("/"))
        .catch(console.error);
    }
  }, [dispatch, getToken, navigate]);

  return <></>;
};
