import { DatePick, DialogBody, InputField, SelectField } from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Buyer } from "../../state/buyers/buyer.class";
import {
  ContractStatus,
  ContractType,
} from "../../state/contracts/contracts.class";
import { User } from "../../state/user/user.class";
import { Request } from "../../state/requests/requests.class";
import { validateFieldRequired } from "../new-request/step-utils";
import styles from "./new-contract.module.scss";

interface IStepTwoProps {
  form: any;
  allUsers: { content: User[] };
  allBuyers: { content: Buyer[] };
  requestData: Request;
}

export const StepTwoContractData: FC<IStepTwoProps> = ({
  form,
  allUsers,
  allBuyers,
  requestData,
}) => {
  const { t } = useTranslation();

  const usersData = allUsers?.content.find(
    (user: User) => user.id === Number(form.getState().values.user.id)
  );

  const buyersData = allBuyers?.content.find(
    (buyer: Buyer) => buyer.id === Number(form.getState().values.buyer.id)
  );

  const labelMapping = {
    [ContractStatus.InPreparation]: t("dialog.contracts.status.inPreparation"),
    [ContractStatus.Expired]: t("dialog.contracts.status.expired"),
    [ContractStatus.Valid]: t("dialog.contracts.status.valid"),
  };

  const nextYearDate = new Date()
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

  return (
    <DialogBody
      className={classnames(
        styles["new-contract__body"],
        styles["new-contract__dialog-body-step-one"]
      )}
    >
      <div className={styles["new-contract__fields"]}>
        <Field
          name="contractNumber"
          type="text"
          validate={validateFieldRequired}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  className={styles["new-contract__field"]}
                  label={`${t("dialog.contracts.contractNumber")}*` || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="startDate"
          type="text"
          initialValue={new Date()}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <DatePick
                  icon={<IconMemo name="Datum-dospeca" />}
                  label={t("dialog.contracts.dateCreated") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        {form.getState().values.contractType === ContractType.LongTerm && (
          <Field
            name="endDate"
            initialValue={nextYearDate}
            type="text"
            render={({ input, meta }) => {
              const isError = meta.touched && meta.error;

              return (
                <div className={styles["new-contract__field"]}>
                  <DatePick
                    icon={<IconMemo name="Datum-dospeca" />}
                    label={t("dialog.contracts.endDate") || ""}
                    {...input}
                  />
                  <div className={styles["new-contract__field--validation"]}>
                    <div>{isError && meta.error}</div>
                  </div>
                </div>
              );
            }}
          />
        )}

        <Field
          name="status"
          type="text"
          initialValue={ContractStatus.Valid}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <SelectField
                  label={t("dialog.contracts.status.name") || ""}
                  placeholder={t("dialog.contracts.status.name") || ""}
                  options={Object.keys(ContractStatus).map((key) => ({
                    value: ContractStatus[key as keyof typeof ContractStatus],
                    label:
                      labelMapping[
                        ContractStatus[key as keyof typeof ContractStatus]
                      ],
                  }))}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        {form.getState().values.contractType === ContractType.LongTerm && (
          <>
            <Field
              name="contractLimit"
              type="number"
              render={({ input, meta }) => {
                const isError = meta.touched && meta.error;

                return (
                  <div className={styles["new-contract__field"]}>
                    <InputField
                      type="number"
                      className={styles["new-contract__field"]}
                      label={`${t("dialog.users.limit")}`}
                      {...input}
                    />
                    <div className={styles["new-contract__field--validation"]}>
                      <div>{isError && meta.error}</div>
                    </div>
                  </div>
                );
              }}
            />

            <Field
              name="contractLimitText"
              type="text"
              render={({ input, meta }) => {
                const isError = meta.touched && meta.error;

                return (
                  <div className={styles["new-contract__field"]}>
                    <InputField
                      type="text"
                      className={styles["new-contract__field"]}
                      label={`${t("dialog.contracts.limitWritten")}`}
                      {...input}
                    />
                    <div className={styles["new-contract__field--validation"]}>
                      <div>{isError && meta.error}</div>
                    </div>
                  </div>
                );
              }}
            />
          </>
        )}

        <Field
          name="retainedAdvancePercent"
          initialValue={
            form.getState().values.contractType === ContractType.LongTerm
              ? usersData?.retainedAdvancePercent
              : requestData?.paymentData.retainedAdvancePercent
          }
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  step="any"
                  className={styles["new-contract__field"]}
                  label={`${t("dialog.users.retainedAdvancePercent")} (%)`}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="appFeePercent"
          initialValue={
            form.getState().values.contractType === ContractType.OneTime
              ? requestData?.paymentData.appFeePercent
              : undefined
          }
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  step="any"
                  className={styles["new-contract__field"]}
                  label={`${t("dialog.requests.appFeePercent")} (%)`}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="monthlyInterestRate"
          initialValue={
            form.getState().values.contractType === ContractType.LongTerm
              ? usersData?.monthlyInterestRatePercent
              : requestData?.paymentData.monthlyInterestRatePercent
          }
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  step="any"
                  className={styles["new-contract__field"]}
                  label={`${t(
                    "dialog.requests.monthlyInterestRatePercent"
                  )} (%)`}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="promissoryNotes"
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={`${t("table.users.promissoryNoteCount")}`}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>

      <h4>{t("dialog.contracts.usersData")}</h4>
      <div className={styles["new-contract__fields"]}>
        <Field
          name="user.id"
          type="text"
          initialValue={usersData && usersData.id}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__hidden-field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.companyName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
        <Field
          name="user.companyName"
          type="text"
          initialValue={usersData && usersData.companyName}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.companyName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.zipCode"
          initialValue={usersData && usersData.zipCode}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.zipCode") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.city"
          initialValue={usersData && usersData.city}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.city") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.street"
          initialValue={usersData && usersData.street}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.street") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.doorNumber"
          initialValue={usersData && usersData.doorNumber}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.doorNumber") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.phoneNumber"
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.phone") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.companyRegistrationNumber"
          initialValue={usersData && usersData.registrationNumber}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.buyers.registrationNumber") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.companyPib"
          type="number"
          initialValue={usersData && usersData.companyPib}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("table.buyers.pib") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.accountNumber"
          initialValue={usersData && usersData.accountNumber}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.buyers.bankAccount") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.representativeFirstName"
          initialValue={usersData && usersData.representative.firstName}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.representativeLastName"
          initialValue={usersData && usersData.representative.lastName}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeLastname") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.representativeJmbg"
          initialValue={usersData && usersData.representative.jmbg}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeJMBG") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="user.representativeType"
          initialValue={usersData && usersData.representative.type}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeFunction") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>

      <h4>{t("dialog.contracts.buyersData")}</h4>
      <div className={styles["new-contract__fields"]}>
        <Field
          name="buyer.id"
          type="text"
          initialValue={buyersData && buyersData.id}
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__hidden-field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.companyName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.companyName"
          initialValue={buyersData?.companyName}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.companyName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.zipCode"
          initialValue={buyersData?.zipCode}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.zipCode") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.city"
          initialValue={buyersData?.city}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.city") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.street"
          initialValue={buyersData?.street}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.street") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.doorNumber"
          initialValue={buyersData?.doorNumber}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.doorNumber") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.phoneNumber"
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.users.phone") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.companyRegistrationNumber"
          initialValue={buyersData?.registrationNumber}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.buyers.registrationNumber") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.companyPib"
          initialValue={buyersData?.companyPib}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("table.buyers.pib") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.accountNumber"
          initialValue={buyersData?.accountNumber}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.buyers.bankAccount") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.representativeFirstName"
          initialValue={buyersData?.representative.firstName}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeName") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.representativeLastName"
          initialValue={buyersData?.representative.lastName}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeLastname") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.representativeJmbg"
          initialValue={buyersData?.representative.jmbg}
          type="number"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="number"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeJMBG") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />

        <Field
          name="buyer.representativeType"
          initialValue={buyersData?.representative.type}
          type="text"
          render={({ input, meta }) => {
            const isError = meta.touched && meta.error;

            return (
              <div className={styles["new-contract__field"]}>
                <InputField
                  type="text"
                  className={styles["new-contract__field"]}
                  label={t("dialog.contracts.representativeFunction") || ""}
                  {...input}
                />
                <div className={styles["new-contract__field--validation"]}>
                  <div>{isError && meta.error}</div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </DialogBody>
  );
};

const IconMemo = React.memo(Icon);
