import { createBrowserRouter } from "react-router-dom";
import { Admin } from "../pages/admin/admin";
import { Buyers } from "../pages/buyers/buyers";
import { Client } from "../pages/client/client";
import { LandingPage } from "../pages/landing/landing";
import { LoginPage } from "../pages/login/login";
import { LoginCallback } from "../pages/login/login-callback";
import { Logout } from "../pages/logout/logout";
import { NotFoundPage } from "../pages/not-found/not-found";
import { Register } from "../pages/register/register";
import { Reports } from "../pages/reports/reports";
import { AdminRequests } from "../pages/requests/admin-requests";
import { ClientRequests } from "../pages/requests/client-requests";
import { Users } from "../pages/users/users";
import { PrivateRoute } from "./private-route";
import { Contracts } from "../pages/contracts/contracts";
import { Payments } from "../pages/payments/payments";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/client",
    element: (
      <PrivateRoute>
        <Client />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/client/requests",
        element: (
          <PrivateRoute>
            <ClientRequests />
          </PrivateRoute>
        ),
      },
      {
        path: "/client/buyers",
        element: (
          <PrivateRoute>
            <Buyers />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <Admin />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/admin/requests",
        element: (
          <PrivateRoute>
            <AdminRequests />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/buyers",
        element: (
          <PrivateRoute>
            <Buyers />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/users",
        element: (
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/reports",
        element: (
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/contracts",
        element: (
          <PrivateRoute>
            <Contracts />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/payments",
        element: (
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/login_callback",
    element: <LoginCallback />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
]);
