import { FC, ReactNode, useContext } from "react";
import { createPortal } from "react-dom";
import { HeaderPortalContext } from "./header";

interface IHeaderPortalProps {
  children: ReactNode;
}

export const HeaderPortal: FC<IHeaderPortalProps> = ({ children }) => {
  const portalContext = useContext(HeaderPortalContext);

  return portalContext && portalContext.portalEl
    ? createPortal(children, portalContext.portalEl)
    : null;
};
