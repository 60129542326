import { classnames } from "@faktoring/util";
import { ChangeEvent, FC, ReactNode } from "react";
import styles from "./file-upload.module.scss";

// TODO this interface should extend InputHTMLAttributes but the value type is conflicting
interface IFiledUploadProps {
  children: ReactNode;
  label: string;
  className?: string;
  onChange: (value: any) => void;
  value?: File[];
  multiple?: boolean;
  accept?: string;
  push?: (...args: File[]) => void;
}

const FileUpload: FC<IFiledUploadProps> = ({
  className,
  children,
  label,
  onChange,
  value,
  multiple = false,
  accept = "*",
  push,
  ...props
}) => {
  const classes = classnames(className, styles["file-upload"]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) return;

    for (let i = 0; i < event.target.files.length; i += 1) {
      onChange(event.target.files[i]);
    }
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (accept !== "*") {
      // TODO handle more complex "accept" strings

      const fileList = event.dataTransfer.files || [];
      const mimeParts = accept.split("/");
      if (mimeParts.length === 2) {
        for (let i = 0; i < fileList.length; i += 1) {
          const fileMimeParts = fileList[i].type.split("/");
          if (
            ((mimeParts[0] === "*" || mimeParts[0] === fileMimeParts[0]) &&
              mimeParts[1] === "*") ||
            mimeParts[1] === fileMimeParts[1]
          ) {
            onChange(fileList[i]);
          }
        }
      }
    } else {
      onChange(event.dataTransfer.files);
    }
    event.preventDefault();
  };

  const handleOnDragOver = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <div
      className={classes}
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
    >
      <div className={styles["file-upload__content"]}>

        <div className={styles["file-upload__text-box"]}>
          {children}
          <label className={styles["file-upload__input"]}>
            {label}
            <input
              accept={accept}
              {...props}
              name="files"
              multiple={multiple}
              type="file"
              hidden
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export { type IFiledUploadProps, FileUpload };
