import { FileList, FileUpload } from '@faktoring/ui';
import { FormApi } from 'final-form';
import { FC, useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import styles from './new-invoice-step.module.scss';

interface IStepTwoNewInvoiceProps {
  push: (arg1: string, arg2: File[]) => void;
  newRequestForm?: FormApi;
  selectedIndex?: number;
}

export const StepTwoFiles: FC<IStepTwoNewInvoiceProps> = ({
  push,
  newRequestForm,
  selectedIndex,
}) => {
  const handleFiles = (files: File[]) => push('files', files);
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(undefined);

  const invoices = newRequestForm?.getFieldState('invoices');

  useEffect(() => {
    const initialValues =
      selectedIndex !== undefined ? invoices?.value[selectedIndex].files : [];

    setInitialValues(initialValues);
  }, [invoices?.value, selectedIndex]);

  return (
    <FieldArray
      initialValue={initialValues}
      validate={(value) => {
        if (!value || !value.length) {
          return t('warnings.minOneDocIsRequired');
        }

        const twoMB = 2000000;

        let toLargeSizeAlert: string[] = [];
        value.forEach((el) => {
          console.log('el', el);
          if (el.size > twoMB) {
            toLargeSizeAlert.push(el.name);
          }
        });

        return toLargeSizeAlert.length
          ? ` ${t('warnings.toBigFileSize')} - ${toLargeSizeAlert.join(' , ')}`
          : undefined;
      }}
      name="files">
      {({ fields, meta }) => {
        const isError = meta.touched && meta.error;

        return (
          <div className={styles['new-invoice__step-container']}>
            <div className={styles['new-invoice__field']}>
              <FileUpload
                push={fields.push}
                onChange={handleFiles}
                value={fields.value}
                label={`${t('dialog.requests.cta.clickHere')}`}
                multiple
                accept="image/*, application/pdf">
                <div className={styles['new-invoice__file-upload-content']}>
                  {t('dialog.requests.docFormats')} <br />
                </div>
              </FileUpload>
              <br />
              {fields?.value?.length > 0 && (
                <FileList
                  onRemoveElement={fields.remove}
                  files={fields.value}
                />
              )}
              <p className={styles['new-invoice__field--information']}>
                <b> {t('dialog.requests.fileSizeLimitInfo')} </b>
              </p>
              <div className={styles['new-invoice__field--validation']}>
                <div>{isError && meta.error}</div>
              </div>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
