import { classnames } from "@faktoring/util";
import { FC, ReactNode } from "react";
import styles from "./info-box.module.scss";

interface IInfoBoxProps {
  children: ReactNode;
  className?: string;
  title: string;
  errorMessage?: boolean;
}

const InfoBox: FC<IInfoBoxProps> = ({
  className,
  title,
  children,
  errorMessage,
  ...props
}) => {
  const classes = classnames(className, styles["info-box"], {
    [styles["info-box__error"]]:
      errorMessage === true,
  });

  return (
    <div className={classes} {...props}>
      <div
        className={classnames(styles["info-box__left-border"], {
          [styles["info-box__left-border-error"]]:
            errorMessage === true,
        })}
      />
      <h4> {title} </h4>
      <div
        className={classnames(styles["info-box__content"], {
          [styles["info-box__content-error"]]:
            errorMessage === true,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { type IInfoBoxProps, InfoBox };
