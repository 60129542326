import {
  DropDown,
  FloatButton,
  SearchBar,
  Spinner,
  Status,
  Table,
} from "@faktoring/ui";
import { Icon } from "@faktoring/ui/src/components/icon/icon";
import { classnames } from "@faktoring/util";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClientRequestPreview } from "../../components/client-request-preview/client-request-preview";
import { HeaderPortal } from "../../components/header/header-portal";
import {
  changeRequestFilteredStatus,
  changeRequestsSearchedValue,
  changeRequestsSortDirection,
  changeRequestsSortField,
  openRequestPreview,
  selectDialogState,
  useRequestPreviewState,
} from "../../state/requests/request.slice";
import { useRequestsQuery } from "../../state/requests/requests-api.service";
import { Request } from "../../state/requests/requests.class";
import {
  onPageChange,
  openRequestDialog,
  selectRequestState,
  selectRequestsPageOptions,
  setRequestsData,
} from "../../state/requests/requests.slice";
import { localizedCurrency, localizedTime } from "../users/users";
import styles from "./requests.module.scss";

interface IDropDownElement {
  isOpen: boolean;
  actions: {
    icon: ReactElement;
    name: string;
    handleClick: (value: string) => void;
  }[];
}

export const ClientRequests: FC<{}> = () => {
  const dispatch = useDispatch();
  const [dropDownData, setDropDownData] = useState<IDropDownElement[] | null>(
    null
  );
  const { pageSize, page } = useSelector(
    selectRequestsPageOptions
  );
  const { isAddedNewRequestOnBeginning } = useSelector(selectRequestState)
  const {
    requestFilteredStatus,
    requestsSortField: sortField,
    requestsSortDirection: sortDirection,
    requestsSearchedValue,
  } = useSelector(selectDialogState);
  const { data, refetch, isFetching } = useRequestsQuery(
    {
      page,
      pageSize,
      searchedValue: requestsSearchedValue,
      sortField,
      sortDirection,
      status: requestFilteredStatus,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const { t } = useTranslation();

  const actions = [
    {
      icon: <span className={styles["request__action-icon"]}><Icon name="Detalj" /></span>,
      name: t("table.actions.details"),
      handleClick: (value: string) => dispatch(openRequestPreview(value)),
    },
  ];

  useEffect(() => {
    dispatch(changeRequestFilteredStatus(""))
  }, [])

  useEffect(() => {
    dispatch(onPageChange(0));
    refetch();
  }, [requestsSearchedValue, requestFilteredStatus]);

  useEffect(() => {
    refetch();
    dispatch(setRequestsData(data));
  }, [isStatusChanged]);

  useEffect(() => {
    const tableRowsNumber = data?.content.length;

    if (!tableRowsNumber) return;

    const rowsButtonData = [];
    for (let i = 0; i < tableRowsNumber; i++) {
      rowsButtonData.push({ isOpen: false, actions });
    }

    setDropDownData(rowsButtonData);
    dispatch(setRequestsData(data));
  }, [data]);

  const columnsDefinitionRequests = [
    {
      header: t("table.requests.idNumber"),
      field: (row: Request) => row.id,
    },
    {
      header: (
        <div className={styles["requests__th"]}>
          <div className={styles["requests__th-buttons-wrapper"]}>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "buyerCompanyName"
                ) {
                  dispatch(changeRequestsSortField("buyerCompanyName"));
                  dispatch(changeRequestsSortDirection("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "buyerCompanyName"
                ) {
                  dispatch(changeRequestsSortField("buyerCompanyName"));
                  dispatch(changeRequestsSortDirection("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <span>{t("table.requests.buyer")}</span>
        </div>
      ),
      field: (row: Request) => row.buyerCompanyName,
    },
    {
      header: (
        <div
          className={classnames(
            styles["requests__th"],
            styles["requests__text-align-right"]
          )}
        >
          <div className={styles["requests__th-buttons-wrapper"]}>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "amount") {
                  dispatch(changeRequestsSortField("amount"));
                  dispatch(changeRequestsSortDirection("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "amount") {
                  dispatch(changeRequestsSortField("amount"));
                  dispatch(changeRequestsSortDirection("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["requests__text-align-right"]}>
            {t("table.requests.requestedAmount")}
          </p>
        </div>
      ),
      field: (row: Request) => (
        <p className={styles["requests__text-align-right"]}>
          {row.paymentData.amount && localizedCurrency(row.paymentData.amount)}
        </p>
      ),
    },
    {
      header: (
        <div
          className={classnames(
            styles["requests__th"],
            styles["requests__text-align-right"]
          )}
        >
          <div className={styles["requests__th-buttons-wrapper"]}>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (sortDirection === "desc" || sortField !== "dateCreated") {
                  dispatch(changeRequestsSortField("dateCreated"));
                  dispatch(changeRequestsSortDirection("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (sortDirection === "asc" || sortField !== "dateCreated") {
                  dispatch(changeRequestsSortField("dateCreated"));
                  dispatch(changeRequestsSortDirection("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["requests__text-align-right"]}>
            {t("table.requests.paymentDate")}
          </p>
        </div>
      ),
      field: (row: Request) => (
        <p className={styles["requests__text-align-right"]}>
          {row.dateCreated && localizedTime(row.dateCreated)}
        </p>
      ),
    },
    {
      header: t("table.requests.reqStatus.name"),
      field: (row: Request) => <Status title={row.status} />,
    },
    {
      header: (
        <div
          className={classnames(
            styles["requests__th"],
            styles["requests__text-align-right"]
          )}
        >
          <div className={styles["requests__th-buttons-wrapper"]}>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "lastStatusChangeDate"
                ) {
                  dispatch(changeRequestsSortField("lastStatusChangeDate"));
                  dispatch(changeRequestsSortDirection("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["requests__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "lastStatusChangeDate"
                ) {
                  dispatch(changeRequestsSortField("lastStatusChangeDate"));
                  dispatch(changeRequestsSortDirection("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["requests__text-align-right"]}>
            {t("table.requests.lastChangeDate")}
          </p>
        </div>
      ),
      field: (row: Request) => (
        <p className={styles["requests__text-align-right"]}>
          {row.lastStatusChangeDate && localizedTime(row.lastStatusChangeDate)}
        </p>
      ),
    },
    {
      header: "",
      field: (_row: Request, index: number) => (
        <DropDown
          id={_row.id.toString()}
          index={index}
          setDropDownData={setDropDownData}
          dropDownData={dropDownData}
        />
      ),
    },
  ];

  return (
    <>
      <div className={styles["requests"]}>
        <SearchBar
          onSearchedValueChange={(value) =>
            dispatch(changeRequestsSearchedValue(value))
          }
        />
        {isFetching ? (
          <div className={styles["requests__spinner"]}>
            <Spinner />
          </div>
        ) : (
          <Table
            isAdded={isAddedNewRequestOnBeginning}
            data={data}
            currentPage={page}
            onPageChange={(value) => dispatch(onPageChange(value))}
            columns={columnsDefinitionRequests}
          />
        )}
      </div>
      <CreateNewRequest />
      <ClientRequestPreview
        {...useRequestPreviewState()}
        onStatusChange={setIsStatusChanged}
      />
    </>
  );
};

const CreateNewRequest = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <HeaderPortal>
        <FloatButton
          color="blue"
          onClick={() => dispatch(openRequestDialog())}
          className={styles["header-portal__main-button"]}
        >
          <Icon name="Novi-zahtev" />
          {t("cta.createNewRequest")}
        </FloatButton>
      </HeaderPortal>
    </>
  );
});

const IconMemo = React.memo(() => {
  return <Icon name="down" />;
});
