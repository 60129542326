import { classnames } from "@faktoring/util";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Buyer } from "../../../../faktoring-client/src/state/buyers/buyer.class";
import { Contract } from "../../../../faktoring-client/src/state/contracts/contracts.class";
import { PaginationResult } from "../../../../faktoring-client/src/state/pagination-types";
import { Request } from "../../../../faktoring-client/src/state/requests/requests.class";
import { selectRequestState } from "../../../../faktoring-client/src/state/requests/requests.slice";
import { User } from "../../../../faktoring-client/src/state/user/user.class";
import { Pagination } from "../pagination/pagination";
import { ReportByRequests } from "../../../../faktoring-client/src/state/reports/reports.class";
import styles from "./table.module.scss";

interface ITableProps {
  isAdded: boolean;
  data:
    | PaginationResult<Request>
    | PaginationResult<Buyer>
    | PaginationResult<User>
    | { content: ReportByRequests[], totalPages?: 0 }
    | { content: Contract[], totalPages?: 0 };
  currentPage: number;
  onPageChange: (page: number) => void;
  columns: any[];
  className?: string;
}

export const Table: FC<ITableProps> = ({
  data,
  currentPage,
  onPageChange,
  columns,
  className,
  isAdded,
}) => {
  const { isAddedNewRequestOnBeginning, isAddedNewRequestOnEnd } =
    useSelector(selectRequestState);

  return (
    <div className={styles["table-wrapper"]}>
      <table className={styles["table"]}>
        <thead className={styles["table__head"]}>
          <tr>
            {columns.map((c, i) => {
              return (
                <th className={styles["table__head-element"]} key={i}>
                  {c.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles["table__body"]}>
          {data.content &&
            data.content.map((row: any, id: number) => {
              const isLastElement = data.content.length - 1 === id;
              const isFirstElement = id === 0;

              const classesTableBodyRow = classnames(
                className,
                styles["table__body-row"],
                {
                  [styles["table__body-row--new-buyer"]]:
                    isAdded && isAddedNewRequestOnEnd && isLastElement,
                  [styles["table__body-row--new-buyer"]]:
                    isAdded && isAddedNewRequestOnBeginning && isFirstElement,
                }
              );

              return (
                <tr key={id} className={classesTableBodyRow}>
                  {columns.map((c, i) => (
                    <td key={i} className={styles["table__body-element"]}>
                      {c.field(row, id)}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
      {data.totalPages && (
        <Pagination
          totalPages={data.totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};
