import {
  DatePick,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
} from "@faktoring/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectDialogState } from "../../state/requests/request.slice";
import { Request } from "../../state/requests/requests.class";
import { selectRequestState } from "../../state/requests/requests.slice";
import { Field, Form } from "react-final-form";
import { useSetPaymentPerInvoiceMutation } from "../../state/requests/requests-api.service";
import { onChangeRequestData } from "../../state/requests/total-request.slice";
import i18next from "i18next";
import styles from "./invoice-payment-dialog.module.scss";

interface IInvoicePaymentProps {
  invoiceIndex: number;
  open: boolean;
  onClose: () => void;
}

export interface Payment {
  paymentDate: string;
  amount: number;
  comment: string;
}

export const formatNumber = (number: string | number) => {
  return Number(number).toLocaleString(`${i18next.t("date")}`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const InvoicePaymentDialog: FC<IInvoicePaymentProps> = ({
  invoiceIndex,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { requestsData: data } = useSelector(selectRequestState);
  const { requestPreviewId } = useSelector(selectDialogState);
  const [setPaymentPerInvoice] = useSetPaymentPerInvoiceMutation();
  const { t } = useTranslation();

  const invoiceData = data?.content.find(
    (request: Request) => request.id === Number(requestPreviewId)
  )?.invoices[invoiceIndex];

  const onSubmit = async (values: Payment) => {
    if (!data) return;

    const invoicePaymentValues = { ...values, amount: Number(values.amount), invoiceId: invoiceData?.id };

    await setPaymentPerInvoice(invoicePaymentValues);

    const refreshReqData = () => {
      dispatch(onChangeRequestData(true));

      setTimeout(() => {
        dispatch(onChangeRequestData(false));
      }, 500);
    };

    refreshReqData()
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog open={open} onClose={closeAndReset}>
            <DialogHeader>
              {t("dialog.requests.invoice")}: {invoiceData?.invoiceNumber}
            </DialogHeader>

            <DialogBody className={styles["invoice-preview"]}>
              <div className={styles["invoice-preview__calculation"]}>
                <Field
                  name="paymentDate"
                  validate={(value) => {if (!value) {
                    return t("warnings.requiredField");
                  }}}
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["invoice-payment__body-field"]}>
                        <DatePick
                          {...input}
                          label={
                            t("dialog.requests.paymentDatePerInvoice") || ""
                          }
                          className={styles["invoice-payment__date-picker-field"]}
                        />
                        <div
                          className={
                            styles["invoice-payment__body-field--validation"]
                          }
                        >
                          {isError && meta.error}
                        </div>
                      </div>
                    );
                  }}
                />
                <Field
                  name="amount"
                  validate={(value) => {if (!value) {
                    return t("warnings.requiredField");
                  }}}
                  type="number"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["invoice-payment__body-field"]}>
                        <InputField
                          type="number"
                          label={`${t("dialog.requests.paymentAmount")}`}
                          {...input}
                        />
                        <div
                          className={
                            styles["invoice-payment__body-field--validation"]
                          }
                        >
                          {isError && meta.error}
                        </div>
                      </div>
                    );
                  }}
                />
                <Field
                  name="comment"
                  type="text"
                  render={({ input, meta }) => {
                    const isError = meta.touched && meta.error;

                    return (
                      <div className={styles["invoice-payment__body-field"]}>
                        <InputField
                          {...input}
                          min="0"
                          label={`${t("dialog.requests.comment")}`}
                        />
                        <div
                          className={
                            styles["invoice-preview__body-field--validation"]
                          }
                        >
                          {isError && meta.error}
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </DialogBody>

            <DialogActions className={styles["invoice-payment__dialog-actions"]}>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>
              <FlatButton
                onClick={async () => {
                  await form.submit();
                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  closeAndReset();
                }}
                type="button"
                color="blue"
                disabled={!form.getState().valid}
              >
                {t("dialog.users.saveChanges")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
