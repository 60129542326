import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
} from "@faktoring/ui";
import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDialogState } from "../../state/requests/request.slice";
import { useAllRequestsQuery, useUpdateRequestStatusMutation } from "../../state/requests/requests-api.service";
import { selectRequestsPageOptions } from "../../state/requests/requests.slice";
import styles from "./request-message.module.scss";

interface IRequestMessageProps {
  open: boolean;
  onClose: () => void;
}

export const RequestMessageDialog: FC<IRequestMessageProps> = ({
  open,
  onClose
}) => {
  const [updateRequest] = useUpdateRequestStatusMutation();
  const { pageSize, page } = useSelector(selectRequestsPageOptions);
  const {
    data: requestData,
    refetch: refetchData,
  } = useAllRequestsQuery({
    page,
    pageSize,
  });
  const { requestPreviewId } = useSelector(selectDialogState);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const onSubmit = async () => {
    if (requestPreviewId) {
      await updateRequest({
        requestId: +requestPreviewId,
        requestStatus: RequestStatus.Created,
        adminComment: message
      });
    }

    await refetchData()
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ form }) => {
        const closeAndReset = () => {
          form.restart();
          setMessage('')
          onClose();
        };

        return (
          <Dialog open={open} onClose={closeAndReset}>
            <DialogHeader>
              
              {t("dialog.requests.messageToClient")}
            </DialogHeader>
            <DialogBody>
              <Field
                name="message"
                type="text"
                validate={(value) => {
                  if (value?.length > 255) {
                    return t("warnings.messageLengthOverMax");
                  }
                }}
                render={({ input, meta }) => {
                  const isError = meta.touched && meta.error;

                  return (
                    <div className={styles["request-message"]}>
                      <textarea
                        {...input}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
                        value={message}
                        maxLength={255}
                        className={styles["request-message__field-text-box"]}
                      />
                      <div className={styles["request-message__field-validation"]}>
                        {isError && meta.error}
                      </div>
                    </div>
                  );
                }}
              />
            </DialogBody>

            <DialogActions className={styles["request-message__field-buttons-wrapper"]}>
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>

              <FlatButton
                onClick={async () => {
                  await form.submit()
                  closeAndReset();
                }}
                type="button"
                color="blue"
                disabled={!message}
              >
                {t("dialog.requests.cta.send")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
