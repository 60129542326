import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../store";
import { initialRequest } from "./requests.class";

const requestsSlice = createSlice({
  name: "requests",
  initialState: {
    isRequestDialogOpen: false,
    isAddedNewRequestOnBeginning: false,
    isAddedNewRequestOnEnd: false,
    pageSize: 12,
    page: 0,
    requestsData: {
      content: [initialRequest],
      totalElements: 0,
      totalPages: 0,
      pageSize: 0,
      pageNumber: 0,
      sortDirection: "",
      sortField: ""
    }
  },
  reducers: {
    openRequestDialog: (state) => {
      state.isRequestDialogOpen = true;
    },

    closeRequestDialog: (state) => {
      state.isRequestDialogOpen = false;
    },

    onPageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    onAddNewRequestOnBeginning: (state, action: PayloadAction<boolean>) => {
      state.isAddedNewRequestOnBeginning = action.payload;
    },
    onAddNewRequestOnEnd: (state, action: PayloadAction<boolean>) => {
      state.isAddedNewRequestOnEnd = action.payload;
    },
    setRequestsData: (state,  action: PayloadAction<any>) => {
      state.requestsData = action.payload
    }
  },
});

export default requestsSlice;

export const selectRequestState = (state: ReturnType<typeof store.getState>) => ({
  isRequestDialogOpen: state.requests.isRequestDialogOpen,
  requestsData: state.requests.requestsData,
  isAddedNewRequestOnBeginning: state.requests.isAddedNewRequestOnBeginning,
  isAddedNewRequestOnEnd: state.requests.isAddedNewRequestOnEnd,
});

export const useRequestsDialogState = () => {
  const state = useSelector(selectRequestState);
  const dispatch = useDispatch();
  return {
    open: state.isRequestDialogOpen,
    onClose: () => dispatch(closeRequestDialog()),
  };
};

export const selectRequestsPageOptions = (state: ReturnType<typeof store.getState>) => ({
  page: state.requests.page,
  pageSize: state.requests.pageSize,
});

export const {
  onAddNewRequestOnBeginning,
  onAddNewRequestOnEnd,
  onPageChange,
  openRequestDialog,
  closeRequestDialog,
  setRequestsData
} = requestsSlice.actions;
