import { classnames } from "@faktoring/util";
import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import styles from "./float-button.module.scss";

interface IFloatButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  color?: "blue" | "orange";
}

export const FloatButton: FC<IFloatButtonProps> = ({
  className,
  color,
  ...props
}) => {
  const classes = classnames(className, styles["button__float"], {
    [styles["button__float--blue"]]: color === "blue",
    [styles["button__float--orange"]]: color === "orange",
  });

  return <button className={classes} {...props}></button>;
};
