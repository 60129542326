import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InputField,
  Spinner,
} from "@faktoring/ui";
import arrayMutators from "final-form-arrays";
import { FC, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  useDownloadFileMutation,
  useUploadFilesMutation,
} from "../../state/requests/requests-api.service";
import {
  useDeleteUsersFileMutation,
  useUpdateUsersCompanyDataMutation,
  useUpdateUsersFilesMutation,
  useUserQuery,
} from "../../state/user/users-api.service";
import styles from "./company-data-settings.module.scss";

interface IAccountSettings {
  open: boolean;
  onClose: () => void;
}

interface IFiles {
  name: string;
  type: string;
  fileReference: string;
}

const emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRgx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;

export const CompanyDataSettingsDialog: FC<IAccountSettings> = ({
  open,
  onClose,
}) => {
  const { data, refetch, isFetching } = useUserQuery(null);
  const [uploadFiles] = useUploadFilesMutation();
  const [updateUsersFiles] = useUpdateUsersFilesMutation();
  const [downloadFile] = useDownloadFileMutation();
  const [deleteUsersFile] = useDeleteUsersFileMutation();
  const [updateUsersCompanyData] = useUpdateUsersCompanyDataMutation();
  const { t } = useTranslation();

  const validateFieldRequired = (value: string) => {
    if (!value) {
      return t("warnings.requiredField");
    }
  };

  useEffect(() => {
    refetch()
  }, [open])
  
  const onSubmit = async (values: any) => {
    if (!values) return;

    updateUsersCompanyData(values);

    // TODO add file upload handler if needed
    // const files = values.files;
    // const formData = new FormData();

    // if (files) {
    //   await Promise.all(
    //     files.map((file: File) => formData.append("files", file))
    //   );
    // }

    // await uploadFiles(formData)
    //   .unwrap()
    //   .then((data) => updateUsersFiles(data.files))
    //   .then(() => refetch());
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={data}
      render={({
        form,
        form: {
          mutators: { push },
        },
        pristine,
      }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <Dialog
            open={open}
            onClose={closeAndReset}
            className={styles["company-data-settings"]}
          >
            <DialogHeader> {t("dialog.users.yoursCompanyData")} </DialogHeader>

            <DialogBody className={styles["company-data-settings__body"]}>
              <div className={styles["company-data-settings__body-fields-wrapper"]}>
                <div className={styles["company-data-settings__body-fields"]}>
                  <Field
                    name="companyPib"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 9) {
                        return t("warnings.pibMustHave9Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("table.buyers.pib")}`}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="registrationNumber"
                    type="number"
                    validate={(value) => {
                      if (!value) {
                        return t("warnings.requiredField");
                      }

                      if (value.length !== 8) {
                        return t("warnings.IDNumMustHave8Num");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                            min={0}
                            label={`${t("dialog.buyers.registrationNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyName"
                    type="text"
                    validate={validateFieldRequired}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.companyName")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="shortBusinessName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.shortCompanyName")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-settings__body-fields"]}>
                  <Field
                    name="zipCode"
                    type="number"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.zipCode")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="city"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.city")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="street"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.street")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="doorNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.doorNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-settings__body-fields"]}>
                  <Field
                    name="companyPhoneNumber"
                    type="tel"
                    validate={(value) => {
                      if (value && !value.match(phoneRgx)) {
                        return t("warnings.wrongPhoneNumber");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.requests.companyPhoneNumber")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="companyEmail"
                    type="email"
                    validate={(value) => {
                      if (value && !value.match(emailRgx)) {
                        return t("warnings.wrongEmail");
                      }
                    }}
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.users.email")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={styles["company-data-settings__body-fields"]}>
                  <Field
                    name="accountNumber"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bankAccount")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="bank"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.bank")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                <p className={styles["company-data-settings__body-subtitle"]}>
                  {t("dialog.buyers.representative")}
                </p>
                <div className={styles["company-data-settings__body-fields"]}>
                  <Field
                    name="representative.firstName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.name")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.lastName"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t(
                              "dialog.buyers.lastName"
                            )}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.jmbg"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.jmbg")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Field
                    name="representative.type"
                    type="text"
                    render={({ input, meta }) => {
                      const isError = meta.touched && meta.error;

                      return (
                        <div className={styles["company-data-settings__body-field"]}>
                          <InputField
                            {...input}
                            label={`${t("dialog.buyers.representativeType")}`}
                          />
                          <div
                            className={
                              styles["company-data-settings__body-field--validation"]
                            }
                          >
                            {isError && meta.error}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles["company-data-settings__body-fields-contact"]}>
                <p className={styles["company-data-settings__body-subtitle"]}>
                  {t("dialog.buyers.contactPersonsInfo")}
                </p>
                <div
                  className={styles["company-data-settings__body-fields-wrapper"]}
                >
                  <div className={styles["company-data-settings__body-fields"]}>
                    <Field
                      name="contact.firstName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div
                            className={styles["company-data-settings__body-field"]}
                          >
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.name")}`}
                            />
                            <div
                              className={
                                styles[
                                  "company-data-settings__body-field--validation"
                                ]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.lastName"
                      type="text"
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div
                            className={styles["company-data-settings__body-field"]}
                          >
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.lastName")}`}
                            />
                            <div
                              className={
                                styles[
                                  "company-data-settings__body-field--validation"
                                ]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.email"
                      type="email"
                      validate={(value) => {
                        if (value && !value.match(emailRgx)) {
                          return t("warnings.wrongEmail");
                        }
                      }}
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div
                            className={styles["company-data-settings__body-field"]}
                          >
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.email")}`}
                            />
                            <div
                              className={
                                styles[
                                  "company-data-settings__body-field--validation"
                                ]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Field
                      name="contact.phoneNumber"
                      type="tel"
                      validate={(value) => {
                        if (value && !value.match(phoneRgx)) {
                          return t("warnings.wrongPhoneNumber");
                        }
                      }}
                      render={({ input, meta }) => {
                        const isError = meta.touched && meta.error;

                        return (
                          <div
                            className={styles["company-data-settings__body-field"]}
                          >
                            <InputField
                              {...input}
                              label={`${t("dialog.buyers.contactPhoneNum")}`}
                            />
                            <div
                              className={
                                styles[
                                  "company-data-settings__body-field--validation"
                                ]
                              }
                            >
                              {isError && meta.error}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* TODO add file upload section if needed */}
              {/* <div>
                <div className={styles["company-data-settings__file-list"]}>
                  <p>{t("dialog.additionalDoc.yoursDoc")}: </p>
                  {data?.files?.map((file: IFiles, index: number) => (
                    <div key={index} className={styles["company-data-settings__file-button"]}>
                      <button
                        key={index}
                        onClick={() =>
                          downloadFile({
                            fileReference: file.fileReference,
                            name: file.name,
                            type: file.type,
                          })
                        }
                        className={styles["company-data-settings__file"]}
                      >
                        {file.name}
                      </button>

                      <button
                        className={styles["company-data-settings__delete-button"]}
                        onClick={async () => {
                          await deleteUsersFile(file.fileReference);
                          refetch();
                        }}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
                {(!data?.files || data?.files.length < 2) && (
                  <>
                    <h4>
                      {t(
                        "dialog.additionalDoc.youNeedToAddIdCardAndGrossBalance"
                      )}
                    </h4>
                    <StepTwoFiles push={push} />
                  </>
                )}
              </div> */}
            </DialogBody>

            <DialogActions
              className={styles["company-data-settings__actions-group"]}
            >
              <FlatButton type="button" onClick={closeAndReset}>
                {t("dialog.users.quit")}
              </FlatButton>

              <FlatButton
                onClick={async () => {
                  await form.submit();
                  const isSubmitted = form.getState().submitSucceeded;

                  if (!isSubmitted) return;

                  closeAndReset()
                }}
                type="button"
                color="blue"
                className={styles["company-data-settings__save-button"]}
                disabled={pristine}
              >
                {isFetching ? <Spinner /> : t("dialog.users.saveChanges")}
              </FlatButton>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};
