import { FC, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { EditBuyer } from "../../components/new-buyer/new-buyer";
import { Layout } from "../../components/layout/layout";
import { NewRequest } from "../../components/new-request/new-request";
import { useEditBuyerDialogState } from "../../state/buyers/buyers.slice";
import { useRequestsDialogState } from "../../state/requests/requests.slice";
import { CompanyDataDialog } from "../../components/company-data-dialog/company-data-dialog";
import { useUserQuery } from "../../state/user/users-api.service";

export const Client: FC<{}> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data: userInfo } = useUserQuery(null);

  useEffect(() => {
    if (userInfo.companyPib === "000000000") {
      setIsOpen(true)
    }
  }, [])

  return (
    <Layout>
      <Outlet />

      {/* Dialogs */}
      <CompanyDataDialog open={isOpen} onClose={() => setIsOpen(false)} />
      <NewRequest {...useRequestsDialogState()} />
      <EditBuyer {...useEditBuyerDialogState()} />
    </Layout>
  );
};
