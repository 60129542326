import { FC } from "react";
import NotFoundImage from "../../assets/images/not-found.jpeg";
import styles from "./not-found.module.scss";

export const NotFoundPage: FC<{}> = () => {
  return (
    <div className={styles["not-found"]}>
      <h1>This is not the page you're searching for.</h1>
      <img src={NotFoundImage} alt="Not Found" />
    </div>
  );
};
