import { classnames } from "@faktoring/util";
import { FC, InputHTMLAttributes } from "react";
import styles from "./input-field.module.scss";

interface IInputField extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: string;
  label?: string;
}

const InputField: FC<IInputField> = ({ className, error, label, ...props }) => {
  const classes = classnames(className, styles["input-field"], {
    [styles["input-field--invalid"]]: !!error,
  });

  return (
    <div>
      {label && <label className={styles["input-field--label"]}>{label}</label>}
      <input type="text" min={0.00} className={classes} {...props} />
      {error ? (
        <span className={styles["input-field__error"]}>{error}</span>
      ) : null}
    </div>
  );
};

export { type IInputField, InputField };
