import { classnames } from "@faktoring/util";
import { FC, InputHTMLAttributes, useState } from "react";
import { InputField } from "../input-field/input-field";
import { useTranslation } from "react-i18next";
import styles from "./input-show-and-hide.module.scss";

interface IInputField extends InputHTMLAttributes<HTMLInputElement>  {
    className?: string;
    error?: string;
    label?: string;
}

export const InputShowAndHide: FC<IInputField> = ({
  className,
  error,
  label,
  ...props
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className={styles["input-show-and-hide__wrapper"]}>
      <InputField
        {...props}
        label={label}
        error={error}
        className={classnames(styles["input-show-and-hide__input"], className)}
        type={isShown ? "text" : "password"}
      ></InputField>
      <button
        onClick={() => setIsShown(!isShown)}
        disabled={!props?.value}
        className={styles["input-show-and-hide__button"]}
      >
        {isShown ? t("dialog.requests.cta.hide") : t("dialog.requests.cta.show")}
      </button>
    </div>
  );
};
