import { Dialog, DialogBody, DialogHeader } from "@faktoring/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { localizedTime } from "../../pages/users/users";
import { formatNumber } from "../invoice-preview/invoice-preview";

interface IInvoiceFromSefPreviewProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

export const InvoiceFromSefPreview: FC<IInvoiceFromSefPreviewProps> = ({
  open,
  onClose,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader>
        {t("dialog.requests.invoiceDetails")}: {data.invoiceNumber}
      </DialogHeader>

      <DialogBody>
        <p>
          {t("dialog.requests.issueDate")}:&nbsp;
          {localizedTime(data.issueDate || "0")}
        </p>
        <p>
          {t("dialog.requests.paymentDate")}:&nbsp;
          {localizedTime(data.paymentDate || "0")}
        </p>
        <p>
          {t("dialog.requests.actualDeliveryDate")}:&nbsp;
          {localizedTime(data.actualDeliveryDate || "0")}
        </p>
        <p>
          {t("dialog.requests.amount")}: {formatNumber(data.amount || 0)}&nbsp;
          {data?.currency}
        </p>
        <p>
          {t("dialog.requests.salesInvoiceId")}: {data.salesInvoiceId}
        </p>
      </DialogBody>
    </Dialog>
  );
};
