import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  FlatButton,
  InfoBox,
  Spinner,
} from "@faktoring/ui";
import arrayMutators from "final-form-arrays";
import { FC } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUploadFilesMutation } from "../../state/requests/requests-api.service";
import { openRequestDialog } from "../../state/requests/requests.slice";
import { useUpdateUsersFilesMutation, useUserQuery } from "../../state/user/users-api.service";
import { StepTwoFiles } from "../new-invoice/step-two-files";
import styles from "./additional-documents-dialog.module.scss";

interface IAdditionalDocumentsDialog {
  open: boolean;
  onClose: () => void;
}

interface IUpdateFilesResponse {
  id: string;
  files: {
    fileName: string;
    uploadIdentifier: string;
  }[];
}


export const AdditionalDocumentsDialog: FC<IAdditionalDocumentsDialog> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [uploadFiles] = useUploadFilesMutation();
  const [updateUsersFiles] = useUpdateUsersFilesMutation();
  const { data, refetch } = useUserQuery(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    refetch()
  }, [isAdding])

  const handleSubmit = async (values: {files: File[]}) => {
    const files = values.files;
    const formData = new FormData();

    if (files) {
      await Promise.all(
        files.map((file: File) => formData.append("files", file))
      );
    }

    await uploadFiles(formData)
    .unwrap()
    .then((data: IUpdateFilesResponse) => {
      updateUsersFiles(data.files)
      setIsAdding(true)
    })

    if (data.files.length + files.length >= 2) {
      setTimeout(() => {
        dispatch(openRequestDialog());
      }, 450);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        form,
        pristine,
        form: {
          mutators: { push },
        },
      }) => {
        const closeAndReset = () => {
          form.restart();
          onClose();
        };

        return (
          <>
            <Dialog
              open={open}
              onClose={closeAndReset}
              className={styles["additional-doc"]}
            >
              <DialogHeader>{t("dialog.requests.info")}</DialogHeader>
              <DialogBody>
                <InfoBox title="">
                  {t("dialog.additionalDoc.beforeMakingReqAddDoc")}
                </InfoBox>

                <DialogHeader>
                  *{t("dialog.additionalDoc.addIdCardAndGrossBalance")}
                </DialogHeader>

                <StepTwoFiles push={push} />

                <DialogActions
                  className={styles["additional-doc__buttons-wrapper"]}
                  align="left"
                >
                  <FlatButton
                    type="button"
                    onClick={closeAndReset}
                    className={styles["additional-doc__button"]}
                  >
                    {t("dialog.requests.cta.quit")}
                  </FlatButton>
                  <FlatButton
                    color="blue"
                    className={styles["additional-doc__button"]}
                    onClick={async () => {
                      await form.submit()

                      setTimeout(() => {
                        setIsAdding(false);
                        closeAndReset();
                      }, 450);
                    }}
                    disabled={pristine || !form.getState().values.files}
                  >
                    {isAdding ? <Spinner /> : t("dialog.requests.cta.add")}
                  </FlatButton>
                </DialogActions>
              </DialogBody>
            </Dialog>
          </>
        );
      }}
    />
  );
};
