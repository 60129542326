import { Dialog, DialogBody, DialogHeader } from "@faktoring/ui";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import ClientsData from "../../assets/images/PodaciKlijenta.png";
import BuyersData from "../../assets/images/PodaciKupca.png";
import InvoiceStepOne from "../../assets/images/faktura1.png";
import InvoiceStepTwo from "../../assets/images/faktura2.png";
import RequestStepFour from "../../assets/images/kreirajZahtevStepFour.png";
import RequestStepOne from "../../assets/images/kreirajZahtevStepOne.png";
import ChooseAddingInvoices from "../../assets/images/Zahtev_izborFakture.png";
import RequestStepTwo from "../../assets/images/kreirajZahtevStepTwo.png";
import StatusCreated1 from "../../assets/images/status-kreiran-1.png";
import StatusCreated2 from "../../assets/images/status-kreiran-2.png";
import StatusApproved from "../../assets/images/status-odobren.png";
import StatusRealized from "../../assets/images/status-realizovan.png";
import InvoicesFromSefTwo from "../../assets/images/Zahtev_SefFakture.png";
import DownloadingInvoices from "../../assets/images/preuzmi-fakture-sa-sefa.png";
import AddApiKey from "../../assets/images/MojProfil-ApiKljuc.png";
import styles from "./info-dialog.module.scss";
import { Link } from "react-router-dom";

interface IInfoDialogProps {
  open: boolean;
  onClose: () => void;
}

interface IInfoText {
  text: any;
  imgSrc?: string;
  title?: string;
  ref?: any;
  id?: any;
}

interface ITitleIndex {
  title: string;
}

const InfoText: FC<IInfoText> = ({ id, text, imgSrc, title }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div id={id} ref={ref} className={styles["info-dialog__info-wrapper"]}>
      {title && <h4>{title}</h4>}
      <p>{text}</p>
      {imgSrc && <img src={imgSrc} alt="Faktoring Uputstvo" loading="lazy" />}
    </div>
  );
};

const TitleIndex: FC<ITitleIndex> = ({ title }) => {
  const { t } = useTranslation();

  const scrollToInfoText = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button
      onClick={() => scrollToInfoText(t(`${title}`))}
      className={styles["title-index-button"]}
    >
      {t(`${title}`)}
    </button>
  );
};

export const InfoDialog: FC<IInfoDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className={styles["info-dialog"]}>
      <DialogHeader>{t("dialog.info.appInstruction")}</DialogHeader>

      <DialogBody className={styles["info-dialog__body"]}>
        {/* Page indexing */}
        <ol>
          <li>
            <TitleIndex title="dialog.info.dataEntryAboutCompany"></TitleIndex>
          </li>

          <li>
            <TitleIndex title="dialog.info.dataEntryAboutBuyer"></TitleIndex>
          </li>
          <li>
            <TitleIndex title="dialog.info.creatingRequest"></TitleIndex>
          </li>
          <li>
            <TitleIndex title="dialog.info.requestsStatus"></TitleIndex>
          </li>
          <li>
            <TitleIndex title="dialog.info.addingAPIKey"></TitleIndex>
          </li>
        </ol>

        <InfoText
          title={t("dialog.info.dataEntryAboutCompany") || ""}
          text={t("dialog.info.firstLoginDataEntryText")}
          imgSrc={ClientsData}
          id={t("dialog.info.dataEntryAboutCompany")}
        />
        <InfoText
          title={t("dialog.info.dataEntryAboutBuyer") || ""}
          text={t("dialog.info.createBuyerInstructionText")}
          imgSrc={BuyersData}
          id={t("dialog.info.dataEntryAboutBuyer")}
        />

        {/* First step of creating request */}
        <InfoText
          title={t("dialog.info.creatingRequest") || ""}
          text={t("dialog.info.creatingRequestStepOneInstructions")}
          imgSrc={RequestStepOne}
          id={t("dialog.info.creatingRequest")}
        />

        {/* Second step of creating request */}
        <InfoText
          text={t("dialog.info.dataEntryAboutBuyer")}
          imgSrc={RequestStepTwo}
        />

        {/* Creation of invoice */}
        <InfoText
          text={t("dialog.info.twoOptionsOFAddingInvoices")}
          imgSrc={ChooseAddingInvoices}
        />
        <InfoText
          text={t("dialog.info.addInvoiceData")}
          imgSrc={InvoiceStepOne}
        />
        <InfoText
          text={t("dialog.info.addInvoiceDocuments")}
          imgSrc={InvoiceStepTwo}
        />
        <InfoText
          text={t("dialog.info.addingInvoicesFromSEF")}
          imgSrc={InvoicesFromSefTwo}
        />

        {/* Fourth step of creating request */}
        <InfoText
          text={t("dialog.info.addRequestDocuments")}
          imgSrc={RequestStepFour}
        />

        {/* Requests status */}
        <InfoText
          text={t("dialog.info.requestHasStatusCreate")}
          imgSrc={StatusCreated1}
        />
        <InfoText
          title={t("dialog.info.requestsStatus") || ""}
          text={t("dialog.info.statusCreated2")}
          imgSrc={StatusCreated2}
          id={t("dialog.info.requestsStatus")}
        />
        <InfoText
          text={t("dialog.info.statusApproved")}
          imgSrc={StatusApproved}
        />
        <InfoText
          text={t("dialog.info.statusRealized")}
          imgSrc={StatusRealized}
        />

        {/* Adding API key */}
        <InfoText
          title={t("dialog.info.addingAPIKey") || ""}
          text={t("dialog.info.youNeedToAddAPIKey")}
          imgSrc={AddApiKey}
          id={t("dialog.info.addingAPIKey")}
        />
        <InfoText
          text={t("dialog.info.usingAndDownloadingInvoicesFromSEFInstructions")}
          imgSrc={DownloadingInvoices}
        />
        <div className={styles["info-dialog__links-wrapper"]}>
          <Link
            to="https://www.efaktura.gov.rs/view_file.php?file_id=314&cache=sr"
            target="_blank"
            className={styles["info-dialog__info-link"]}
          >
            {t("dialog.requests.downloadInstructionsToAccessSEF")}
          </Link>
          <Link
            to="https://www.efaktura.gov.rs/view_file.php?file_id=59&cache=sr"
            target="_blank"
            className={styles["info-dialog__info-link"]}
          >
            {t("dialog.requests.downloadInstructionsForGeneratingAPIkey")}
          </Link>
        </div>
      </DialogBody>
    </Dialog>
  );
};
