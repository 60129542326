import api from "../api";
import { IPageRequestData, PaginationResult } from "../pagination-types";
import { User } from "./user.class";

interface IFiles {
  fileName: string,
  uploadIdentifier: string
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    user: build.query({
      query: () => "users",
    }),
    allUsers: build.query<PaginationResult<User>, IPageRequestData>({
      query: ({ 
        page,
        pageSize
       }) =>
        `users/all?pageSize=${pageSize}&pageNumber=${page}`,
    }),
    updateUserPersonalData: build.mutation({
      query: (personalData) => ({
        url: 'users/update/personal-data',
        method: "PUT",
        body: personalData
      }),
    }),
    updateUserPaymentInfo: build.mutation({
      query: ({ 
        id, 
        debtLimit, 
        retainedAdvancePercent,
        monthlyInterestRatePercent
      }) => ({
        url: `users/update/payment-info/${id}`,
        method: "PUT",
        body: {
          debtLimit: debtLimit,
          retainedAdvancePercent: retainedAdvancePercent,
          monthlyInterestRatePercent: monthlyInterestRatePercent
        },
      }),
    }),
    updateUsersCompanyData: build.mutation({
      query: (companyData) => ({
        url: 'users/update/company-data',
        method: "PUT",
        body: companyData,
      }),
    }),
    updateUsersFiles: build.mutation({
      query: (files: IFiles[]) => ({
        url: "documents/add/user",
        method: "POST",
        body: { files: files },
      }),
    }),
    deleteUsersFile: build.mutation({
      query: (file: IFiles[] | string) => ({
        url: "documents/delete/user",
        method: "DELETE",
        body: {files: [file]},
      }),
    }),
    getUsersDataFromApr: build.mutation({
      query: (registrationNumber: number) => ({
        url: `b2b/user/${registrationNumber}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUserQuery,
  useAllUsersQuery,
  useUpdateUserPersonalDataMutation,
  useUpdateUserPaymentInfoMutation,
  useUpdateUsersCompanyDataMutation,
  useUpdateUsersFilesMutation,
  useDeleteUsersFileMutation,
  useGetUsersDataFromAprMutation
} = extendedApi;
