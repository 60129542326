export const srNames = {
  time: {
    date: 'sr-Latn-RS',
  },
  date: 'sr-RS',
  header: {
    myRequests: 'Moji zahtevi',
    myBuyers: 'Moji kupci',
    myUsers: 'Moji klijenti',
    reports: 'Izveštaji',
    contracts: 'Ugovori',
    myCompany: 'Moja firma',
    myProfile: 'Moj profil',
    payments: 'Uplate',
  },
  sideBar: {
    allRequests: 'Svi zahtevi',
    newRequests: 'Novi zahtevi',
    info: 'Potrebne informacije',
    approved: 'Odobren',
    toSign: 'Da potpiše',
    rejected: 'Odbijen',
    quited: 'Odustao',
    accepted: 'Prihvaćen',
    closed: 'Zatvoren',
    realized: 'Realizovan',
    charged: 'Naplaćen',
    logout: 'Log out',
  },
  cta: {
    createNewRequest: 'Kreirajte novi zahtev',
    createNewBuyer: 'Kreirajte novog kupca',
  },
  table: {
    actions: {
      action: 'Akcija',
      approve: 'Odobri',
      change: 'Izmeni',
      delete: 'Obriši',
      reject: 'Odbij',
      details: 'Detalji',
    },
    requests: {
      idNumber: 'ID broj',
      buyer: 'Kupac',
      user: 'Klijent',
      totalAmount: 'Fakture ukupno',
      requestedAmount: 'Zahtevani iznos',
      approvedAmount: 'Odobreni iznos',
      debt: 'Postojeći dug',
      offeredAmount: 'Ponuđena cena',
      buyerResponse: 'Odgovor kupca',
      reqStatus: {
        name: 'Status',
        created: 'Kreiran',
        approvedAdmin: 'Odobren',
        approvedUser: 'Prihvaćen',
        rejectedAdmin: 'Odbijen',
        rejectedUser: 'Odustao',
        pendingUpdate: 'Na izmeni',
        charged: 'Naplaćen',
        realized: 'Realizovan',
        unknown: 'Nepoznat',
      },
      details: 'Detalji',
      paymentDate: 'Datum zahteva',
      maturityDate: 'Datum dospeća',
      lastChangeDate: 'Poslednja izmena',
      invoiceId: 'ID fakture',
    },
    buyers: {
      idNumber: 'ID broj',
      name: 'Ime',
      pib: 'PIB',
      email: 'Email',
      phone: 'Telefon',
      cooperationStartDate: 'Datum početka saradnje',
    },
    users: {
      idNumber: 'ID broj',
      name: 'Ime',
      lastname: 'Prezime',
      companyName: 'Naziv preduzeća',
      email: 'Email',
      phone: 'Telefon',
      limit: 'Limit',
      debt: 'Postojeći dug',
      retainedAdvancePercent: 'Zadržani avans',
      monthlyInterestRatePercent: 'Kamatna stopa',
      promissoryNoteCount: 'Menice',
    },
    reports: {
      monthlyAmount: 'Mesečni iznos',
      dailyAmount: 'Dnevni iznos',
      totalInvoicesAmountPerMonth: 'Ukupan iznos dospelih faktura po mesecima',
      retainedAmount: 'Plasirani iznos',
      interestPercent: 'Kamata',
      interestPercentAmount: 'Kamata - iznos',
      penaltyInterest: 'Prihod docnja',
      latestMaturity: 'Poslednje dospeće',
    },
  },
  dialog: {
    users: {
      userData: 'Podaci o klijentu',
      buyersData: 'Podaci o kupcu',
      yoursCompanyData: 'Podaci Vašeg preduzeća',
      yoursData: 'Vaši lični podaci',
      name: 'Ime',
      lastName: 'Prezime',
      companyName: 'Naziv preduzeća',
      shortCompanyName: 'Skraćeno poslovno ime',
      email: 'Email',
      emailForLogin: 'Email (za pristup aplikaciji)',
      contactEmail: 'Kontakt email',
      phone: 'Telefon',
      limit: 'Limit',
      remainingLimit: 'Preostali limit',
      debt: 'Postojeći dug',
      street: 'Ulica',
      doorNumber: 'Broj',
      city: 'Grad',
      zipCode: 'Poštanski broj',
      retainedAdvancePercent: 'Zadržani avans',
      quit: 'Odustani',
      saveChanges: 'Sačuvaj izmene',
      save: 'Sačuvaj',
      youNeedToAddCompanysData:
        'Neophodno je da unesete potrebne podatke o firmi.',
      changePassword: 'Izmeni lozinku',
    },
    buyers: {
      name: 'Ime',
      lastName: 'Prezime',
      email: 'Email',
      delete: 'Izbriši',
      searchByPib: 'Pretraži po PIB-u',
      searchByIDNumber: 'pretraži po matičnom broju',
      manuelInput: 'Manuelni unos',
      enterPib: 'Unesite PIB',
      registrationNumber: 'Matični broj',
      enterIDNum: 'Unesite matični broj',
      companyAddress: 'Adresa sedišta',
      representative: 'Zastupnik',
      director: 'Direktor',
      jmbg: 'JMBG',
      representativeType: 'Funkcija',
      bankAccount: 'Tekući račun',
      bank: 'Banka',
      creditScore: 'Kreditna ocena',
      creditScoreDate: 'Datum kreditne ocene',
      trend: 'Trend poslovanja',
      contactPhoneNum: 'Telefon',
      contactPersonsInfo: 'Podaci o kontakt osobi',
      addContactPersonsInfo: 'Unesite podatke o kontakt osobi',
      areYouSureYouWantDeleteBuyer:
        'Da li ste sigurni da želite da izbrišete kupca?',
      buyerWillBeDeletedForever: 'Kupac će biti zauvek izbrisan.',
      buyerWithPibOrRegNumIsNotFound:
        'Kupac sa unetim PIB-om ili matičnim brojem nije pronađen.',
    },
    requests: {
      requestDetails: 'Detalji zahteva',
      invoiceDetails: 'Detalji fakture',
      bankAccountDetails: 'Detalji bankovnog računa',
      totalRequest: 'Ukupan obračun na nivou zahteva',
      invoiceData: 'Obračun na nivou fakture',
      paymentOption: {
        name: 'Način plaćanja',
        promissoryNote: 'Menica',
        payWarrent: 'Platni nalog',
      },
      totalCalculation: 'Ukupan obračun',
      amount: 'Iznos fakture',
      totalAmount: 'Ukupan iznos',
      retainedAdvancePercent: 'Zadržani avans',
      retainedAdvanceAmount: 'Zadržani deo - ukupno',
      amountAfterAdvance: 'Iznos nakon zadržanog dela',
      dailyInterestRatePercent: 'Diskontna stopa - dnevno',
      monthlyInterestRatePercent: 'Diskontna stopa - mesečno',
      totalDays: 'Broj dana',
      interestRateTotal: 'Ukupna diskontna stopa',
      appFeePercent: 'Faktoring naknada',
      appFeeTotal: 'Faktoring naknada - ukupno',
      totalAppExpense: 'Ukupan faktoring trošak/cena',
      finalPaymentAmount: 'Iznos za isplatu',
      total: 'Ukupno',
      messageToClient: 'Poruka klijentu',
      message: 'Poruka',
      issueDate: 'Datum izdavanja',
      paymentDate: 'Datum dospeća',
      actualDeliveryDate: 'Datum prometa',
      salesInvoiceId: 'Broj fakture na SEF-u',
      invoiceCreationDate: 'Datum kreiranja fakture',
      endDate: 'Krajnji datum',
      invoice: 'Faktura',
      invoices: 'Fakture',
      info: 'Obaveštenje',
      currency: 'Valuta',
      forMakingRequestYouNeedBankAccount:
        'Da biste dodali zahtev Vaš profil mora sadržati tekući račun preduzeća.',
      additionalDocumentationForRequest: 'Prateća dokumentacija za zahtev',
      buyerWillPayInvoiceWith: 'Kupac će fakturu platiti putem',
      buyerIsForeignLegalEntity: 'Kupac je strano pravno lice',
      chooseBankAccount: 'Izaberite tekući račun',
      addNewBankAccount: 'Dodajte novi bankovni račun',
      addNewBuyer: 'Dodajte novog kupca',
      addInvoice: 'Dodajte fakturu',
      addInvoicesFromSef: 'Dodajte fakture sa SEF-a',
      addDocs: 'Dodajte dokumente',
      newAccount: 'Novi račun',
      chooseBuyer: 'Izaberite kupca',
      docFormats: 'PDF, JPG, JPEG, PNG',
      fileSizeLimitInfo: 'Fajl ne sme biti veći od 2 MB!',
      dragFiles: 'Prevucite fajlove ili',
      companyPhoneNumber: 'Broj telefona preduzeća',
      invoiceNumber: 'Broj fakture',
      chooseDeadline: 'Izaberite rok',
      apiKey: 'API ključ',
      downloadingInvoicesFromSefInstructions:
        'Dodajte fakture sa SEF-a kreirane u određenom vremenskom periodu. Prikazane fakture će biti fakture izdate za kupca kog ste prethodno uneli tokom kreiranja zahteva.',
      noDownloadedInvoices:
        'Za odabranog kupca ne postoje fakture u izabranom vremenskom opsegu.',
      invalidApiKeyErrorMessage: 'Uneti API key nije odgovarajuć.',
      or: 'Ili',
      downloadInstructionsToAccessSEF: 'Preuzmite uputstvo za pristup SEF-u',
      downloadInstructionsForGeneratingAPIkey:
        'Preuzmite uputstvo za generisanje API ključa',
      youNeedToAddAPIKeyBeforeDownloadingInvoices:
        'Da biste pretražili fakture na SEF-u morate da unesete API ključ.',
      addApiKey: 'Unesi API ključ',
      changeApiKey: 'Promenite API ključ.',
      additionalFiles: 'Prilozi',
      paymentDatePerInvoice: 'Datum uplate',
      paymentAmount: 'Uplaćeni iznos',
      comment: 'Komentar',
      payments: 'Uplate',
      allPayments: 'Sve uplate',
      totalPaymentsPerInvoice: 'Ukupno uplaćeno',
      invoiceRequestId: 'ID zahteva',
      cta: {
        sendForChange: 'Pošalji na izmenu',
        rejectRequest: 'Odbij zahtev',
        acceptRequest: 'Prihvati zahtev',
        approveRequest: 'Odobri zahtev',
        rejectInvoice: 'Odbij fakturu',
        acceptInvoice: 'Odobri fakturu',
        send: 'Pošalji',
        back: 'Nazad',
        continue: 'Nastavi',
        quit: 'Odustani',
        calculate: 'Izračunaj',
        saveAccount: 'Sačuvaj račun',
        clickHere: 'Klikni ovde',
        add: 'Dodaj',
        saveBuyer: 'Sačuvaj kupca',
        search: 'Pretraži',
        show: 'Prikaži',
        hide: 'Sakrij',
        createDocumentation: 'Kreiraj dokumentaciju',
        requestIsCharged: 'Zahtev je naplaćen',
        invoiceIsCharged: 'Faktura je naplaćena',
        addInvoicePayment: 'Unesi uplatu',
        downloadInvoices: 'Pretraži fakture',
        realizeRequest: 'Realizuj zahtev',
      },
    },
    info: {
      appInstruction: 'Uputstvo o korišćenju aplikacije',
      dataEntryAboutCompany: 'Unos podataka o Vašem preduzeću',
      requestsStatus: 'Statusi zahteva',
      firstLoginDataEntryText:
        'Na prvom logovanju potrebno je da unesete osnovne podatke o Vašem preduzeću. Unesite podatke automatski, pretragom po PIB/MB ili samostalno i sačuvajte. Dodatne račune možete da dodate na formi za kreiranje zahteva.',
      dataEntryAboutBuyer: 'Unos podataka o kupcu',
      createBuyerInstructionText:
        'Klikom na "Kreirajte novog kupca" otvoriće se forma za unos podataka o kupcu. Na isti način unesite podatke o Vašem kupcu (pretragom po PIB/MB ili samostalno) i sačuvajte.',
      creatingRequest: 'Kreiranje zahteva',
      creatingRequestStepOneInstructions:
        'Klikom na "Kreirajte novi zahtev" otvoriće se forma za novi zahtev. Izaberite broj računa ili dodajte novi i nastavite.',
      chooseBuyerAndContinue: 'Izaberite kupca i nastavite',
      addInvoiceData: 'Manuelni unos - dodajte podatke o fakturi',
      addInvoiceDocuments: 'Dodajte vizuelni prikaz fakture',
      addRequestDocuments: 'Dodajte potrebne dokumente za zahtev',
      requestHasStatusCreate:
        'Vaš zahtev je u statusu "Kreiran". Detaljima zahteva pristupate preko padajućeg menija određenog zahteva - "Akcija" - "Detalji".',
      statusCreated2:
        'Nakon kreiranja zahteva možete očekivati poruku sa detaljima o tome šta je neophodno dodati kako bi zahtev bio potpun, ukoliko je to potrebno.',
      statusApproved:
        'Nakon što je zahtev pregledan, on može imati status "Odobren". Tada možete videti ukupan obračun i iznos za isplatu u detaljima zahteva. Ukoliko Vam ponuda odgovara, možete prihvatiti ponudu i Vaš zahtev će biti u statusu "Prihvaćen", ili možete odbiti zahtev.',
      statusRealized:
        'Nakon Vašeg prihvatanja zahteva i kreiranja dokumentacije, Vaš zahtev će biti u statusu "Realizovan".',
      addingAPIKey: 'Dodavanje API ključa',
      usingAndDownloadingInvoicesFromSEFInstructions:
        'Ukoliko započnete kreiranje zahteva bez unetog API ključa možete uneti API ključ tokom trećeg koraka kreiranja zahteva ili možete ručno uneti podatke o fakturi. Ako imate unesen validan API ključ možete da pretražujete sve fakture vezane za kupca kog ste prethodno izabrali tokom kreiranja zahteva u određenom vremenskom periodu. Imate mogućnost da izaberete samo početni datum ili vremenski opseg. Oba datuma se odnose na datume kada ste kreirali fakturu na SEF-u. Ispod možete pronaći dodatna uputstva o korišćenju SEF-a. ',
      youNeedToAddAPIKey:
        'Da biste pretraživali svoje fakture na SEF-u morate da unesete API ključ na Mom profilu.',
      twoOptionsOFAddingInvoices:
        'Fakture možete dodati popunjavanjem formulara ili preuzimanjem sa SEF-a',
      addingInvoicesFromSEF: 'Dodavanje faktura sa SEF-a',
    },
    additionalDoc: {
      beforeMakingReqAddDoc:
        'Pre nego što započnete sa kreiranjem zahteva, neophodno je da unesete potrebne dokumente.',
      addIdCardAndGrossBalance: 'Dodajte očitanu ličnu kartu i bilans stanja',
      yoursDoc: 'Vaši dokumenti',
      youNeedToFollowingDocs: 'Naophodno je dodati sledeća dokumenta',
      youNeedToAddIdCardAndGrossBalance:
        'Neophodno je da dodate očitanu ličnu kartu i bilans stanja',
      idCardWithMoreThan25pct: 'Lična karta lica sa više od 25% udela',
      representativeIdCard: 'Lična karta ovlašćenog lica - zastupnika',
      grossBalance: 'Bruto bilans',
      customerCard: 'Kartica kupca',
      finalAccountForLastYear: 'Završni račun za poslednju godinu (opciono)',
    },
    contracts: {
      makeNewContract: 'Napravi ugovor',
      newContract: 'Novi ugovor',
      contractNumber: 'Broj ugovora',
      contractDoesnHaveNumber: 'Ugovor nema broj',
      thereIsnNoActiveContract: 'Nema aktivnog ugovora',
      contractDate: 'Datum ugovora',
      chooseClient: 'Izaberi klijenta',
      chooseBuyer: 'Izaberi kupca',
      chooseClientAndBuyer: 'Izaberi klijenta i kupca',
      contractsDetails: 'Detalji ugovora',
      representativeName: 'Zastupnik-ime',
      representativeLastname: 'Zastupnik-prezime',
      representativeJMBG: 'Zastupnik-JMBG',
      representativeFunction: 'Zastupnik-funkcija',
      usersData: 'Podaci klijenta',
      buyersData: 'Podaci kupca',
      create: 'Kreiraj',
      dateCreated: 'Datum sklapanja',
      endDate: 'Datum isteka',
      status: {
        name: 'Status',
        inPreparation: 'U pripremi',
        valid: 'Važeći',
        expired: 'Istekao',
      },
      chooseContractStatus: 'Izaberi status ugovora',
      contractDetails: 'Detalji ugovora',
      contractLimit: 'Limit',
      limitWritten: 'Limit (slovima)',
      downloadContract: 'Preuzmi ugovor',
      downloadPromissoryNotes: 'Preuzmi meničnu izjavu',
      contractType: 'Tip ugovora',
      chooseContractType: 'Izaberi tip ugovora',
      contractOneTimeType: 'Jednokratni ugovor',
      contractWithLimitType: 'Ugovor sa limitom',
      allContracts: 'Svi',
      filters: 'Filteri',
      oneTimeContract: 'Jednokratni ugovor',
      longTermContract: 'Ugovor sa limitom',
      noContractErrorMessage:
        'Pre kreiranja dokumentacije neophodno je kreirati ugovor',
    },
  },
  chart: {
    incomePerMonth: 'Dospeća na mesečnom nivou',
    incomePerDay: 'Dospeća na dnevnom nivou',
    invoicesDueOnTheDay: 'Fakture koje dospevaju na dan',
    client: 'Klijent',
    buyer: 'Kupac',
    amount: 'Iznos',
  },
  warnings: {
    requiredField: 'Polje je obavezno',
    retainedAdvancePercentIsHigher: 'Procenat kamatne stope prelazi 100%',
    monthlyInterestRatePercentIsHigher: 'Procenat diskontne stope prelazi 100%',
    noRequestsData: 'Trenutno nema ni jedan poznat zahtev!',
    messageLengthOverMax: 'Dozvoljeni broj karaktera je 255.',
    minOneDocIsRequired: 'Potrebno je uneti najmanje jedan dokument',
    minTwoDocIsRequired: 'Potrebno je uneti najmanje dva dokumenta',
    wrongPhoneNumber: 'Neispravan broj telefona',
    pibMustHave9Num: 'PIB mora imati 9 cifara',
    IDNumMustHave8Num: 'Matični broj mora imati 8 cifara',
    wrongEmail: 'Neispravna e-mail adresa',
    toBigFileSize: 'Fajlovi us preveliki',
  },
};
