import { RequestStatus } from "@faktoring/ui/src/components/status-info/status-info";
import { Invoice } from "./invoice.class";

export interface PaymentData {
  id: number;
  minDate: string;
  maxDate: string;
  amount: number;
  retainedAdvancePercent: number;
  retainedAdvanceAmount: number;
  amountAfterAdvance: number;
  dailyInterestRatePercent: number;
  monthlyInterestRatePercent: number;
  totalDays: number;
  interestRateTotal: number;
  appFeePercent: number;
  appFeeTotal: number;
  totalAppExpense: number;
  finalPaymentAmount: number;
  minDays: number;
  maxDays: number;
}

interface File {
  name: string;
  type: string;
  fileReference: string;
}

const initialPaymentData = {
  id: 0,
  minDate: "",
  maxDate: "",
  amount: 0,
  retainedAdvancePercent: 0,
  retainedAdvanceAmount: 0,
  amountAfterAdvance: 0,
  dailyInterestRatePercent: 0,
  monthlyInterestRatePercent: 0,
  totalDays: 0,
  interestRateTotal: 0,
  appFeePercent: 0,
  appFeeTotal: 0,
  totalAppExpense: 0,
  finalPaymentAmount: 0,
  minDays: 0,
  maxDays: 0,
};

const initialInvoice = {
  id: 1,
  amount: 0,
  invoiceNumber: "",
  issueDate: "",
  paymentDate: "",
  deadlineDays: 0,
  totalAmount: 0,
  requestedAmount: 0,
  currency: "",
  status: RequestStatus.ApprovedAdmin,
  paymentData: initialPaymentData,
  files: [],
};

const initialFile = {
  name: "",
  type: "",
  fileReference: "",
};

export const initialRequest = {
  id: 0,
  buyerId: 0,
  userId: 0,
  amount: 0,
  accountNumber: "",
  adminComment: "",
  buyerCompanyName: "",
  buyerPib: "",
  clientCompanyName: "",
  dateCreated: "",
  files: [initialFile],
  invoices: [initialInvoice],
  lastStatusChangeDate: "",
  maturityDate: "",
  paymentData: initialPaymentData,
  paymentOption: "",
  status: RequestStatus.ApprovedAdmin,
  details: "",
  contractId: 0,
  contractNumber: "",
  contractType: "",
  contractStatus: "",
};

export class Request {
  public id: number = 0;
  public buyerId: number = 0;
  public userId: number = 0;
  public amount: number = 0;
  public accountNumber: string = "";
  public buyerPib: string = "";
  public buyerCompanyName: string = "";
  public clientCompanyName: string = "";
  public invoices: Invoice[] = [initialInvoice];
  public paymentOption: string = "";
  public status: RequestStatus = RequestStatus.Unknown;
  public details: string = "";
  public paymentData: PaymentData = initialPaymentData;
  public dateCreated: string = "";
  public adminComment: string = "";
  public lastStatusChangeDate: string = "";
  public maturityDate: string = "";
  public files: File[] = [initialFile];
  public contractId: number = 0;
  public contractNumber: string = "";
  public contractType: string = "";
  public contractStatus: string = "";
}

export class Requests {
  public content: Request[] = [initialRequest];
  public totalElements?: number = 0;
  public totalPages?: number = 0;
  public pageSize?: number = 0;
  public pageNumber?: number = 0;
  public sortField?: string = "";
  public sortDirection?: string = "";
}
