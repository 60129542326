import api from "../api";

interface IAllPaymentsPayload {
  id: number;
  invoideId: number;
  paymentDate: string;
  amount: number;
  comments: string;
}

interface IAllPayments {
  page: number
  pageSize: number
  sortField: string
  sortDirection: string
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    allPayments: build.query({
      query: ({
        page,
        pageSize,
        sortField,
        sortDirection,
      }: IAllPayments) =>
        `payments/all?pageSize=${pageSize}&pageNumber=${page}&sortField=${sortField}&sortDirection=${sortDirection}`,
    }),
  }),
});

export const {
  useAllPaymentsQuery,
} = extendedApi;
