import React, { FC } from "react";
import { Spinner, Table } from "@faktoring/ui";
import { useAllPaymentsQuery } from "../../state/payments/payments-api.service";
import { useTranslation } from "react-i18next";
import { Payment } from "../../state/payments/payments.class";
import { onPageChange, onSortDirectionChange, onSortFieldChange, selectPaymentsData } from "../../state/payments/payments.slice";
import { useDispatch, useSelector } from "react-redux";
import { localizedCurrency, localizedTime } from "../users/users";
import styles from "./payments.module.scss";
import { Icon } from "@faktoring/ui/src/components/icon/icon";

export const Payments: FC = () => {
  const { pageSize, page, sortDirection, sortField } = useSelector(selectPaymentsData);
  const { data: allPayments } = useAllPaymentsQuery({pageSize, page, sortDirection, sortField});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnsDefinitionPayments = [
    {
      header: (
        <div className={styles["contracts__th"]}>
          <span>{t("table.requests.idNumber")}</span>
        </div>
      ),
      field: (row: Payment) => row.id,
    },
    {
      header: (
        <div className={styles["contracts__th"]}>
          <span>{t("table.requests.buyer")}</span>
        </div>
      ),
      field: (row: Payment) => row.buyerCompanyName,
    },
    {
      header: (
        <div className={styles["contracts__th"]}>
          <span>{t("table.requests.user")}</span>
        </div>
      ),
      field: (row: Payment) => row.userCompanyName,
    },
    {
      header: (
        <div className={styles["payments__th"]}>
          <div className={styles["payments__th-buttons-wrapper"]}>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "amount"
                ) {
                  dispatch(onSortFieldChange("amount"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "amount"
                ) {
                  dispatch(onSortFieldChange("amount"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["payments__text-align-right"]}>
            {t("dialog.requests.paymentAmount")}
          </p>
        </div>
      ),
      field: (row: Payment) => localizedCurrency(row.amount),
    },
    {
      header: (
        <div className={styles["payments__th"]}>
          <div className={styles["payments__th-buttons-wrapper"]}>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "paymentDate"
                ) {
                  dispatch(onSortFieldChange("paymentDate"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "paymentDate"
                ) {
                  dispatch(onSortFieldChange("paymentDate"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["payments__text-align-right"]}>
            {t("dialog.requests.paymentDatePerInvoice")}
          </p>
        </div>
      ),
      field: (row: Payment) => localizedTime(row.paymentDate),
    },
    {
      header: (
        <div className={styles["payments__th"]}>
          <div className={styles["payments__th-buttons-wrapper"]}>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "invoiceRequestId"
                ) {
                  dispatch(onSortFieldChange("invoiceRequestId"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "invoiceRequestId"
                ) {
                  dispatch(onSortFieldChange("invoiceRequestId"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["payments__text-align-right"]}>
            {t("dialog.requests.invoiceRequestId")}
          </p>
        </div>
      ),
      field: (row: Payment) => row.invoiceRequestId,
    },
    {
      header: (
        <div className={styles["payments__th"]}>
          <div className={styles["payments__th-buttons-wrapper"]}>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "desc" ||
                  sortField !== "invoiceNumber"
                ) {
                  dispatch(onSortFieldChange("invoiceNumber"));
                  dispatch(onSortDirectionChange("asc"));
                }
              }}
            >
              <IconMemo />
            </button>
            <button
              className={styles["payments__th-button"]}
              onClick={() => {
                if (
                  sortDirection === "asc" ||
                  sortField !== "invoiceNumber"
                ) {
                  dispatch(onSortFieldChange("invoiceNumber"));
                  dispatch(onSortDirectionChange("desc"));
                }
              }}
            >
              <IconMemo />
            </button>
          </div>
          <p className={styles["payments__text-align-right"]}>
            {t("dialog.requests.invoiceNumber")}
          </p>
        </div>
      ),
      field: (row: Payment) => row.invoiceNumber,
    },
    {
      header: (
        <div className={styles["contracts__th"]}>
          <span>{t("dialog.requests.comment")}</span>
        </div>
      ),
      field: (row: Payment) => row.comment,
    },
  ];

  return (
    <>
      {allPayments ? (
        <Table
          isAdded={false}
          data={allPayments}
          currentPage={page}
          onPageChange={(value) => dispatch(onPageChange(value))}
          columns={columnsDefinitionPayments}
        />
      ) : (
        <div className={styles["payments__spinner-wrapper"]}>
          <Spinner />
        </div>
      )}
    </>
  );
};

const IconMemo = React.memo(() => {
  return <Icon name="down" />;
});