import { Dialog, DialogBody, DialogHeader } from "@faktoring/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAllUsersQuery } from "../../state/user/users-api.service";
import { User } from "../../state/user/user.class";
import { localizedCurrency, localizedTime } from "../../pages/users/users";
import {
  InvoiceForReports,
  ReportByDate,
} from "../../state/reports/reports.class";
import styles from "./report-preview.module.scss";

interface IReportPreview {
  open: boolean;
  onClose: () => void;
  data: ReportByDate;
}

export const ReportPreview: FC<IReportPreview> = ({ open, onClose, data }) => {
  const { data: allUsers } = useAllUsersQuery(
    {
      page: 0,
      pageSize: 300,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} onClose={onClose} className={styles["report-preview"]}>
        <DialogHeader>{`${t("chart.invoicesDueOnTheDay")}: ${localizedTime(data?.date)}`}</DialogHeader>

        <DialogBody className={styles["report-preview__body"]}>
          {data?.invoices.map((invoice: InvoiceForReports, index: number) => (
            <div key={invoice.amount} className={styles["report-preview__invoice"]}>
              <h4 className={styles["report-preview__invoice-index"]}>
                {index + 1}.
              </h4>
              <div className={styles["report-preview__details"]}>
                <p>
                  <strong>{t("chart.client")}:</strong> &nbsp;
                  {
                    allUsers?.content.find(
                      (user: User) => user.id === invoice.userId
                    )?.companyName
                  }
                </p>
                <p>
                  <strong>{t("chart.buyer")}:</strong> {invoice.buyerCompanyName}
                </p>
                <p>
                  <strong>{t("chart.amount")}:</strong> {localizedCurrency(invoice.amount)}
                </p>
              </div>
            </div>
          ))}
        </DialogBody>
      </Dialog>
    </>
  );
};
