import { FC } from "react";
import styles from "./spinner.module.scss";

export const Spinner: FC<{}> = () => {
  return (
    <div className={styles["spinner__wrapper"]}>
      <div className={styles["spinner"]}></div>
    </div>
  );
};
